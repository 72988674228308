import APIService from "@/services/API";

const GetCoursesRequest = () => function constructor() {};

const GetCoursesResponse = (coursesArray) =>
  coursesArray.map((course) => {
    return {
      id_course_progress: course.id_course_progress,
      name: course.name,
      image_url: course.image_url,
      institution_id: course.institution_id,
      major_name: course.major_name,
      major_id: course.major_id,
    };
  });
class GetCoursesService extends APIService {
  constructor(coursesRequest) {
    super();
    this.serviceURL = "/student/show-courses/";
    this.response = null;
    this.request = coursesRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, this.request);
    return GetCoursesResponse(this.response.data);
  }
}

export { GetCoursesService, GetCoursesRequest };
