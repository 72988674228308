import {
  GetAnswerSheetResultsBySubjectService,
  GetAnswerSheetResultsBySubjectServiceRequest,
} from "@/use_cases/show_answer_sheet_results_by_subject/GetAnswerSheetResultsBySubjectService";
import {
  ShowAnswerSheetItemResultService,
  ShowAnswerSheetItemResultServiceRequest,
  // eslint-disable-next-line no-unused-vars
  ShowAnswerSheetItemResultServiceResponse,
} from "@/use_cases/show_answer_sheet_results_by_subject/ShowAnswerSheetItemResultService";

const GetAnswerSheetResultsBySubjectResponse = function constructor({ answerSheetId, exerciseResults } = {}) {
  this.answerSheetId = answerSheetId;
  this.exercisesResults = exerciseResults;
};

/** @returns {GetAnswerSheetResultsBySubjectResponse} * */
const getAnswerSheetResultsBySubject = async ({ answerSheetId, subjectId }) => {
  const serviceRequest = new GetAnswerSheetResultsBySubjectServiceRequest({ answerSheetId, subjectId });
  const service = new GetAnswerSheetResultsBySubjectService(serviceRequest);
  return new GetAnswerSheetResultsBySubjectResponse(await service.do());
};

/** @returns {ShowAnswerSheetItemResultServiceResponse} * */
const getAnswerSheetItemResult = async ({ answerSheetId, answerSheetItemId }) => {
  const serviceRequest = new ShowAnswerSheetItemResultServiceRequest({ answerSheetId, answerSheetItemId });
  const service = new ShowAnswerSheetItemResultService(serviceRequest);
  return service.do();
};

const ShowAnswerSheetResultsBySubject = { getAnswerSheetResultsBySubject, getAnswerSheetItemResult };

export default ShowAnswerSheetResultsBySubject;
