<template>
  <div class="base-input-checkbox">
    <input :id="id" v-model="checked" type="checkbox" class="base-input-checkbox__input" @change="emitChange" />
    <v-icon
      :class="['base-input-checkbox__icon', { 'base-input-checkbox__icon--checked': checked }]"
      color="white"
      @click="toggleCheckbox"
    >
      {{ checked ? checkboxIconActive : checkboxIconInactive }}
    </v-icon>
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label :for="id" class="base-input-checkbox__text">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseInputCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "base-input-checkbox",
    },
    checkboxIconInactive: {
      type: String,
      default: "mdi-checkbox-blank-outline",
      required: false,
    },
    checkboxIconActive: {
      type: String,
      default: "mdi-checkbox-marked",
      required: false,
    },
  },
  data() {
    return {
      checked: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.checked = newValue;
    },
  },
  methods: {
    toggleCheckbox() {
      this.checked = !this.checked;
      this.emitChange();
    },
    emitChange() {
      this.$emit("input", this.checked);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_fonts.scss";
@import "~@/scss/_typography.scss";

/** @define base-input-checkbox */
.base-input-checkbox {
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.base-input-checkbox__icon {
  cursor: pointer;
  font-size: 24px;
}

.base-input-checkbox__icon--checked {
  color: blue; /* Cambia el color del ícono cuando está seleccionado */
}

.base-input-checkbox__text {
  @extend %font-caption-2;

  cursor: pointer;
  padding-top: 0.15em;
  padding-left: 0.2em;
  user-select: none;
}

.base-input-checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
</style>
