<template>
  <v-app class="app">
    <TitleBar
      v-if="!isFullScreenView"
      ref="titleBar"
      :is-simulation-exam-menu-icon-visible="isSimulationExamMenuIconVisible"
      :is-quick-menu-visible="isBottomMenuVisible"
      @exam-image-click="updateSimulationExamMenuStatus"
    />
    <transition name="splash-screen--fade-out">
      <SplashScreen v-if="isLoading" />
    </transition>
    <v-main :class="'app__router-view--' + screenStyleModifier">
      <RouterView
        @update-simulation-exam-menu-icon="updateSimulationExamMenuIcon"
        @update-coins-balance="updateCoinsBalance"
        @load-finished="isLoading = false"
      />
    </v-main>
    <BottomNavigationMenu
      :is-visible="isBottomMenuVisible"
      :class="'app__bottom-menu--' + bottomMenuStyle"
    ></BottomNavigationMenu>
  </v-app>
</template>

<script>
import SplashScreen from "@/components/splash_screen/SplashScreen.vue";
import TitleBar from "@/components/title_bar/TitleBar.vue";
import Store from "@/./Store";
import BottomNavigationMenu from "@/components/bottom_navigation_menu/BottomNavigationMenu.vue";
import facebookTracking from "@/facebookTracking";

const unregisteredUserPath = /\/uu\//;
const demoPath = /\/u\//;

export default {
  components: {
    BottomNavigationMenu,
    SplashScreen,
    TitleBar,
  },
  data() {
    return {
      isLoading: true,
      windowProperties: window,
      isSimulationExamMenuIconVisible: false,
      isFullScreenView: false,
      isUserInDemo: false,
      bottomMenuStyle: "hidden",
      isBottomMenuVisible: false,
      courseProgressId: "",
    };
  },
  computed: {
    screenStyleModifier() {
      if (this.isFullScreenView) return "full-screen";
      if (this.isUserInDemo) return "window-screen";
      if (this.isSimulationExamMenuIconVisible)
        return "window-with-bottom-menu app__router-view--window-with-bottom-menu--simulation-exam-slide-menu-visible";
      return "window-with-bottom-menu";
    },
  },
  watch: {
    $route() {
      this.checkPath();
    },
  },
  created() {
    facebookTracking.initialize();
    facebookTracking.trackPageView();
    this.checkPath();
  },
  methods: {
    checkPath() {
      const { path } = this.$route;
      this.isFullScreenView = unregisteredUserPath.test(path);
      this.isUserInDemo = demoPath.test(path);
      this.bottomMenuStyle = !this.isFullScreenView && !this.isUserInDemo ? "visible" : "hidden";
      this.isBottomMenuVisible = !this.isFullScreenView && !this.isUserInDemo;
    },
    updateSimulationExamMenuStatus() {
      return Store.isSimulationExamSlidingMenuEnabled
        ? Store.disableSimulationExamSlidingMenu()
        : Store.enableSimulationExamSlidingMenu();
    },
    updateSimulationExamMenuIcon(status) {
      this.isSimulationExamMenuIconVisible = status;
    },
    updateCoinsBalance() {
      this.$refs.titleBar.updateCoinsBalance();
    },
  },
};
</script>

<style lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @v-application */
.v-application--wrap {
  align-items: center;
}

/** @app */
.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: transparent !important;
}

.app__router-view--window-screen {
  width: 100%;
  max-height: 100%;
  margin-bottom: auto;
  background: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  box-shadow:
    0 2em 2em 0 rgba(0 0 0 / 30%),
    0 6px 20px 0 rgba(0 0 0 / 19%);
}

.app__router-view--full-screen {
  width: 100%;
  height: 100%;
}

.app__router-view--window-with-bottom-menu {
  width: 90%;
  max-height: 100%;
  margin-bottom: auto;
  background: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  box-shadow:
    0 2em 2em 0 rgba(0 0 0 / 30%),
    0 6px 20px 0 rgba(0 0 0 / 19%);

  @include laptop {
    padding-top: 4em !important;
  }

  &--simulation-exam-slide-menu-visible {
    width: 100%;
  }
}

.app__bottom-menu--hidden {
  width: 100%;
  display: none !important;
}

.app__bottom-menu--visible {
  width: 100%;
  visibility: visible;

  @include laptop {
    display: none !important;
  }
}

html {
  height: 100%;
  background-image: url("~@/assets/watermark-blue.svg");
  background-color: $watermark-color;
  background-repeat: space;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.splash-screen--fade-out-leave-active {
  animation: fade-out 0.8s ease-out both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
