const facebookEvents = ["CompleteRegistration"];
function isProduction() {
  return process.env.NODE_ENV === "production";
}

const initialize = () => {
  if (isProduction()) {
    fbq("init", process.env.VUE_APP_FACEBOOK_PIXEL_ID); // eslint-disable-line
  }
};

const trackEvent = ({ eventName, params }) => {
  if (isProduction()) {
    const trackFunction = facebookEvents.includes(eventName) ? "track" : "trackCustom";
    fbq(trackFunction, eventName, params); // eslint-disable-line
  }
};

const trackPageView = () => {
  trackEvent({ eventName: "PageView", params: {} });
};

const facebookTracking = {
  initialize,
  trackPageView,
  trackEvent,
};

export default facebookTracking;
