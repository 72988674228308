<template>
  <div class="select-major">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <div class="main-content__school-img">
          <SchoolImage />
        </div>
        <div class="main-content__input-drop-down">
          <InputDropdown
            v-model="inputModel"
            :items="items"
            :item-template="baseItem"
            :placeholder="placeholder"
            :loading="loadingItems"
            @changed="inputChange"
            @selected="selectItem"
            @no-results="resultsAvailable = false"
          />
        </div>
        <div v-if="searchStarted && !resultsAvailable" data-test-id="no-results" class="main-content__no_results">
          No encontramos ninguna coincidencia
        </div>
        <div class="main-content__help_results">
          Puedes intentar con palabras como: ipn, fes, unam, cecyt, preparatoria, Ingeniería, Licenciatura
        </div>
        <div class="main-content__caption">
          <div class="caption__title">¿Aún no te decides?</div>
          <span> Recuerda que puedes añadir otras opciones en cualquier momento. </span>
        </div>
        <div class="main-content__btn">
          <BaseButton
            style-modifier="secondary"
            :is-disabled="selectedItemId === null"
            @click="
              $router.push({
                name: 'RegisterAsAStudentPage',
                params: {
                  desiredMajorId: selectedItemId,
                  desiredMajor: selectedItem,
                },
              })
            "
          >
            Siguiente
          </BaseButton>
          <div class="main-content__contact_container">
            <div class="main-content__contact_text1">¿No encuentras tu escuela?</div>
            <div class="main-content__contact_text2">¡Contáctanos!</div>
            <div class="main-content__contact_icons">
              <div data-test-id="facebook-btn" @click="redirectToMessengerChat">
                <FacebookLogoImage class="main-content__icon-size" />
              </div>
              <div data-test-id="instagram-btn" @click="redirectToInstagramChat">
                <img :src="require('@/assets/instagram-logo.png')" alt="Instagram" class="main-content__icon-size" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import SchoolImage from "@/assets/school.svg?inline";
import FacebookLogoImage from "@/assets/facebook-logo2.svg?inline";
import InputDropdown from "@/components/input_dropdown/InputDropdown.vue";
import BaseItem from "@/components/input_dropdown/BaseItem.vue";
import SearchMajor from "@/use_cases/select_major/SearchMajor";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";

export default {
  name: "SelectMajorPage",
  components: {
    BaseButton,
    SchoolImage,
    FacebookLogoImage,
    InputDropdown,
    ContentSidebarLayout,
  },
  data() {
    return {
      baseItem: BaseItem,
      inputModel: "",
      placeholder: "Busca tu escuela / carrera deseada",
      minLength: 3,
      rootSearchTextChanged: true,
      items: [],
      loadingItems: false,
      selectedItem: null,
      selectedItemId: null,
      resultsAvailable: false,
      searchStarted: false,
    };
  },
  mounted() {
    this.$emit("load-finished");
  },
  methods: {
    async searchMajor() {
      this.items = await SearchMajor.getMajors(this.inputModel);
    },
    async inputChange(inputModel, validString) {
      this.selectedItem = null;
      this.selectedItemId = null;
      this.rootSearchTextChanged = inputModel.length > this.inputModel.length;
      this.inputModel = inputModel;
      if (inputModel.length >= this.minLength && this.rootSearchTextChanged && validString) {
        this.searchStarted = true;
        this.loadingItems = true;
        await this.searchMajor();
        this.loadingItems = false;
      }
    },
    selectItem(item, id) {
      this.resultsAvailable = true;
      this.selectedItem = item;
      this.selectedItemId = id;
    },
    redirectToMessengerChat() {
      window.open(
        // eslint-disable-next-line max-len
        "https://m.me/ingresapp?text=Hola%2C%20estoy%20teniendo%20problemas%20para%20encontrar%20mi%20escuela%20deseada.",
        "_blank",
      );
    },
    redirectToInstagramChat() {
      window.open(
        // eslint-disable-next-line max-len
        "https://www.instagram.com/ingresapp/",
        "_blank",
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define main-content */
.main-content {
  @extend %font-form;

  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: 1.8em;
  margin-right: 1.8em;

  @include laptop {
    margin-top: 0;
  }
}

.main-content__school-img {
  width: 90%;
  margin-top: 3em;
  margin-bottom: 1em;

  @include laptop {
    width: 40%;
    margin: 1em;
    padding-top: 3em;
  }
}

.main-content__input-drop-down {
  width: 90%;

  @include laptop {
    width: 60%;
    padding-top: 1em;
  }
}

.main-content__btn {
  flex-grow: 1;
  width: 95%;
  padding-top: 3em;

  @include laptop {
    width: 70%;
  }
}

.main-content__caption {
  @extend %font-body-3;

  text-align: center;
  justify-content: center;
  width: 80%;
  padding-top: 3em;

  @include laptop {
    width: 50%;
  }
}

.main-content__no_results {
  @extend %font-body-2;

  color: $red;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  width: 90%;
  padding-top: 1em;

  @include laptop {
    width: 50%;
  }
}

.main-content__help_results {
  @extend %font-body-3;

  margin-top: 1.2em;
  text-align: center;
  justify-content: center;
  width: 90%;

  @include laptop {
    width: 65%;
  }
}

.main-content__search_examples {
  @extend %font-body-3;

  text-align: center;
  justify-content: center;
  width: 80%;
  padding-bottom: 2em;
  padding-top: 2em;

  @include laptop {
    width: 50%;
  }
}

.main-content__contact_container {
  padding-top: 3em;
  text-align: center;
  margin: 0 auto;
}

.main-content__contact_text1 {
  @extend %font-body-2;

  font-weight: bold;
}

.main-content__contact_text2 {
  @extend %font-body-2;

  font-weight: bold;
  color: $primary-color;
}

.main-content__contact_icons {
  padding-top: 1.5em;
  display: flex;
  justify-content: center;
  gap: 1.5em;
}

.main-content__icon-size {
  cursor: pointer;
  width: 3em;
}

/** @define caption */
.caption__title {
  @extend %font-body-3;

  color: $red;
  text-align: center;
  padding-bottom: 0.01em;
}
</style>
