import {
  GetAnswerSheetItemService,
  GetAnswerSheetItemServiceRequest,
} from "@/use_cases/show_exam/GetAnswerSheetItemService";
import {
  EvaluateAnswerSheetItemService,
  EvaluateAnswerSheetItemServiceRequest,
} from "@/use_cases/show_exam/EvaluateAnswerSheetItemService";
import {
  EvaluateAnswerSheetService,
  EvaluateAnswerSheetServiceRequest,
} from "@/use_cases/show_exam/EvaluateAnswerSheetService";

const GetAnswerSheetItemResponse = function constructor({
  answerSheetId,
  nextAnswerSheetItemId,
  previousAnswerSheetItemId,
  answerSheetItemId,
  exercise,
  answers,
  selectedAnswerId,
  answerSheetItemNumber,
  totalHints = false,
  unlockedHints,
} = {}) {
  this.answerSheetId = answerSheetId;
  this.nextAnswerSheetItemId = nextAnswerSheetItemId;
  this.previousAnswerSheetItemId = previousAnswerSheetItemId;
  this.answerSheetItemId = answerSheetItemId;
  this.exercise = exercise;
  this.answers = answers;
  this.selectedAnswerId = selectedAnswerId;
  this.answerSheetItemNumber = answerSheetItemNumber;
  this.totalHints = totalHints;
  this.unlockedHints = unlockedHints;
};

/** @returns {GetAnswerSheetItemResponse} * */
const getAnswerSheetItem = async ({ answerSheetId, answerSheetItemId }) => {
  const serviceRequest = new GetAnswerSheetItemServiceRequest({ answerSheetId, answerSheetItemId });
  const service = new GetAnswerSheetItemService(serviceRequest);
  return new GetAnswerSheetItemResponse(await service.do());
};

// eslint-disable-next-line camelcase
const EvaluateAnswerSheetItemResponse = ({ is_correct, messages }) => ({ is_correct, messages });

const getSecondsSpentOnAnswerSheet = (initialTimestamp) => {
  const finalTimestamp = Date.now();
  return Math.round((finalTimestamp - initialTimestamp) / 1000);
};

/** @returns {EvaluateAnswerSheetItemResponse} * */
const evaluateAnswerSheetItem = async ({ answerSheetId, answerSheetItemId, selectedAnswerId, initialTimestamp }) => {
  const time = getSecondsSpentOnAnswerSheet(initialTimestamp);
  const serviceRequest = EvaluateAnswerSheetItemServiceRequest({
    answerSheetId,
    answerSheetItemId,
    selectedAnswerId,
    time,
  });
  const service = new EvaluateAnswerSheetItemService(serviceRequest);
  const serviceResponse = await service.do();
  return EvaluateAnswerSheetItemResponse(serviceResponse);
};

const EvaluateAnswerSheetResponse = function constructor() {};

/** @returns {EvaluateAnswerSheetResponse} * */
const evaluateAnswerSheet = async ({ answerSheetId }) => {
  const serviceRequest = EvaluateAnswerSheetServiceRequest({
    answerSheetId,
  });
  const service = new EvaluateAnswerSheetService(serviceRequest);
  return new EvaluateAnswerSheetResponse(await service.do());
};

const EvaluateAnswerSheetItem = {
  getAnswerSheetItem,
  evaluateAnswerSheetItem,
  evaluateAnswerSheet,
};

export default EvaluateAnswerSheetItem;
