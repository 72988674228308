<template>
  <OneButtonModal
    :is-visible="isVisible"
    :is-close-button-visible="false"
    style-modifier="transparent"
    @button-click="emitButtonEvents"
  >
    <div slot="image" class="time-out-modal__img">
      <div class="time-out-modal__hints-img">
        <div class="hints-img__img">
          <HourglassImage />
        </div>
      </div>
    </div>

    <div slot="message" class="time-out-modal__message">
      <p>El tiempo del examen se ha agotado</p>
    </div>

    <span slot="buttonText" class="time-out-modal__btn-text">Continuar</span>
  </OneButtonModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import HourglassImage from "@/assets/hourglass.svg?inline";
import OneButtonModal from "@/components/one_button_modal/OneButtonModal.vue";

export default {
  name: "TimeOutModalComponent",
  components: {
    OneButtonModal,
    HourglassImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitButtonEvents(event) {
      this.emitButtonClickEvent(event);
      this.emitHideModalEvent();
    },
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitButtonClickEvent(event) {
      this.$emit("button-click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define time-out-modal */
.time-out-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $overlay-color;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.time-out-modal__hints-img {
  width: 100%;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.time-out-modal__message {
  margin: 2em 0;
}

.time-out-modal__white-arrow-img {
  width: 100%;
  display: flex;
}

/** @define white-arrow-img */
.white-arrow-img__img {
  width: 30%;
  margin-left: 4em;

  @include laptop {
    width: 20%;
  }
}

/** @define hints-img */
.hints-img__img {
  width: 30%;
  padding-left: 35%;
  justify-content: center;

  @include laptop {
    width: 20%;
    padding-left: 40%;
  }
}
</style>
