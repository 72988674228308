<template>
  <div class="demo-start">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <BaseContainer>
          <div class="main-content__logo">
            <LogoImage />
          </div>

          <div class="main-content__top-text">
            <p>Es hora de practicar para tu examen</p>
          </div>

          <div class="main-content__mid-text">
            <p>y divertirte mientras lo haces</p>
          </div>

          <div class="main-content__bottom-text">
            <p>Antes, una breve demostración</p>
          </div>

          <div class="main-content__start-demo-btn">
            <BaseButton style-modifier="secondary" @click="$router.push({ name: 'DemoPage' })">
              COMENZAR DEMO
            </BaseButton>
          </div>
        </BaseContainer>
      </div>
      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import LogoImage from "@/assets/logo-without-text.svg?inline";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import facebookTracking from "@/facebookTracking";

export default {
  name: "DemoSuccessfullyCompleted",
  components: {
    BaseContainer,
    BaseButton,
    LogoImage,
    ContentSidebarLayout,
  },

  mounted() {
    this.trackDemoStarted();
    this.$emit("load-finished");
  },

  methods: {
    trackDemoStarted() {
      this.$gtag.event("tutorial_begin");
      facebookTracking.trackEvent({ eventName: "tutorial_begin", params: {} });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define demo-start; weak */
.demo-start {
  display: flex;
  flex-direction: row;
}

/** @define main-content; */
.main-content {
  background: $tertiary-overlay-color;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 3em 1em;

  @include laptop {
    margin: 1.5em 0 0 4em;
  }
}

.main-content__logo {
  width: 15em;

  @include laptop {
    width: 25%;
    margin: 1.5em;
  }
}

.main-content__start-demo-btn {
  flex-grow: 2;
  width: 100%;
  margin: 1em;

  @include laptop {
    width: 90%;
  }
}

.main-content__top-text {
  @extend %font-h1_landing;

  text-align: center;
  color: $ipn-color-light;
}

.main-content__mid-text {
  @extend %font-title-2;

  text-align: center;
  color: $success-color;
}

.main-content__bottom-text {
  @extend %font-title-1;

  padding-top: 1em;
  text-align: center;
  color: $black;
}
</style>
