<template>
  <div class="circle-icon-progress-bar" data-test-id="progress-bar" @click="emitClickEvent">
    <v-progress-circular
      :rotate="-90"
      :size="110"
      :width="15"
      :value="progressPercentage"
      color="success"
      data-test-id="circle-icon"
    >
      <slot></slot>
    </v-progress-circular>

    <div class="circle-icon-progress-bar__caption" data-test-id="circle-icon-progressbar-caption">
      <span>{{ caption }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleIconWithProgressBar",
  props: {
    progressPercentage: {
      type: Number,
      required: true,
    },
    caption: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      rightCircleStyle: "",
      leftCircleStyle: "",
      progressBarStyle: "",
    };
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define circle-icon-progress-bar; */
.circle-icon-progress-bar::v-deep {
  @extend %font-caption-2;

  width: 6.8em;
  height: 6.8em;
  cursor: pointer;

  .v-progress-circular__info {
    width: 70%;
    height: 70%;
  }

  .v-progress-circular {
    width: 6.8em !important;
    height: 6.8em !important;
  }
}

.circle-icon-progress-bar__caption {
  @extend %font-caption-2;

  text-align: center;
  width: 100%;
  cursor: pointer;
}
</style>
