<template>
  <OneButtonModal
    :is-visible="isVisible"
    :is-close-button-visible="false"
    style-modifier="transparent"
    data-test-id="daily-exam-modal-button"
    @button-click="evaluateAnswerSheetItem"
  >
    <div slot="image">
      <div class="daily-exam-modal__container-img">
        <WinCoinsImage v-if="isCorrect" class="daily-exam-modal__img" />
        <LogoSadImage v-else class="daily-exam-modal__img" />
      </div>
    </div>

    <div slot="message" class="daily-exam-modal__title">
      <p>
        {{ title }}
      </p>
    </div>

    <div slot="message" class="daily-exam-modal__message">
      <p>
        {{ description }}
      </p>
    </div>

    <span slot="buttonText" class="daily-exam-modal__btn-text">{{ buttonText }}</span>
  </OneButtonModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import WinCoinsImage from "@/assets/coin-claim-reward.svg?inline";
import LogoSadImage from "@/assets/logo_sad.svg?inline";
import OneButtonModal from "@/components/one_button_modal/OneButtonModal.vue";

export default {
  name: "DailyExamModal",
  components: {
    OneButtonModal,
    WinCoinsImage,
    LogoSadImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    isCorrect: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    evaluateAnswerSheetItem(event) {
      this.emitHideModalEvent();
      this.$emit("evaluate-answer-sheet-item", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/colors";
@import "~@/scss/typography";

/** @define daily-exam-modal */
.daily-exam-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $overlay-color;
  width: 100%;
  height: 100%;
}

.daily-exam-modal__container-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.daily-exam-modal__title {
  @extend %font-h2_title;

  margin-top: 1em;
}

.daily-exam-modal__message {
  @extend %font-title-1;

  margin: 2em 0;
}

.daily-exam-modal__twelve-coins {
  width: 100%;
  display: flex;
}

.daily-exam-modal__img {
  width: 80%;
}
</style>
