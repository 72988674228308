import APIService from "@/services/API";

const ShowAnswerSheetItemResultServiceRequest = function constructor({ answerSheetId, answerSheetItemId }) {
  this.id_answer_sheet = answerSheetId;
  this.id_answer_sheet_item = answerSheetItemId;
};

/* eslint-disable camelcase */
const Answer = function constructor({ id, text, image } = {}) {
  this.id = id;
  this.text = text;
  this.imageUrl = image;
};

/* eslint-disable camelcase */
const ShowAnswerSheetItemResultServiceResponse = function constructor({
  id_answer_sheet_item,
  exercise_text,
  exercise_image,
  exercise_number,
  id_answer_selected,
  id_correct_answer,
  is_correct,
  id_answer_sheet,
  answers,
  has_explanation,
} = {}) {
  this.answerSheetItemId = id_answer_sheet_item;
  this.exerciseText = exercise_text;
  this.exerciseImage = exercise_image;
  this.exerciseNumber = exercise_number;
  this.selectedAnswerId = id_answer_selected;
  this.correctAnswerId = id_correct_answer;
  this.isCorrect = is_correct;
  this.answerSheetId = id_answer_sheet;
  this.answers = answers.map((answer) => new Answer(answer));
  this.hasExplanation = has_explanation;
};

class ShowAnswerSheetItemResultService extends APIService {
  constructor(showAnswerSheetItemResultServiceRequest) {
    super();
    this.serviceURL = "student/show-answer-sheet-item-result/";
    this.request = showAnswerSheetItemResultServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return new ShowAnswerSheetItemResultServiceResponse(this.response.data);
  }
}

export {
  ShowAnswerSheetItemResultService,
  ShowAnswerSheetItemResultServiceRequest,
  ShowAnswerSheetItemResultServiceResponse,
};
