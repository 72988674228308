<template>
  <BaseModal
    class="payment-modal"
    :is-close-button-visible="false"
    :is-visible="isVisible"
    style-modifier="transparent"
  >
    <template #message>
      <div class="payment-method-container">
        <div>
          <PaymentMethodCard
            :key="componentKey"
            :product="product"
            :oxxo-payment-info="oxxoPaymentInfo"
            :is-shadow-card-hide-active="isCardShadowVisible"
            data-test-id="bank-card-form-modal"
            @buy-with="buyWith"
            @hide="hidePaymentMethodModal"
          ></PaymentMethodCard>
        </div>
        <div class="payment-method-bankcard">
          <BankCardFormCard
            v-if="isCardPayment"
            :product="product"
            @buy-with="buyWith"
            @hide="hidePaymentMethodModal"
          ></BankCardFormCard>
          <OxxoInstructions
            v-if="isOxxoPayment"
            :oxxo-payment-info="oxxoPaymentInfo"
            data-test-id="buy-with-oxxo"
            @buy-with="buyWith"
            @hide="hidePaymentMethodModal"
          ></OxxoInstructions>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import PaymentMethodCard from "@/use_cases/buy_product/PaymentMethodCard.vue";
import BankCardFormCard from "@/use_cases/buy_product/BankCardForm.vue";
import OxxoInstructions from "@/use_cases/buy_product/OxxoInstructions.vue";

export default {
  name: "PaymentMethodModal",
  components: { OxxoInstructions, BankCardFormCard, PaymentMethodCard, BaseModal },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    /** @type { productId: int , name: string , description: string, price: int , image: string, type: string } * */
    product: {
      required: true,
      type: Object,
    },
    /** @type {{ referenceNumber: string, amountToPay: float, expirationDate: date, barcodeUrl: string }} * */
    oxxoPaymentInfo: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isCardPayment: true,
      isOxxoPayment: false,
      isCardShadowVisible: false,
      componentKey: 0,
    };
  },
  methods: {
    buyWith(method) {
      if (method === "card") {
        this.isCardPayment = true;
        this.isOxxoPayment = false;
      }
      if (method === "oxxo") {
        this.isCardPayment = false;
        this.isOxxoPayment = true;
      }
      this.$emit("buy-with", method);
    },
    hidePaymentMethodModal(method) {
      this.isCardShadowVisible = true;
      this.isCardPayment = true;
      this.isOxxoPayment = false;
      this.$emit("hide-modal", method);
      this.componentKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";

.payment-method-container {
  display: flex;
  flex-direction: column;

  @include laptop {
    flex-direction: row;
  }
}

.payment-method-bankcard_text {
  color: $black;
  text-align: center;
}

.payment-modal {
  z-index: 10;
}
</style>
