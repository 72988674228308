<template>
  <transition name="vertical-slide" @enter="enter" @after-enter="afterEnter" @leave="leave" @after-leave="afterLeave">
    <slot />
  </transition>
</template>

<script>
export default {
  name: "VerticalSlide",

  methods: {
    enter() {
      const appliedAnimationClass = "vertical-slide-enter-active";
      this.$emit("enter", appliedAnimationClass);
    },
    afterEnter() {
      this.$emit("after-enter");
    },
    leave() {
      const appliedAnimationClass = "vertical-slide-leave-active";
      this.$emit("leave", appliedAnimationClass);
    },
    afterLeave() {
      this.$emit("after-leave");
    },
  },
};
</script>

<style lang="scss">
.vertical-slide-enter-active {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(0);
  }
}

.vertical-slide-leave-active {
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100px);
  }
}
</style>
