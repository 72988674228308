import APIService from "@/services/API";

const GetActiveCourseProgressIdServiceRequest = () => ({});

/* eslint-disable camelcase */
const GetActiveCourseProgressIdServiceResponse = ({ active_course_progress_id, major_id }) => ({
  activeCourseProgressId: active_course_progress_id,
  majorId: major_id,
});
/* eslint-enable camelcase */

class GetActiveCourseProgressIdService extends APIService {
  constructor(getActiveCourseProgressIdServiceRequest) {
    super();
    this.serviceURL = "student/get-active-course-progress-id/";
    this.request = getActiveCourseProgressIdServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return GetActiveCourseProgressIdServiceResponse(this.response.data);
  }
}

export { GetActiveCourseProgressIdService, GetActiveCourseProgressIdServiceRequest };
