<template>
  <div class="feedback-component">
    <div v-if="title" class="feedback-component__title">{{ title }}</div>
    <div class="feedback-component__score">
      <v-btn
        :class="[
          'feedback-component__score-icon',
          {
            'feedback-component__score-icon--selected': isThumbUpSelected,
            'feedback-component__score-icon--white': buttonsVariant === 'white',
          },
        ]"
        icon
        :disabled="isDisabled"
        :data-test-id="`${dataTestIdPrefix}-thumb-up-button`"
        @click="thumbUpClicked"
      >
        <v-icon size="30">{{ thumbUp }}</v-icon>
      </v-btn>
      <v-btn
        icon
        :disabled="isDisabled"
        :data-test-id="`${dataTestIdPrefix}-thumb-down-button`"
        :class="[
          'feedback-component__score-icon',
          {
            'feedback-component__score-icon--selected': isThumbDownSelected,
            'feedback-component__score-icon--white': buttonsVariant === 'white',
          },
        ]"
        @click="thumbDownClicked"
      >
        <v-icon size="30">{{ thumbDown }}</v-icon>
      </v-btn>
    </div>
    <v-container v-show="isFeedbackTextBoxVisible" class="pa-0 mx-0 fluid">
      <v-textarea
        v-model="value"
        outlined
        dense
        hide-details
        rows="2"
        :placeholder="placeholder"
        background-color="white"
        :data-test-id="`${dataTestIdPrefix}-text-area-feedback`"
        @input="setButtonState"
      >
        <template #append>
          <v-btn
            icon
            small
            class="feedback-text-box__button"
            :data-test-id="`${dataTestIdPrefix}-send-feedback-button`"
            @click="emitClickEvent"
          >
            <v-progress-circular
              v-if="isLoading"
              color="black"
              indeterminate
              :size="20"
              :width="3"
            ></v-progress-circular>
            <v-icon v-if="isSendFeedbackIconVisible" size="25" color="black">mdi-send-outline mdi-rotate-315</v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FeedbackComponent",
  props: {
    buttonsVariant: {
      type: String,
      required: true,
      validator: (prop) => ["black", "white"].includes(prop),
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
      required: false,
    },
    dataTestIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isThumbUpSelected: false,
      isThumbDownSelected: false,
      isFeedbackTextBoxVisible: false,
      isDisabled: false,
      value: "",
      isTextDisabled: false,
      isClickSendFeedback: false,
      isLoading: false,
      isSendFeedbackIconVisible: true,
    };
  },
  computed: {
    thumbUp() {
      return this.isThumbUpSelected ? "mdi-thumb-up" : "mdi-thumb-up-outline";
    },
    thumbDown() {
      return this.isThumbDownSelected ? "mdi-thumb-down" : "mdi-thumb-down-outline";
    },
  },
  destroyed() {
    if (this.isThumbUpSelected || this.isThumbDownSelected)
      if (!this.isClickSendFeedback) this.$emit("send-feedback", { value: this.getFeedbackValue(), text: this.value });
  },
  methods: {
    thumbUpClicked() {
      if (!this.isThumbUpSelected && this.isThumbDownSelected) {
        this.isThumbUpSelected = !this.isThumbUpSelected;
        this.isThumbDownSelected = !this.isThumbDownSelected;
      } else {
        this.isThumbUpSelected = !this.isThumbUpSelected;
      }
      this.isFeedbackTextBoxVisible = this.isThumbDownSelected || this.isThumbUpSelected;
    },
    thumbDownClicked() {
      if (this.isThumbUpSelected && !this.isThumbDownSelected) {
        this.isThumbUpSelected = !this.isThumbUpSelected;
        this.isThumbDownSelected = !this.isThumbDownSelected;
      } else {
        this.isThumbDownSelected = !this.isThumbDownSelected;
      }
      this.isFeedbackTextBoxVisible = this.isThumbDownSelected || this.isThumbUpSelected;
    },
    async emitClickEvent() {
      this.isClickSendFeedback = true;
      this.$emit("send-feedback", { value: this.getFeedbackValue(), text: this.value });
      this.isTextDisabled = true;
      this.isDisabled = true;
      this.isFeedbackTextBoxVisible = false;
    },
    getFeedbackValue() {
      return this.isThumbUpSelected ? 1 : -1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.feedback-component {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.4em;
}

.feedback-component__title {
  @extend %font-subtitle-1;

  text-align: center;
}

.feedback-component__score {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
  padding-bottom: 0.65em;
}

.feedback-component__score-icon {
  color: $secondary-overlay-color !important;

  &--selected {
    color: $black !important;
  }

  &--white {
    &.feedback-component__score-icon--selected {
      color: $white !important;
    }
  }
}

.feedback-text-box__button {
  position: absolute;
  bottom: 15px;
  right: 12px;
}
</style>
