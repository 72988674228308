<template>
  <div :class="'collapsible-item' + '--' + collapsibleStyle">
    <div
      :class="'collapsible-item__header' + '--' + collapsibleStyle"
      data-test-id="collapsible-item-header"
      @click="emitCollapsibleItemClicked"
    >
      <div :class="'header__title--' + collapsibleStyle + '--' + titleStatus" data-test-id="collapsible-item-title">
        <slot name="title"></slot>
      </div>
      <div :class="collapsibleArrowStyle" data-test-id="arrow-image-container">
        <CollapseArrowImage :class="'header__img--' + arrowStyle" data-test-id="collapse-arrow-image" />
      </div>
    </div>
    <transition
      :name="'slide-down-' + collapsibleStyle"
      @after-enter="showCollapsibleContent"
      @before-leave="hideCollapsibleContent"
    >
      <div
        v-show="isCollapsibleOpen"
        id="collapsibleContent"
        :class="'collapsible-item__content' + '--' + collapsibleStyle"
        :style="contentHeight"
        data-test-id="collapsible-item-content"
        @click="emitCollapsibleItemClicked"
      >
        <slot v-show="isCollapsibleContentVisible" name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import CollapseArrowImage from "@/assets/collapse-arrow.svg?inline";

export default {
  name: "CollapsibleItem",
  components: {
    CollapseArrowImage,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    itemsLength: {
      type: Number,
      default: 1,
    },
    isMobileResolution: {
      type: Boolean,
      default: false,
    },
    collapsibleStyle: {
      type: String,
      default: "dark",
      validator: (prop) => ["dark", "white"].includes(prop),
    },
  },
  data() {
    return {
      isCollapsibleOpen: this.isOpen,
      isCollapsibleContentVisible: false,
      collapsibleMaxHeight: 0,
    };
  },
  computed: {
    numberOfItemsPerRow() {
      return this.isMobileResolution ? 5 : 7;
    },
    collapsibleArrowStyle() {
      return this.isCollapsibleOpen ? "expand-btn-animation" : "collapse-btn-animation";
    },
    titleStatus() {
      return this.isCollapsibleOpen ? "enabled" : "disabled";
    },
    contentHeight() {
      if (this.collapsibleStyle === "white") {
        return "height: auto";
      }
      // return `height:${Math.ceil(this.itemsLength / this.numberOfItemsPerRow) * 3.3}em`;
      return `height:auto`;
    },
    arrowStyle() {
      return this.collapsibleStyle === "dark" ? "white" : "blue";
    },
  },
  watch: {
    isOpen() {
      this.isCollapsibleOpen = this.isOpen;
    },
    isMobileResolution() {
      return this.isMobileResolution;
    },
  },
  methods: {
    emitCollapsibleItemClicked(event) {
      this.isCollapsibleOpen = !this.isCollapsibleOpen;
      this.$emit("collapsible-item-clicked", event);
    },
    async showCollapsibleContent() {
      this.isCollapsibleContentVisible = true;
    },
    async hideCollapsibleContent() {
      this.isCollapsibleContentVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define collapsible-item */
.collapsible-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.collapsible-item--dark {
  @extend .collapsible-item;

  color: $white;
  background-color: $navigation-panel-color;
}

.collapsible-item--white {
  @extend .collapsible-item;

  color: $black;
  background-color: $white;
}

.collapsible-item__header--dark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $overlay-color--hover;
  }
}

.collapsible-item__header--white {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.collapsible-item__content--dark {
  @extend %font-body-1;

  display: flex;
  flex-flow: row wrap;
  padding-left: 1.2em;
  width: 90%;
}

.collapsible-item__content--white {
  @extend %font-body-1;

  display: flex;
  flex-flow: row wrap;
  padding-left: 1.2em;
  padding-top: 1em;
  width: 90%;
  align-items: center;
  justify-content: center;
}

/** @define header */
.header__img--white {
  width: 2.5em;
  padding-right: 1em;
}

/** @define header; weak */
.header__img--blue {
  width: 1.2em;
}

.header__img--blue > path {
  fill: $primary-color;
}

.header__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: auto;
  padding: 0.2em 0.2em 0.2em 1em;
}

.header__title--dark--enabled {
  @extend .header__title;
  @extend %font-subtitle-2;

  color: $secondary-color;
}

.header__title--dark--disabled {
  @extend .header__title;
  @extend %font-subtitle-2;

  color: $white;
}

.header__title--white--enabled {
  @extend .header__title;
  @extend %font-body-1;
}

.header__title--white--disabled {
  @extend .header__title;
  @extend %font-body-1;
}

@keyframes rotate-180-cw {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-180-ccw {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0);
  }
}

/** @define expand-btn-animation */
.expand-btn-animation {
  animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/** @define collapse-btn-animation */
.collapse-btn-animation {
  animation: rotate-180-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
</style>
<style scoped lang="scss">
.slide-down-white-enter-active,
.slide-down-white-leave-active {
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  max-height: 25em;
}

.slide-down-dark-enter-active,
.slide-down-dark-leave-active {
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  max-height: 10em;
}

.slide-down-dark-enter,
.slide-down-dark-leave-to {
  max-height: 0;
}

.slide-down-white-enter,
.slide-down-white-leave-to {
  max-height: 0;
}
</style>
