import APIService from "@/services/API";

const GetStudentCoinsBalanceServiceRequest = (request) => request;
/* eslint-disable camelcase */
const GetStudentCoinsBalanceServiceResponse = ({ coins_balance, is_coins_bundle_purchase_event_pending }) => ({
  coinsBalance: coins_balance,
  isCoinsBundlePurchaseEventPending: is_coins_bundle_purchase_event_pending,
});
/* eslint-enable camelcase */

class GetStudentCoinsBalanceService extends APIService {
  constructor(studentCoinsServiceRequest) {
    super();
    this.serviceURL = "/student/get-coins-balance/";
    this.response = null;
    this.request = studentCoinsServiceRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, this.request);
    return GetStudentCoinsBalanceServiceResponse(this.response.data);
  }
}

export { GetStudentCoinsBalanceService, GetStudentCoinsBalanceServiceRequest };
