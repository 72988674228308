<template>
  <div class="bank-container-bg">
    <div class="payment-method_pay">
      <CardImage :class="['payment-method_card', shadowCardClass]" @click="buyWith('card')"></CardImage>
      <OxxoImage :class="['payment-method_oxxo', shadowOxxoClass]" @click="buyWith('oxxo')"></OxxoImage>
    </div>
    <div class="bank-card-close_container">
      <div class="bank-card-container-close_icon" data-test-id="close-btn-card" @click="hidePaymentMethodModal">
        <CloseButtonImage class="bank-card-close_icon"></CloseButtonImage>
      </div>
    </div>
    <BaseCard class="bank-container_card">
      <div class="bank-container_title">Información de la tarjeta</div>
      <div class="bank-card">
        <label class="bank-card__text-field" for="card-owner">
          <span class="bank-card__label">Nombre del tarjetahabiente</span>
          <input
            id="card-owner"
            v-model="userCardInfo.name"
            class="bank-card__input"
            type="text"
            data-test-id="card-owner-input"
            size="20"
            placeholder="Nombre Completo"
          />
          <span class="bank-card__warn" data-test-id="card-owner-warn">{{ ownerNameError ? ownerNameError : "" }}</span>
        </label>
        <div class="bank-card__info-group">
          <div class="bank-card__text-field bank-card__card_number">
            <span class="bank-card__label">Número de Tarjeta</span>
            <input
              v-model="userCardInfo.number"
              class="bank-card__input"
              type="text"
              data-test-id="card-number-input"
              size="20"
              placeholder="xxxx xxxx xxxx xxxx"
            />
          </div>
        </div>
        <div data-test-id="card-number-warn" class="bank-card__text-field bank-card__warn">
          {{ cardNumberError ? cardNumberError : "" }}
        </div>
        <div>
          <label class="bank-card__text-field" for="expiration-date">
            <div class="bank-card__product-date_container">
              <div class="bank-card__product-date">
                <span class="bank-card__label">Fecha de expiración</span>
                <div class="bank-card__info-group bank-card__product-info">
                  <input
                    id="expiration-date"
                    v-model="userCardInfo.exp_month"
                    class="bank-card__input bank-card__input--month bank-card__month-input"
                    type="text"
                    data-test-id="expiration-month-input"
                    size="2"
                    placeholder="MM"
                  />
                  <input
                    v-model="userCardInfo.exp_year"
                    class="bank-card__input bank-card__input--year"
                    type="text"
                    data-test-id="expiration-year-input"
                    size="4"
                    placeholder="AA"
                  />
                </div>
              </div>
              <div class="bank-card__product-cvv">
                <span class="bank-card__label">CVV</span>
                <div class="bank-card__info-group bank-card__product-info">
                  <input
                    v-model="userCardInfo.cvc"
                    class="bank-card__input bank-card__card_cvc"
                    type="text"
                    data-test-id="cvc-number-input"
                    size="4"
                    placeholder="CVV"
                  />
                </div>
              </div>
            </div>
          </label>
          <div data-test-id="cvc-number-warn" class="bank-card__text-field bank-card__warn">
            {{ cvcNumberError ? cvcNumberError : "" }}
          </div>
          <span class="bank-card__warn" data-test-id="expiration-date-warn">{{
            expirationDateError ? expirationDateError : ""
          }}</span>
        </div>
      </div>
      <div class="bank-card__payment-container-btn">
        <BaseButton
          class="bank-card__payment-btn"
          style-modifier="secondary"
          :is-disabled="isWaitingForRequestResponse"
          data-test-id="pay-with-card-btn"
          @click="payWithCard"
        >
          Pagar
        </BaseButton>
      </div>
      <span class="bank-card__warn" data-test-id="verify-info-warn">{{
        verifyCardInformation ? verifyCardInformation : ""
      }}</span>
      <SuccessfulPaymentModal
        :is-visible="isSuccessfulPaymentModalOpen"
        data-test-id="successful-payment-modal"
        @hide-modal="resetDefaultFormStateAndClosePaymentModals"
      />
    </BaseCard>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
import BuyProduct from "@/use_cases/buy_product/BuyProduct";
import SuccessfulPaymentModal from "@/use_cases/buy_product/SuccessfulPaymentModal.vue";
import BaseCard from "@/components/base_card/BaseCard.vue";
import OxxoImage from "@/assets/oxxo.svg?inline";
import CardImage from "@/assets/card.svg?inline";
import CloseButtonImage from "@/assets/close_btn.svg?inline";

const INVALID_OWNER_NAME = "Ingresa un nombre correcto";
const INVALID_CARD_NUMBER = "Ingresa un número de tarjeta válido";
const INVALID_CVC_NUMBER = "Ingresa un CVV válido";
const INVALID_EXPIRATION_DATE = "Ingresa una fecha válida ";
const VERIFY_CARD_INFO = "Revisa los datos de tu tarjeta";
const COULD_NOT_PROCEED_WITH_CONEKTA = "No se pudo generar el token de pago";
const DEFAULT_EMPTY_CARD_INFO = {
  name: "",
  number: "",
  cvc: "",
  exp_month: "",
  exp_year: "",
};

export default {
  name: "BankCardFormModal",
  components: { BaseCard, BaseButton, SuccessfulPaymentModal, OxxoImage, CardImage, CloseButtonImage },
  props: {
    isVisible: {
      required: false,
      type: Boolean,
    },
    /** @type { productId: int , name: string , description: string, price: int , image: string, type: string } * */
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      conekta: {},
      userCardInfo: JSON.parse(JSON.stringify(DEFAULT_EMPTY_CARD_INFO)),

      ownerNameError: null,
      cardNumberError: null,
      cvcNumberError: null,
      expirationDateError: null,
      verifyCardInformation: null,

      isWaitingForRequestResponse: false,
      isSuccessfulPaymentModalOpen: false,
      isShadowCardActive: true,
      isShadowOxxoActive: false,
    };
  },
  computed: {
    shadowCardClass() {
      return {
        "payment-method-shadow": this.isShadowCardActive,
      };
    },
    shadowOxxoClass() {
      return {
        "payment-method-shadow": this.isShadowOxxoActive,
      };
    },
  },
  watch: {
    isVisible: {
      handler() {
        this.resetForm();
      },
      immediate: true,
    },
    userCardInfo: {
      handler() {
        if (this.verifyCardInformation) {
          this.verifyCardInformation = null;
        }
      },
      deep: true,
    },
    [`userCardInfo.name`]: {
      handler(name) {
        // eslint-disable-next-line no-restricted-globals
        this.ownerNameError = isNaN(name) && name.length > 0 ? null : INVALID_OWNER_NAME;
      },
    },
    [`userCardInfo.number`]: {
      handler(number) {
        this.cardNumberError = this.conekta.card.validateNumber(number) ? null : INVALID_CARD_NUMBER;
      },
    },
    [`userCardInfo.cvc`]: {
      handler(cvc) {
        // eslint-disable-next-line no-restricted-globals
        this.cvcNumberError = !isNaN(cvc) && cvc.length > 0 && cvc.length < 4 ? null : INVALID_CVC_NUMBER;
      },
    },
    [`userCardInfo.exp_month`]: {
      handler(expMonth) {
        this.expirationDateError = this.isValidExpirationDate(expMonth, this.userCardInfo.exp_year);
      },
    },
    [`userCardInfo.exp_year`]: {
      handler(expYear) {
        this.expirationDateError = this.isValidExpirationDate(this.userCardInfo.exp_month, expYear);
      },
    },
  },
  mounted() {
    const { NODE_ENV } = process.env;
    if (NODE_ENV === "production" || NODE_ENV === "development") {
      // eslint-disable-next-line no-undef
      this.conekta = Conekta;
      this.conekta.setPublicKey(process.env.VUE_APP_CONEKTA_PUBLIC_KEY);
    }
  },
  methods: {
    isValidExpirationDate(expMonth, expYear) {
      return this.conekta.card.validateExpirationDate(expMonth, expYear) ? null : INVALID_EXPIRATION_DATE;
    },
    isCardInfoRight() {
      const formContainsData = Object.keys(this.userCardInfo)
        .map((field) => this.userCardInfo[field])
        .filter((data) => `${data}`.length && `${data}`.length > 0);
      return (
        formContainsData.length > 0 &&
        this.ownerNameError == null &&
        this.cardNumberError == null &&
        this.cvcNumberError == null &&
        this.expirationDateError == null
      );
    },
    payWithCard() {
      this.isWaitingForRequestResponse = true;
      if (this.isCardInfoRight()) {
        const cardObject = { card: this.userCardInfo };
        this.conekta.Token.create(cardObject, this.onSuccessTokenResponse, this.onErrorTokenResponse);
      } else {
        this.verifyCardInformation = VERIFY_CARD_INFO;
        this.isWaitingForRequestResponse = false;
      }
    },
    async onSuccessTokenResponse(cardToken) {
      const dataWithToken = { productId: this.product.productId, cardToken: cardToken.id };
      await BuyProduct.BuyProductWithCard(dataWithToken);
      this.isSuccessfulPaymentModalOpen = true;
      this.isWaitingForRequestResponse = false;
    },
    onErrorTokenResponse() {
      this.verifyCardInformation = COULD_NOT_PROCEED_WITH_CONEKTA;
      this.isWaitingForRequestResponse = false;
    },
    resetDefaultFormStateAndClosePaymentModals() {
      this.resetForm();
      this.isSuccessfulPaymentModalOpen = false;
      this.$emit("hide-modal");
    },
    resetForm() {
      this.ownerNameError = null;
      this.cardNumberError = null;
      this.cvcNumberError = null;
      this.expirationDateError = null;
      this.verifyCardInformation = null;
      this.userCardInfo = JSON.parse(JSON.stringify(DEFAULT_EMPTY_CARD_INFO));
    },
    hidePaymentMethodModal() {
      this.$emit("hide", false);
    },
    buyWith(method) {
      this.isShadowCardActive = method === "card";
      this.isShadowOxxoActive = method === "oxxo";
      this.$emit("buy-with", method);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_fonts.scss";
@import "~@/scss/_spacing.scss";

.bank-container-bg {
  color: $black;
  background: $white;
  height: 100%;

  @include laptop {
    padding: 0 3.6em;
  }
}

.bank-card-close_container {
  display: none;

  @include laptop {
    display: flex;
    justify-content: flex-end;
    padding-top: 2em;
  }
}

.bank-card-container-close_icon {
  cursor: pointer;
}

.bank-card-close_icon {
  width: 2em;
  height: 2em;
}

.bank-container_card {
  @extend %font-body-3;

  display: flex;
  flex-direction: column;
  color: $black;
  background: white;
  border-radius: 0;
  height: auto;
  border: none;

  @include laptop {
    border: solid 2px $secondary-color;
    margin-top: 2em;
    padding: 6.5em 4em;
  }
}

.bank-container_title {
  @extend %font-title-1;

  margin-bottom: 1em;
  display: none;

  @include laptop {
    display: block;
  }
}

.bank-card {
  width: 100%;
  text-align: left;
}

.bank-card__product-info {
  display: flex;
}

.bank-card__product-section {
  padding: $space-unit;
  margin: $space-unit;
}

.bank_card__product_description {
  @extend %font-caption-2;
}

.bank-card--full-width-input {
  width: 100%;
}

.bank-card__info-group {
  display: flex;
}

.bank-card__text-field {
  display: grid;
}

.bank-card__label {
  @extend %font-body-2;
}

.bank-card__input {
  border-bottom: 1px solid $overlay-color--hover;
  margin-bottom: 2em;
}

.bank-card__card_number {
  width: 100%;
}

.bank-card__card_cvc {
  width: 100%;
}

.bank-card__input--centered {
  text-align: center;
}

.bank-card__input--month {
  width: 35%;
}

.bank-card__input--year {
  width: 35%;
}

.bank-card__warn {
  color: $red;

  @extend %font-caption-2;
}

.bank-card__payment-container-btn {
  display: flex;
  justify-content: center;
}

.bank-card__payment-btn {
  margin-top: 1em;
  color: white;
  font-weight: bold;
  border-radius: $space-unit;
  width: 50%;
}

.bank-card__product-date_container {
  display: flex;
  gap: 1em;
}

.bank-card__product-date {
  display: flex;
  flex-direction: column;
}

.bank-card__month-input {
  margin-right: 1em;
}

.bank-card__product-cvv {
  display: flex;
  flex-direction: column;
}

.payment-method_pay {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1em;

  @include laptop {
    display: none;
  }
}

.payment-method_card {
  cursor: pointer;
  width: 3.5em;
}

.payment-method_oxxo {
  cursor: pointer;
  width: 4.5em;
}

.payment-method-shadow {
  border-radius: 8%;
  box-shadow: #0a0a0a 0 3px 13px 0;
}
</style>
