/* eslint-disable key-spacing,object-shorthand,no-unused-vars */
import APIService from "@/services/API";

const SignUpUsingEmailServiceRequest = (email, password) => ({ email, password });

const SignUpUsingEmailResponse = (jwt) => ({ jwt });

class SignUpUsingEmailService extends APIService {
  constructor(signUpUsingEmailServiceRequest) {
    super();
    this.serviceURL = "unregistered-user/sign-up-using-email/";
    this.response = null;
    this.request = signUpUsingEmailServiceRequest;
  }

  async do() {
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return SignUpUsingEmailResponse(this.response.data.jwt);
  }
}

export { SignUpUsingEmailService, SignUpUsingEmailServiceRequest };
