var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products"},[_c('ContentSidebarLayout',[_c('div',{staticClass:"products__list",attrs:{"slot":"main-content","data-test-id":"products-list"},slot:"main-content"},[_vm._l((_vm.products),function(product){return _c('div',{key:product.id,class:{
          'products__list_6months--hidden':
            (product.productId === _vm.SIX_MONTHS && _vm.minutesLeft > 0) ||
            (product.productId === _vm.SIX_MONTHS_DISCOUNT && _vm.minutesLeft <= 0),
          products__list_3months: product.productId === _vm.THREE_MONTHS,
          products__list_6months: product.productId === _vm.SIX_MONTHS || product.productId === _vm.SIX_MONTHS_DISCOUNT,
          products__list_coins_bundle: product.productId === _vm.COINS_BUNDLE,
        }},[_c('CoinsProduct',{attrs:{"promotion-minutes-left":_vm.minutesLeft,"product":product},on:{"time-out":_vm.timeOut,"open-payment-method-modal":_vm.openPaymentMethodModal}})],1)}),_c('div',{staticClass:"products__info"},[_c('p',{staticClass:"products__info-text"},[_vm._v(" Metodos de pago: "),_c('br'),_vm._v(" - Tarjetas de crédito o débito "),_c('br'),_vm._v(" - Oxxo ")])])],2)]),_c('div',[_c('PaymentMethodModal',{attrs:{"data-test-id":"payment-method-modal","is-visible":_vm.isPaymentMethodModalOpen,"product":_vm.selectedProduct,"oxxo-payment-info":_vm.oxxoPaymentInfo},on:{"hide-modal":_vm.closePaymentMethodModal,"buy-with":_vm.buyWith}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }