import DailyExam from "@/use_cases/show_exam/daily_exam/GetDailyExam";

export default {
  async startDailyExam(courseProgressId) {
    return DailyExam.getDailyExam({
      idCourseProgress: courseProgressId,
      date: this.getActualDate(),
    });
  },
  getActualDate() {
    const actualDate = new Date();
    const year = actualDate.getFullYear();
    const month = `0${actualDate.getMonth() + 1}`.slice(-2);
    const day = `0${actualDate.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  },
};
