import APIService from "@/services/API";

const BuyProductWithCardRequest = function constructor({ productId, cardToken }) {
  this.id_product = productId;
  this.card_token = cardToken;
};

const BuyProductWithCardSuccessResponse = function constructor(data) {
  this.data = data;
};
class BuyProductWithCardService extends APIService {
  constructor(buyProductWithCardRequest) {
    super();
    this.serviceURL = "student/buy-product-with-card/";
    this.request = buyProductWithCardRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return new BuyProductWithCardSuccessResponse(this.response.data);
  }
}

export { BuyProductWithCardService, BuyProductWithCardRequest };
