import APIService from "@/services/API";

/* eslint-disable camelcase */
const SendFeedbackRequest = function constructor({
  text,
  hint_id,
  client,
  answer_sheet_item_id,
  explanation_id,
  score,
}) {
  this.text = text;
  this.hint_id = hint_id;
  this.client = client;
  this.answer_sheet_item_id = answer_sheet_item_id;
  this.explanation_id = explanation_id;
  this.score = score;
};
/* eslint-enable camelcase */

const SendFeedbackResponse = function constructor() {};

class SendFeedbackService extends APIService {
  constructor(sendFeedbackRequest) {
    super();
    this.serviceURL = "student/send-feedback/";
    this.request = sendFeedbackRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return SendFeedbackResponse(this.response.data);
  }
}

export { SendFeedbackService, SendFeedbackRequest };
