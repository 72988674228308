// eslint-disable-next-line import/named
import { GetCoursesService, GetCoursesRequest } from "./GetCoursesService";

// eslint-disable-next-line no-unused-vars,camelcase
const GetCoursesResponse = function constructor({
  // eslint-disable-next-line camelcase
  id_course_progress,
  name,
  // eslint-disable-next-line camelcase
  image_url,
  // eslint-disable-next-line camelcase
  institution_id,
  // eslint-disable-next-line camelcase
  major_name,
  // eslint-disable-next-line camelcase
  major_id,
} = {}) {
  // eslint-disable-next-line camelcase
  this.id_course_progress = id_course_progress;
  this.name = name;
  // eslint-disable-next-line camelcase
  this.image_url = image_url;
  // eslint-disable-next-line camelcase
  this.institution_id = institution_id;
  // eslint-disable-next-line camelcase
  this.major_name = major_name;
  // eslint-disable-next-line camelcase
  this.major_id = major_id;
};

const getCourses = async () => {
  const getCoursesService = new GetCoursesService(GetCoursesRequest({}));
  const serviceResponse = await getCoursesService.do();
  return serviceResponse.map((responseData) => new GetCoursesResponse(responseData));
};

const ShowProducts = { getCourses };

export default ShowProducts;
