import APIService from "@/services/API";

const ShowExplanationServiceRequest = function constructor({ answerSheetItemId }) {
  this.id_answer_sheet_item = answerSheetItemId;
};

/* eslint-disable camelcase */
const ShowExplanationServiceServiceResponse = function constructor({
  exercise_text,
  exercise_image,
  exercise_number,
  answer_selected_text,
  answer_selected_image,
  answer_correct_image,
  answer_correct_text,
  is_correct,
  explanation_id,
  explanation_text,
  explanation_image,
} = {}) {
  this.exerciseText = exercise_text;
  this.exerciseImage = exercise_image;
  this.exerciseNumber = exercise_number;
  this.selectedAnswerText = answer_selected_text;
  this.selectedAnswerImage = answer_selected_image;
  this.correctAnswerImage = answer_correct_image;
  this.correctAnswerText = answer_correct_text;
  this.isCorrect = is_correct;
  this.explanationId = explanation_id;
  this.explanationText = explanation_text;
  this.explanationImage = explanation_image;
};

class ShowExplanationService extends APIService {
  constructor(showExplanationServiceRequest) {
    super();
    this.serviceURL = "student/show-explanation/";
    this.request = showExplanationServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return new ShowExplanationServiceServiceResponse(this.response.data);
  }
}

export { ShowExplanationServiceRequest, ShowExplanationService };
