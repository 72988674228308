import APIService from "@/services/API";

const GetProfileRequest = function constructor() {};

/* eslint-disable camelcase */
const GetProfileResponse = function constructor({ full_name, email, package: pkg, referral_code }) {
  this.fullName = full_name;
  this.email = email;
  this.package = pkg;
  this.referralCode = referral_code;
};
/* eslint-enable camelcase */

class GetProfileService extends APIService {
  constructor(getProfileRequest) {
    super();
    this.serviceURL = "student/profile/";
    this.request = getProfileRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, this.request);
    return new GetProfileResponse(this.response.data);
  }
}

export { GetProfileService, GetProfileRequest };
