<template>
  <div class="products">
    <ContentSidebarLayout>
      <div slot="main-content" class="products__list" data-test-id="products-list">
        <div
          v-for="product in products"
          :key="product.id"
          :class="{
            'products__list_6months--hidden':
              (product.productId === SIX_MONTHS && minutesLeft > 0) ||
              (product.productId === SIX_MONTHS_DISCOUNT && minutesLeft <= 0),
            products__list_3months: product.productId === THREE_MONTHS,
            products__list_6months: product.productId === SIX_MONTHS || product.productId === SIX_MONTHS_DISCOUNT,
            products__list_coins_bundle: product.productId === COINS_BUNDLE,
          }"
        >
          <CoinsProduct
            :promotion-minutes-left="minutesLeft"
            :product="product"
            @time-out="timeOut"
            @open-payment-method-modal="openPaymentMethodModal"
          />
        </div>

        <div class="products__info">
          <p class="products__info-text">
            Metodos de pago: <br />
            - Tarjetas de crédito o débito <br />
            - Oxxo
          </p>
        </div>
      </div>
    </ContentSidebarLayout>

    <div>
      <PaymentMethodModal
        data-test-id="payment-method-modal"
        :is-visible="isPaymentMethodModalOpen"
        :product="selectedProduct"
        :oxxo-payment-info="oxxoPaymentInfo"
        @hide-modal="closePaymentMethodModal"
        @buy-with="buyWith"
      />
    </div>
  </div>
</template>

<script>
import localForage from "localforage";
import ShowProducts from "@/use_cases/show_products/ShowProducts";
import BuyProduct from "@/use_cases/buy_product/BuyProduct";
import CoinsProduct from "@/components/coins_product/CoinsProduct.vue";
import PaymentMethodModal from "../buy_product/PaymentMethodModal.vue";
import ContentSidebarLayout from "../../components/ContentSidebarLayout.vue";

const COINS_BUNDLE = 1;
const THREE_MONTHS = 2;
const SIX_MONTHS = 3;
const SIX_MONTHS_DISCOUNT = 37;

export default {
  name: "ShowProductsPage",
  components: {
    CoinsProduct,
    ContentSidebarLayout,
    PaymentMethodModal,
  },

  data() {
    return {
      /**
       * @typedef Product
       * @type {object}
       * @property {number} productId
       * @property {string} name
       * @property {string} description
       * @property {number} price
       * @property {string} image
       * @property {string} type
       */

      /** @type {Product} * */
      selectedProduct: {},

      /** @type {Product[]} * */
      products: [],

      isPaymentMethodModalOpen: false,

      oxxoPaymentInfo: {},
      bankPaymentInfo: {},

      minutesLeft: 0,
      initialTime: null,
      promotionTime: null,
    };
  },

  async created() {
    this.COINS_BUNDLE = COINS_BUNDLE;
    this.THREE_MONTHS = THREE_MONTHS;
    this.SIX_MONTHS = SIX_MONTHS;
    this.SIX_MONTHS_DISCOUNT = SIX_MONTHS_DISCOUNT;
  },

  async mounted() {
    await this.requestShowProductsService();
    this.initialTime = await localForage.getItem("initialTime");
    this.promotionTime = await localForage.getItem("promotionTime");
    const now = new Date().getTime();
    if (this.promotionTime != null) {
      this.minutesLeft = (this.promotionTime - Math.round((now - this.initialTime) / 1000)) / 60;
    }
  },

  methods: {
    async timeOut() {
      this.minutesLeft = 0;
    },
    async requestShowProductsService() {
      this.products = await ShowProducts.getProducts();
      this.products.push({
        productId: 37,
        name: "6 meses de Ingresapp Premium",
        description:
          // eslint-disable-next-line max-len
          "Acceso ilimitado durante seis meses a toda las funciones de Ingresapp. Recibiras 10 mil monedas para que las uses como prefieras.",
        price: 359,
        image: "https://ingresapp-api-new.s3.amazonaws.com/media/treasure.png",
        type: "coins_bundle",
      });
      this.$emit("load-finished");
    },
    openPaymentMethodModal(product) {
      this.selectedProduct = product;
      this.isPaymentMethodModalOpen = true;
    },
    closePaymentMethodModal() {
      this.isPaymentMethodModalOpen = false;
    },
    buyWith(method) {
      switch (method) {
        case "oxxo":
          this.buyWithOxxo();
          break;
      }
    },
    async buyWithOxxo() {
      const { productId } = this.selectedProduct;
      this.oxxoPaymentInfo = await BuyProduct.BuyProductWithOxxo({ productId });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_resolutions.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define products */
.products {
  width: 100%;
  height: 100%;
}

.products__list {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  @include laptop {
    align-content: space-between;
  }
}

.products__list_3months {
  order: 2;
  width: 100%;
  margin-top: 0.75em;

  @include laptop {
    margin-top: 0;
    width: 48%;
  }
}

.products__list_6months {
  order: 1;
  width: 100%;

  @include laptop {
    height: 20em;
  }

  &--hidden {
    display: none;
  }
}

.products__list_coins_bundle {
  order: 3;
  width: 100%;
  margin-top: 0.75em;

  @include laptop {
    margin-top: 0;
    margin-left: auto;
    width: 48%;
  }
}

.products__info {
  order: 4;
  text-align: center;
  width: 100%;
}

.products__info-text {
  @extend %font-body-1;
}
</style>
