import {
  ShowSimulationExamsService,
  ShowSimulationExamsServiceRequest,
} from "@/use_cases/show_simulation_exams/ShowSimulationExamsService";

const ROMAN_NUMBERS = { 1: "I", 2: "II", 3: "III", 4: "IV", 5: "V", 6: "VI", 7: "VII", 8: "VIII", 9: "IX", 10: "X" };
const MONTHS = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};
const getSimulationExamName = (simulationExamDate, simulationExamNumber) => {
  let simulationExamName = MONTHS[simulationExamDate.getMonth()];
  if (simulationExamNumber > 0) {
    simulationExamName = `${MONTHS[simulationExamDate.getMonth()]} ${ROMAN_NUMBERS[simulationExamNumber]}`;
  }
  return simulationExamName;
};

/** @type {SimulationExam[]} * */
/**
 * @typedef SimulationExam
 * @type {object}
 * @property {string} simulationExamId
 * @property {string} courseName
 * @property {string} releaseDate
 * @property {string} finishedAnswerSheetId
 * @property {string} currentAnswerSheetItemId
 * @property {string} unfinishedAnswerSheetId
 * @property {string} freeReleaseEndDate
 * @property {float} answerSheetProgress
 * @property {number} year
 * @property {string} name
 */

/** @type {SimulationExam[]} * */
const groupSimulationExamsByYear = (simulationExams) => {
  let simulationExamsNames = {};
  const groupedSimulationExams = [];
  let simulationExamNumber = 0;
  const sortedSimulationExams = simulationExams.sort((a, b) =>
    new Date(a.releaseDate) >= new Date(b.releaseDate) ? 1 : -1,
  );

  let groupYear = 0;
  let simulationExamDate = 0;
  let groupCounter = -1;
  let groupIntCounter = 0;
  let isNewGroup = false;
  for (let index = 0; index < sortedSimulationExams.length; index += 1) {
    simulationExamDate = new Date(sortedSimulationExams[index].releaseDate);
    sortedSimulationExams[index].year = simulationExamDate.getFullYear();
    if (groupYear !== sortedSimulationExams[index].year) {
      simulationExamsNames = {};
      groupYear = sortedSimulationExams[index].year;
      groupCounter += 1;
      groupIntCounter = 1;
      isNewGroup = true;
    }
    sortedSimulationExams[index].name = getSimulationExamName(simulationExamDate, 0);

    if (sortedSimulationExams[index].name in simulationExamsNames) {
      simulationExamsNames[sortedSimulationExams[index].name] += 1;
      simulationExamNumber = simulationExamsNames[sortedSimulationExams[index].name];

      if (simulationExamNumber === 2) {
        simulationExamDate = new Date(groupedSimulationExams[groupCounter][groupIntCounter - 1].releaseDate);
        groupedSimulationExams[groupCounter][groupIntCounter - 1].name = getSimulationExamName(simulationExamDate, 1);
      }
      sortedSimulationExams[index].name = getSimulationExamName(simulationExamDate, simulationExamNumber);
    } else {
      simulationExamsNames[sortedSimulationExams[index].name] = 1;
    }

    if (isNewGroup) {
      groupedSimulationExams.push([sortedSimulationExams[index]]);
      isNewGroup = false;
    } else {
      groupedSimulationExams[groupCounter].push(sortedSimulationExams[index]);
      groupIntCounter += 1;
    }
  }
  return groupedSimulationExams.reverse();
};

const getSimulationExams = async ({ courseProgressId }) => {
  const showSimulationExamsServiceRequest = ShowSimulationExamsServiceRequest({
    courseProgressId,
  });
  const showSimulationExamsService = new ShowSimulationExamsService(showSimulationExamsServiceRequest);
  const simulationExams = await showSimulationExamsService.do();
  return groupSimulationExamsByYear(simulationExams);
};

const ShowSimulationExams = { getSimulationExams, MONTHS, ROMAN_NUMBERS };

export default ShowSimulationExams;
