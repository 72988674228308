<template>
  <div class="title-bar__menu-daily-exam">
    <v-app-bar app class="title-bar_daily-exam" data-test-id="daily-exam-bar-button" @click="startDailyExam">
      <CoinsImage class="title-bar_daily-exam-image"></CoinsImage>
      <p class="title-bar_daily-exam-text">Responder pregunta del día</p>
    </v-app-bar>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";
import DailyExamUtils from "@/use_cases/show_subjects/daily_exam/DailyExamUtils";

export default {
  name: "TitleBarNew",
  components: {
    CoinsImage,
  },
  props: {
    courseProgressId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDailyExamModelOpen: false,
    };
  },
  methods: {
    async startDailyExam() {
      const response = await DailyExamUtils.startDailyExam(this.courseProgressId);
      await this.redirectToExamPage(response.answerSheetId, response.currentAnswerSheetItemId);
    },
    async redirectToExamPage(answerSheetId, answerSheetItemId) {
      await this.$router.push({
        name: "DailyExamPage",
        params: {
          answerSheetId,
          answerSheetItemId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/typography";
@import "~@/scss/colors";

/** @define title-bar */
.title-bar__menu-daily-exam {
  cursor: pointer;
  display: block;
  z-index: 3;

  @media (min-width: $laptop-width) {
    display: none;
  }
}

.title-bar_daily-exam {
  height: 4.5em !important;
  margin-top: 55px !important;
  padding-top: 0 !important;
  color: $white !important;
  display: flex !important;
  justify-content: center !important;
  background: $green !important;

  @media (min-width: $laptop-width) {
    margin-top: 35px !important;
  }
}

.title-bar_daily-exam-image {
  margin-bottom: 1em;
  margin-right: 1em;
  width: 3.5em;
}

.title-bar_daily-exam-text {
  margin-top: 0.3em;
  font-size: 1.6em;
}
</style>
