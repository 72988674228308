<template>
  <div class="splash-screen">
    <div class="splash-screen__img-container">
      <LogoImage />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import LogoImage from "@/assets/logo.svg?inline";

export default {
  name: "SplashScreen",
  components: {
    LogoImage,
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/colors";

/** @define splash-screen */
.splash-screen {
  background: $primary-color;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  &__img-container {
    max-width: 560px;
    width: 45%;
  }
}

/** @end */

$feather_animation_duration: 0.4;
$total_feathers: 7;
$first_cycle_duration: $feather_animation_duration * $total_feathers;
$total_duration: $first_cycle_duration * 2;

@for $feather_index from 1 through $total_feathers {
  $delay: ($feather_index - 1) * $feather_animation_duration;
  $key_frame_1: $delay;
  $key_frame_2: $feather_animation_duration + $delay;
  $key_frame_3: $key_frame_2 + $first_cycle_duration;
  $key_frame_4: $key_frame_3 + $feather_animation_duration;

  .logo__feather-#{$feather_index} {
    animation: fade-in-#{$feather_index} #{$total_duration}s cubic-bezier(0.39, 0.575, 0.565, 1) both infinite;
  }

  @keyframes fade-in-#{$feather_index} {
    0% {
      opacity: 1;
    }

    #{ ($key_frame_1 / $total_duration) * 100%} {
      opacity: 1;
    }

    #{ ($key_frame_2 / $total_duration) * 100%} {
      opacity: 0;
    }

    #{ ( $key_frame_3 / $total_duration) * 100%} {
      opacity: 0;
    }

    #{ ( $key_frame_4 / $total_duration) * 100%} {
      opacity: 1;
    }
  }
}
</style>
