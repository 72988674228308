import APIService from "@/services/API";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import HttpStatusCodes from "@/services/HttpStatusCodes";
import RepeatedCourse from "@/exceptions/DesiredMajorHasAlreadyBeenSelected";

const AddCourseServiceRequest = ({ idCourseProgress, idCourse, idDesiredMajor }) => ({
  id_course_progress: idCourseProgress,
  id_course: idCourse,
  id_desired_major: idDesiredMajor,
});

// eslint-disable-next-line camelcase
const AddCourseServiceResponse = ({ id_desired_major }) => ({ idDesiredMajor: id_desired_major });

class AddCourseService extends APIService {
  constructor(addCourseServiceRequest) {
    super();
    this.serviceURL = "student/start-course/";
    this.request = addCourseServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    try {
      this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    } catch (e) {
      switch (e.response.status) {
        case HttpStatusCodes.PRECONDITION_REQUIRED:
          throw new RepeatedCourse(e.response.data.message);
        case HttpStatusCodes.PAYMENT_REQUIRED:
          throw new InsufficientCoins(e.response.data.message);
        default:
          console.log(e);
      }
    }
    return AddCourseServiceResponse(this.response.data);
  }
}

export { AddCourseService, AddCourseServiceRequest };
