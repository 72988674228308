<template>
  <h2 class="social-auth_title">Auntentificando Cuenta...</h2>
</template>

<script>
import axios from "axios";
import localForage from "localforage";
import facebookTracking from "@/facebookTracking";

const baseURL = process.env.VUE_APP_API_URL;
const redirectUri = `${window.location.origin}/uu/ingresar/social-auth`;
const uriGoogle = "/google";
const uriFacebook = "/facebook";
const endPointGoogle = "/api/use-cases/google-auth/";
const endPointFacebook = "/api/use-cases/facebook-auth/";

export default {
  name: "SocialAuth",
  data() {
    return {
      url: new URL(window.location.href),
    };
  },
  created() {
    this.checkUrlAccount();
  },
  methods: {
    async checkUrlAccount() {
      const code = this.url.searchParams.get("code");
      if (this.url.href.includes("google")) {
        await this.loginWithSocialAccount(code, endPointGoogle, uriGoogle);
      } else if (this.url.href.includes("facebook")) {
        await this.loginWithSocialAccount(code, endPointFacebook, uriFacebook);
      }
    },
    async loginWithSocialAccount(code, endPoint, uri) {
      try {
        const requestData = {
          code,
          redirect_uri: redirectUri + uri,
        };
        const responseAPI = await axios.post(baseURL + endPoint, requestData);
        const socialAuthProvider = uri.replace("/", "");
        await this.redirectUserLoggedWithSocialAccount(responseAPI.data, socialAuthProvider);
      } catch (err) {
        console.log(err.message);
        await this.$router.push({ name: "LoginSignUpPage" });
      }
    },
    async redirectUserLoggedWithSocialAccount(response, socialAuthProvider) {
      try {
        await localForage.setItem("JWT", response.jwt);
        if (response.is_student_registration_pending) {
          await localForage.setItem("isStudentRegistrationPending", true);

          this.$gtag.event("sign_up_started", {
            auth_provider: socialAuthProvider,
          });

          facebookTracking.trackEvent({ eventName: "sign_up_started", params: { auth_provider: socialAuthProvider } });

          await this.$router.push({ name: "DemoPage" });
        } else {
          await this.$router.push({ name: "Student" });
        }
      } catch (err) {
        console.log(err.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

.social-auth_title {
  text-align: center;

  @extend %font-section-title-1;
}
</style>
