import { GetProfileService, GetProfileRequest } from "@/use_cases/profile/GetProfileService";

/* eslint-disable camelcase */
const GetProfileResponse = function constructor({ fullName, email, package: pkg, referralCode }) {
  this.fullName = fullName;
  this.email = email;
  this.package = pkg;
  this.referralCode = referralCode;
};
/* eslint-disable camelcase */

/* eslint-disable camelcase */
const getProfile = async () => {
  const GetProfileServiceRequest = new GetProfileRequest();
  const getProfileService = new GetProfileService(GetProfileServiceRequest);
  return new GetProfileResponse(await getProfileService.do());
};
/* eslint-enable camelcase */

const GetProfile = { getProfile };

export default GetProfile;
