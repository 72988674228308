import APIService from "@/services/API";
import DailyExamIsNotAvailable from "@/exceptions/DailyExamIsNotAvailable";

const IsDailyExamAvailableServiceRequest = function constructor({ courseProgressId }) {
  this.course_progress_id = courseProgressId;
};

// eslint-disable-next-line camelcase
const IsDailyExamAvailableServiceResponse = function constructor({ is_daily_exam_available }) {
  // eslint-disable-next-line camelcase
  this.isDailyExamAvailable = is_daily_exam_available;
};

class IsDailyExamAvailableService extends APIService {
  constructor(isAvailableDailyExamServiceRequest) {
    super();
    this.serviceURL = "student/is-daily-exam-available/";
    this.request = isAvailableDailyExamServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    try {
      return new IsDailyExamAvailableServiceResponse(this.response.data);
    } catch (e) {
      throw new DailyExamIsNotAvailable(e.response.data.message);
    }
  }
}

export { IsDailyExamAvailableService, IsDailyExamAvailableServiceRequest };
