import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import GraduationCapIcon from "@/components/icons/GraduationCapIcon.vue";
import CourseIcon from "@/components/icons/CourseIcon.vue";
import StoreIcon from "@/components/icons/StoreIcon.vue";
import CoinsIcon from "@/components/icons/CoinsIcon.vue";
import ExamIcon from "@/components/icons/ExamIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1b61dd",
        secondary: "#fbb03b",
        accent: "#fccf89",
        error: "#f04747",
        success: "#47b547",
      },
    },
  },
  icons: {
    values: {
      graduationCap: {
        component: GraduationCapIcon,
      },
      course: {
        component: CourseIcon,
      },
      store: {
        component: StoreIcon,
      },
      coins: {
        component: CoinsIcon,
      },
      exam: {
        component: ExamIcon,
      },
    },
  },
});
