<template>
  <OneButtonModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    style-modifier="transparent"
    @button-click="emitButtonEvents"
    @hide-modal="emitHideModalEvent"
  >
    <div slot="image" class="course-price-modal__img">
      <div class="course-price-modal__twelve-coins-img">
        <div class="twelve-coins-img__img">
          <TwelveCoinsImage />
        </div>
      </div>
    </div>

    <div slot="message" class="course-price-modal__message">
      <p>Este curso tiene un costo de 50 monedas.</p>
    </div>

    <span slot="buttonText" class="course-price-modal__btn-text">Añadir</span>
  </OneButtonModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import TwelveCoinsImage from "@/assets/twelve-coins.svg?inline";
import OneButtonModal from "@/components/one_button_modal/OneButtonModal.vue";

export default {
  name: "CoursePriceModal",
  components: {
    OneButtonModal,
    TwelveCoinsImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitButtonEvents() {
      this.emitContinueWithPurchaseCourse();
      this.emitHideModalEvent();
    },
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitContinueWithPurchaseCourse() {
      this.$emit("continue-with-purchase-course");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define course-price-modal */
.course-price-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $overlay-color;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.course-price-modal__twelve-coins-img {
  width: 100%;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.course-price-modal__message {
  margin: 2em 0;
}

.course-price-modal__twelve-coins {
  width: 100%;
  display: flex;
}

/** @define twelve-coins */
.twelve-coins__img {
  width: 30%;
  margin-left: 4em;

  @include laptop {
    width: 20%;
  }
}

/** @define twelve-coins-img */
.twelve-coins-img__img {
  width: 25em;

  @include laptop {
    width: 35em;
  }
}
</style>
