<template>
  <div class="exercise-answer">
    <label :class="'exercise-answer__label ' + 'exercise-answer__label--' + styleModifier" :for="`answer-${answer.id}`">
      <input
        :id="`answer-${answer.id}`"
        :type="isCheckBox ? 'checkbox' : 'radio'"
        class="exercise-answer__radio-button"
        :name="name"
        :value="answer.id"
        :checked="checked === answer.id"
        :disabled="disabled"
        @change="change"
      />
      <span :class="'exercise-answer__check-mark ' + 'exercise-answer__check-mark--' + styleModifier" />
      <span v-show="hasText" :data-test-id="`answer-text-${answer.id}`">{{ answer.text }}</span>
      <span v-show="answer.imageUrl !== ''" :data-test-id="`answer-img-${answer.id}`">
        <img :src="answer.imageUrl" class="exercise-answer__img" alt="" />
      </span>
    </label>
  </div>
</template>

<script>
const IMAGE_ONLY_ANSWER_TEXT = ".";
export default {
  name: "ExerciseAnswer",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    /** @type {{ id: number, text: string, imageUrl: string }} * */
    answer: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    styleModifier: {
      type: String,
      required: true,
    },
    checked: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCheckBox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    hasText() {
      return this.answer.text !== IMAGE_ONLY_ANSWER_TEXT;
    },
  },
  methods: {
    change(event) {
      this.$emit("change", parseInt(event.target.value, 10));
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_resolutions.scss";

/** @define exercise-answer */
.exercise-answer {
  @extend %font-body-2;

  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0.35em 0 1.5em 1em;

  @include laptop {
    margin-bottom: 1.5em;
    padding: 0 0 0 2em;
  }
}

.exercise-answer__label {
  position: relative;
  width: 100%;
  padding-left: 2em;
  cursor: pointer;

  &--success {
    color: $success-color;
  }

  &--error {
    color: $error-color;
  }
}

.exercise-answer__radio-button {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  vertical-align: middle;
}

.exercise-answer__check-mark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 0.9em;
  width: 0.9em;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $black;
  vertical-align: middle;

  &::after {
    content: "";
    position: absolute;
    display: none;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    border-radius: 100%;
  }

  &--primary::after {
    background: $primary-color;
  }

  &--success::after {
    background: $success-color;
  }

  &--error::after {
    background: $error-color;
  }
}

.exercise-answer__radio-button:checked ~ .exercise-answer__check-mark {
  &--primary {
    border-color: $primary-color;
  }

  &--success {
    border-color: $success-color;
  }

  &--error {
    border-color: $error-color;
  }
}

.exercise-answer__radio-button:checked ~ .exercise-answer__check-mark::after {
  display: block;
}

.exercise-answer__img {
  max-width: 100%;
}
</style>
