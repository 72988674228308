import APIService from "@/services/API";

const ShowSimulationExamsServiceRequest = ({ courseProgressId }) => ({ course_progress_id: courseProgressId });

/* eslint-disable camelcase */
const ShowSimulationExamsServiceResponse = ({
  simulation_exam_id,
  course_name,
  release_date,
  finished_answer_sheet_id,
  current_answer_sheet_item_id,
  unfinished_answer_sheet_id,
  free_release_end_date,
  answer_sheet_progress,
}) => ({
  simulationExamId: simulation_exam_id,
  courseName: course_name,
  releaseDate: release_date,
  finishedAnswerSheetId: finished_answer_sheet_id,
  currentAnswerSheetItemId: current_answer_sheet_item_id,
  unfinishedAnswerSheetId: unfinished_answer_sheet_id,
  freeReleaseEndDate: free_release_end_date,
  answerSheetProgress: answer_sheet_progress,
});

class ShowSimulationExamsService extends APIService {
  constructor(searchMajorRequest) {
    super();
    this.serviceURL = "student/show-simulation-exams/";
    this.response = null;
    this.request = searchMajorRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    return this.response.data.map((responseData) => ShowSimulationExamsServiceResponse(responseData));
  }
}

export { ShowSimulationExamsService, ShowSimulationExamsServiceRequest };
