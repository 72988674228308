import axios from "axios";
import httpAdapter from "axios/lib/adapters/http";
import localForage from "localforage";

export default class APIService {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL;
    this.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: "",
    };
  }

  // eslint-disable-next-line class-methods-use-this
  do() {
    throw new Error("You have to implement the method do");
  }

  async setAuthorizationHeaders() {
    const jwt = await localForage.getItem("JWT");
    this.headers.Authorization = `Bearer ${jwt}`;
  }

  createHttpRequest() {
    return axios.create({
      baseURL: `${this.baseURL}/api/use-cases/`,
      headers: this.headers,
      adapter: httpAdapter,
    });
  }
}
