import localForage from "localforage";
import {
  RegisterAsAStudentService,
  RegisterAsAStudentServiceRequest,
} from "@/use_cases/register_as_a_student/RegisterAsAStudentService";

const RegisterAsAStudentResponse = ({ courseProgressId }) => ({ courseProgressId });

const registerAsAStudent = async ({ name, lastName, referrerCode, desiredMajorId }) => {
  const registerAsAStudentServiceRequest = RegisterAsAStudentServiceRequest({
    name,
    lastName,
    referrerCode,
    desiredMajorId,
  });
  const registerAsAStudentService = new RegisterAsAStudentService(registerAsAStudentServiceRequest);
  const response = RegisterAsAStudentResponse(await registerAsAStudentService.do());
  await localForage.setItem("isStudentRegistrationPending", false);
  return response;
};

const RegisterAsAStudent = { registerAsAStudent };

export default RegisterAsAStudent;
