<template>
  <div class="navigation-panel">
    <CollapsibleItem
      v-for="(subject, index) in subjects"
      :key="index"
      :is-open="collapsibleItems[index]"
      :items-length="subject.items.length"
      :is-mobile-resolution="isMobileResolution"
      class="navigation-panel__subject-item"
      :data-test-id="'collapsible-item-component-' + index"
      @collapsible-item-clicked="updateItems(index)"
    >
      <div slot="title">{{ subject.name }}</div>
      <div slot="content" class="subject-item__content">
        <QuestionCircleIcon
          v-for="item in subject.items"
          :key="item.itemId"
          :is-answered="item.isAnswered"
          :value="item.itemNumber"
          class="content__question-icon"
          :data-test-id="'question-circle-icon-' + item.itemId"
          @click="emitLoadItem(item.itemId)"
        ></QuestionCircleIcon>
      </div>
    </CollapsibleItem>
  </div>
</template>
<script>
import CollapsibleItem from "@/components/collapsible_item/CollapsibleItem.vue";
import QuestionCircleIcon from "@/components/navigation_panel/QuestionCircleIcon.vue";

export default {
  name: "NavigationPanel",
  components: {
    QuestionCircleIcon,
    CollapsibleItem,
  },
  props: {
    /** @type {subject[]} * */
    /**
     * @typedef subject
     * @type {object}
     * @property {string} name
     * @property {item[]} items * */
    /** @typedef item
     * @type {object}
     * @property {number} itemNumber
     * @property {number} itemId
     * @property {boolean} isAnswered
     * */
    subjects: {
      type: Array,
      required: true,
    },
    isMobileResolution: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsibleItems: [],
    };
  },
  watch: {
    isMobileResolution() {
      return this.isMobileResolution;
    },
  },
  methods: {
    created() {
      for (let index = 0; index < this.subjects.length; index += 1) {
        this.collapsibleItems.push(false);
      }
    },
    updateItems(itemNumber) {
      for (let index = 0; index < this.subjects.length; index += 1) {
        if (index === itemNumber) {
          this.$set(this.collapsibleItems, index, !this.collapsibleItems[index]);
        } else {
          this.$set(this.collapsibleItems, index, false);
        }
      }
    },
    emitLoadItem(itemId) {
      this.$emit("load-item", itemId);
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define navigation-panel */
.navigation-panel {
  display: flex;
  flex-direction: column;
  color: $white;
  width: 100%;
}

.navigation-panel__subject-item {
  width: 100%;
}

/** @define subject-item */
.subject-item__content {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 0.5rem;
  padding-left: 8%;
}

/** @define content */
.content__question-icon {
  width: 2em;
  margin: 0.2em;
}
</style>
