<template>
  <BaseModal
    :is-close-button-visible="true"
    :is-visible="isVisible"
    style-modifier="secondary"
    @hide-modal="$emit('hide-modal')"
  >
    <template #message>
      <div>
        <div>Compra exitosa</div>
        <blockquote>
          <p>Agradecemos tu compra y te deseamos mucho éxito</p>
        </blockquote>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "SuccessfulPaymentModal",
  components: { BaseModal },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
