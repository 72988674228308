import APIService from "@/services/API";

/* eslint-disable camelcase */
const ReportIssueRequest = function constructor({ text, issue_types, client, answer_sheet_item_id }) {
  this.text = text;
  this.issue_types = issue_types;
  this.client = client;
  this.answer_sheet_item_id = answer_sheet_item_id;
};
/* eslint-enable camelcase */

const ReportIssueResponse = function constructor() {};

class ReportIssueService extends APIService {
  constructor(reportIssueRequest) {
    super();
    this.serviceURL = "student/report-an-issue/";
    this.request = reportIssueRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return ReportIssueResponse(this.response.data);
  }
}

export { ReportIssueService, ReportIssueRequest };
