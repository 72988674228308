import APIService from "@/services/API";

const EvaluateAnswerSheetItemServiceRequest = ({ answerSheetId, answerSheetItemId, selectedAnswerId, time }) => ({
  answer_sheet_id: answerSheetId,
  answer_sheet_item_id: answerSheetItemId,
  selected_answer_id: selectedAnswerId,
  time,
});

// eslint-disable-next-line camelcase
const EvaluateAnswerSheetItemServiceResponse = ({ is_correct, messages }) => ({ is_correct, messages });

class EvaluateAnswerSheetItemService extends APIService {
  constructor(getAnswerSheetItemsServiceRequest) {
    super();
    this.serviceURL = "student/evaluate-answer-sheet-item/";
    this.request = getAnswerSheetItemsServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().put(this.serviceURL, this.request);
    return EvaluateAnswerSheetItemServiceResponse(this.response.data);
  }
}

export { EvaluateAnswerSheetItemService, EvaluateAnswerSheetItemServiceRequest };
