import {
  ValidateIfEmailIsAlreadyRegisteredService,
  ValidateIfEmailIsAlreadyRegisteredServiceRequest,
} from "./services/ValidateIfEmailIsAlreadyRegisteredService";

const ValidateIfEmailIsAlreadyRegisteredResponse = ({ isRegistered, authProvider }) => ({
  isRegistered,
  authProvider,
});

const validateIfEmailIsAlreadyRegistered = async ({ email }) => {
  const request = ValidateIfEmailIsAlreadyRegisteredServiceRequest(email);
  const service = new ValidateIfEmailIsAlreadyRegisteredService(request);
  return ValidateIfEmailIsAlreadyRegisteredResponse(await service.do());
};

const LoginSignUp = { validateIfEmailIsAlreadyRegistered };

export default LoginSignUp;
