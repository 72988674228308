import {
  GetAnswerSheetResultsSummaryService,
  GetAnswerSheetResultsSummaryServiceRequest,
} from "@/use_cases/show_answer_sheet_results_summary/GetAnswerSheetResultsSummaryService";

const GetAnswerSheetResultsSummaryResponse = function constructor({
  numberOfExercises,
  numberOfCorrectAnswers,
  spentTimeInSeconds,
  subjectsResults,
} = {}) {
  this.numberOfExercises = numberOfExercises;
  this.numberOfCorrectAnswers = numberOfCorrectAnswers;
  this.spentTimeInSeconds = spentTimeInSeconds;
  this.subjectsResults = subjectsResults;
};

/** @returns {GetAnswerSheetResultsSummaryResponse} * */
const getAnswerSheetResultsSummary = async ({ answerSheetId }) => {
  const serviceRequest = new GetAnswerSheetResultsSummaryServiceRequest({ answerSheetId });
  const service = new GetAnswerSheetResultsSummaryService(serviceRequest);
  return new GetAnswerSheetResultsSummaryResponse(await service.do());
};

const ShowAnswerSheetResultsSummary = { getAnswerSheetResultsSummary };

export default ShowAnswerSheetResultsSummary;
