import APIService from "@/services/API";

const ShowSimulationExamServiceRequest = function constructor({ answerSheetId, simulationExamId }) {
  this.id_simulation_exam = simulationExamId;
  this.id_answer_sheet = answerSheetId;
};

const mapItem = function constructor(item) {
  return {
    itemNumber: item.item_number,
    itemId: item.id_item,
    isAnswered: item.is_answered,
  };
};
const mapSubject = function constructor(subject) {
  return {
    name: subject.name,
    // eslint-disable-next-line func-names
    items: subject.items.map(mapItem),
  };
};
/* eslint-disable camelcase */
const ShowSimulationExamServiceResponse = function constructor({ remain_time, subjects } = {}) {
  this.remainTime = remain_time;
  /** @type {subject[]} * */
  /**
   * @typedef subject
   * @type {object}
   * @property {string} name
   * @property {item[]} items * */
  /** @typedef item
   * @type {object}
   * @property {number} itemNumber
   * @property {number} itemId
   * @property {boolean} isAnswered
   * */
  this.subjects = subjects
    // eslint-disable-next-line func-names
    .map(mapSubject)
    // eslint-disable-next-line func-names
    .sort(function (subjectA, subjectB) {
      return subjectA.items[0].itemNumber - subjectB.items[0].itemNumber;
    });
};
/* eslint-enable camelcase */

class ShowSimulationExamService extends APIService {
  constructor(showSimulationExamServiceRequest) {
    super();
    this.serviceURL = "student/show-simulation-exam/";
    this.request = showSimulationExamServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    return new ShowSimulationExamServiceResponse(this.response.data);
  }
}

export { ShowSimulationExamService, ShowSimulationExamServiceRequest };
