<template>
  <v-btn :to="{ name: 'ShowProductsPage' }" outlined class="white--text title-bar__coins">
    <v-icon large left>$vuetify.icons.coins</v-icon>
    <span data-test-id="coins__amount">{{ coinsBalance }}</span>
  </v-btn>
</template>

<script>
// eslint-disable-next-line import/extensions
import StudentCoins from "@/use_cases/show_student_coins/ShowStudentCoinsBalance";
import facebookTracking from "@/facebookTracking";

export default {
  name: "StudentCoinsView",
  components: {},
  data() {
    return {
      coinsBalance: 0,
    };
  },
  watch: {
    "$route.path": {
      async handler() {
        await this.loadBalance();
      },
    },
  },
  methods: {
    async loadBalance() {
      if (this.isAStudentPath()) {
        await this.loadStoredStudentBalance();
        this.requestStudentBalance();
      }
    },
    async loadStoredStudentBalance() {
      const storedStudentBalance = await StudentCoins.StudentStoredBalance();
      this.setStudentBalance(storedStudentBalance);
    },
    async requestStudentBalance() {
      const requestedStudentBalance = await StudentCoins.GetStudentBalance();

      if (requestedStudentBalance.isCoinsBundlePurchaseEventPending) {
        StudentCoins.SetCoinsBundlePurchaseEventAsSent();

        this.$gtag.purchase({
          currency: "MXN",
          value: 99,
        });
        facebookTracking.trackEvent({ eventName: "Purchase", params: { currency: "MXN", value: 99.0 } });
      }

      this.setStudentBalance(requestedStudentBalance);
    },
    setStudentBalance({ coinsBalance }) {
      this.coinsBalance = coinsBalance;
    },
    isAStudentPath() {
      const { path } = this.$route;
      if (path.length === 2 && path.substr(0, 2) === "/s") {
        return true;
      }
      return path.length > 2 && path.substr(0, 3) === "/s/";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

.title-bar__coins {
  @extend %font-coins;

  border: none !important;
}
</style>
