import APIService from "@/services/API";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import HttpStatusCodes from "@/services/HttpStatusCodes";

const StartGeneralExamServiceRequest = ({ numberOfExercises, courseProgressId }) => ({
  number_of_exercises: numberOfExercises,
  course_progress_id: courseProgressId,
});

/* eslint-disable camelcase */
const StartGeneralExamServiceResponse = ({
  answer_sheet_id,
  current_answer_sheet_item_id,
  is_answer_sheet_started,
}) => ({
  answerSheetId: answer_sheet_id,
  currentAnswerSheetItemId: current_answer_sheet_item_id,
  isAnswerSheetStarted: is_answer_sheet_started,
});
/* eslint-enable camelcase */

class StartGeneralExamService extends APIService {
  constructor(startGeneralServiceRequest) {
    super();
    this.serviceURL = "student/start-general-exam/";
    this.request = startGeneralServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    try {
      this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    } catch (e) {
      switch (e.response.status) {
        case HttpStatusCodes.PAYMENT_REQUIRED:
          throw new InsufficientCoins(e.response.data.message);
        default:
          console.log(e);
      }
    }

    return StartGeneralExamServiceResponse(this.response.data);
  }
}

export { StartGeneralExamService, StartGeneralExamServiceRequest };
