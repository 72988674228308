import APIService from "@/services/API";

const SetCoinsBundlePurchaseEventAsSentServiceRequest = function constructor() {};
const SetCoinsBundlePurchaseEventAsSentServiceResponse = function constructor() {};

class SetCoinsBundlePurchaseEventAsSentService extends APIService {
  constructor(setCoinsBundlePurchaseEventAsSentServiceRequest) {
    super();
    this.serviceURL = "/student/set-coins-bundle-purchase-event-as-sent/";
    this.response = null;
    this.request = setCoinsBundlePurchaseEventAsSentServiceRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);

    return new SetCoinsBundlePurchaseEventAsSentServiceResponse(this.response.data);
  }
}

export { SetCoinsBundlePurchaseEventAsSentService, SetCoinsBundlePurchaseEventAsSentServiceRequest };
