import APIService from "@/services/API";
import HttpStatusCodes from "@/services/HttpStatusCodes";
import InsufficientCoins from "@/exceptions/InsufficientCoins";

// eslint-disable-next-line no-unused-vars
const GetHintServiceRequest = function constructor({ answerSheetItemId, hintId }) {
  this.answer_sheet_item_id = answerSheetItemId;
  if (hintId == null) {
    this.hint_id = "";
  } else {
    this.hint_id = hintId;
  }
};

/* eslint-disable camelcase */
const GetHintServiceResponse = function constructor({ id, text, next_hint } = {}) {
  this.id = id;
  this.text = text;
  this.nextHint = next_hint;
};

class GetHintService extends APIService {
  constructor(getHintServiceRequest) {
    super();
    this.serviceURL = "student/get-hint/";
    this.request = getHintServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    try {
      this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    } catch (e) {
      switch (e.response.status) {
        case HttpStatusCodes.PAYMENT_REQUIRED:
          throw new InsufficientCoins(e.response.data.message);
        default:
          console.log(e);
      }
    }
    return new GetHintServiceResponse(this.response.data);
  }
}

export { GetHintServiceRequest, GetHintService, GetHintServiceResponse };
