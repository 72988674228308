import { ReportIssueService, ReportIssueRequest } from "@/use_cases/show_exam/report_an_issue/ReportIssueService";

const ReportIssueResponse = function constructor() {};

/* eslint-disable camelcase */
const reportIssue = async ({ text, issue_types, client, answer_sheet_item_id }) => {
  const reportIssueServiceRequest = new ReportIssueRequest({
    text,
    issue_types,
    client,
    answer_sheet_item_id,
  });
  const reportIssueService = new ReportIssueService(reportIssueServiceRequest);
  return ReportIssueResponse(await reportIssueService.do());
};
/* eslint-enable camelcase */

const ReportIssue = { reportIssue };

export default ReportIssue;
