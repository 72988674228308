<template>
  <div class="answer-sheet-results-by-subject">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <div class="answer-sheet-results-by-subject__heading">
          <h1 class="heading__title">Resultados</h1>
        </div>

        <div class="answer-sheet-results-by-subject__subjects-results">
          <v-container class="pa-0 px-md-0 px-lg-12">
            <v-expansion-panels flat multiple>
              <v-expansion-panel
                v-for="(exercise, index) in exercisesResults"
                :key="index"
                :data-test-id="'collapsible-item-component-' + index"
                @click="updateItems(index)"
              >
                <v-expansion-panel-header class="custom-panel" :data-test-id="'collapsible-item-header-' + index">
                  <div class="custom-panel_header_container">
                    <div>
                      <CorrectImage v-if="exercise.isCorrect" class="exercise__icon" data-test-id="correct-image" />
                      <IncorrectImage v-else class="exercise__icon" data-test-id="incorrect-image" />
                    </div>
                    <span v-if="headerTextVisibility[index]"> {{ index + 1 + ".-" }} </span>
                    <span
                      v-if="headerTextVisibility[index]"
                      v-html="formatTitleItem(exercise.text.toString(), index)"
                    />
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="custom-panel">
                  <v-container class="pl-3 pl-md-5 pl-lg-10">
                    <div v-if="collapsibleContents[index] !== undefined">
                      <div
                        v-if="collapsibleContents[index] !== null"
                        :data-test-id="'collapsible-item-content-' + index"
                      >
                        <div class="exercise__text">
                          <span> {{ index + 1 + ".-" }} </span>
                          <span v-html="collapsibleContents[index].exerciseText" />
                        </div>
                        <div v-if="collapsibleContents[index].exerciseImage" class="exercise__img">
                          <img :src="collapsibleContents[index].exerciseImage" alt="Imagen del ejercicio" />
                        </div>

                        <div v-for="(answer, answerIndex) in collapsibleContents[index].answers" :key="answerIndex">
                          <ExerciseAnswer
                            v-if="collapsibleContents[index] !== null"
                            :key="answer.id"
                            :ref="`exercise-answer-${answer.id}`"
                            :checked="parseInt(collapsibleContents[index].selectedAnswerId)"
                            is-check-box
                            name="exercise-answer"
                            :style-modifier="
                              selectedAnswerStyleModifier(
                                collapsibleContents[index].selectedAnswerId,
                                collapsibleContents[index].correctAnswerId,
                                answer.id,
                              )
                            "
                            :answer="answer"
                            :disabled="true"
                            :data-test-id="`exercise-answer-${answerIndex}`"
                          />
                        </div>

                        <div v-if="collapsibleContents[index].hasExplanation" class="explanation__button">
                          <router-link
                            :to="{
                              name: 'ExplanationPage',
                              params: {
                                answerSheetId,
                                answerSheetItemId: collapsibleContents[index].answerSheetItemId,
                              },
                            }"
                          >
                            <BaseButton
                              class="content__button"
                              style-modifier="secondary"
                              data-test-id="view-explanation-button"
                            >
                              <div class="content__button-container">
                                <div>VER EXPLICACIÓN 5</div>
                                <CoinsImage class="button__image" />
                              </div>
                            </BaseButton>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </div>

        <div class="answer-sheet-results-by-subject__accept-btn">
          <router-link :to="{ name: 'AnswerSheetResultsSummaryPage', params: { answerSheetId } }">
            <BaseButton style-modifier="secondary" data-test-id="accept-button"> Aceptar </BaseButton>
          </router-link>
        </div>
      </div>

      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import localForage from "localforage";
import CorrectImage from "@/assets/correct.svg?inline";
import IncorrectImage from "@/assets/incorrect.svg?inline";
import CoinsImage from "@/assets/coins.svg?inline";
// eslint-disable-next-line max-len
import ShowAnswerSheetResultsBySubject from "@/use_cases/show_answer_sheet_results_by_subject/ShowAnswerSheetResultsBySubject";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import ExerciseAnswer from "@/components/exam_exercise/ExerciseAnswer.vue";

const LAPTOP_WIDTH = 1200;
const MAX_TITLE_LENGTH = 75;
const MAX_TITLE_LENGTH_MOBILE = 25;

export default {
  name: "AnswerSheetResultsBySubjectPage",
  components: {
    ContentSidebarLayout,
    BaseButton,
    CorrectImage,
    IncorrectImage,
    CoinsImage,
    ExerciseAnswer,
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      /** @type {{
       * id: string, number: number, text: string, is_correct: boolean
       * }[]} * */
      exercisesResults: [],
      collapsibleItems: [],
      collapsibleContents: [],
      isMobileResolution: false,
      subjectName: "",
      subjectImage: "",
      headerTextVisibility: {},
    };
  },
  created() {
    this.initializeCollapsibleItems();
  },
  async mounted() {
    const response = await ShowAnswerSheetResultsBySubject.getAnswerSheetResultsBySubject({
      answerSheetId: this.answerSheetId,
      subjectId: this.subjectId,
    });
    // eslint-disable-next-line vue/no-mutating-props
    this.exercisesResults = response.exercisesResults;
    await this.getSubjectNameAndImage();
    this.initializeCollapsibleItems();
    this.isMobileResolution = window.innerWidth < LAPTOP_WIDTH;
    await this.refreshMathJax();
    await localForage.setItem("subjectId", this.subjectId);

    this.$emit("load-finished");
  },
  methods: {
    selectedAnswerStyleModifier(selectedAnswerId, correctAnswerId, answerId) {
      if (answerId === correctAnswerId) {
        return "success";
      }
      if (answerId === selectedAnswerId && selectedAnswerId !== correctAnswerId) {
        return "error";
      }
      return "";
    },
    initializeCollapsibleItems() {
      for (let index = 0; index < this.exercisesResults.length; index += 1) {
        this.collapsibleItems.push(false);
        this.collapsibleContents.push(null);
        this.$set(this.headerTextVisibility, index, true);
      }
      this.isMobileResolution = window.innerWidth < LAPTOP_WIDTH;
    },
    async getItemContent(answerSheetItemId) {
      return ShowAnswerSheetResultsBySubject.getAnswerSheetItemResult({
        answerSheetId: this.answerSheetId,
        answerSheetItemId,
      });
    },
    async updateItems(itemNumber) {
      this.$set(this.headerTextVisibility, itemNumber, !this.headerTextVisibility[itemNumber]);

      let indexContentToLoad = null;
      for (let index = 0; index < this.exercisesResults.length; index += 1) {
        if (index === itemNumber) {
          this.$set(this.collapsibleItems, index, !this.collapsibleItems[index]);
          if (this.collapsibleItems[index]) {
            if (this.collapsibleContents[index] === null) {
              indexContentToLoad = index;
              this.$set(this.collapsibleContents, index, "Cargando");
            }
          }
        } else {
          this.$set(this.collapsibleItems, index, false);
        }
      }
      if (indexContentToLoad !== null) {
        const content = await this.getItemContent(this.exercisesResults[indexContentToLoad].id);
        this.$set(this.collapsibleContents, indexContentToLoad, content);
      }
      this.refreshMathJax();
    },
    async getSubjectNameAndImage() {
      this.subjectName = await localForage.getItem("subjectName");
      this.subjectImage = await localForage.getItem("subjectImage");
    },
    emitLoadItem(itemId) {
      this.$emit("load-item", itemId);
    },
    async redirectToHomePage() {
      await this.$router.push({
        name: "Home",
      });
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          window.MathJax.typesetPromise();
        });
      }
    },
    getSavePositionToCutMathjax(text, position) {
      const MATHJAX_OPEN_EXPRESSION = "\\(";
      const MATHJAX_CLOSE_EXPRESSION = "\\)";
      const cutString = text.substring(0, position);
      let openExpressionOccur = 0;
      if (cutString.length > 4) {
        for (let index = 0; index < cutString.length - 1; index += 1) {
          const fragment = cutString[index] + cutString[index + 1];
          if (fragment === MATHJAX_OPEN_EXPRESSION) {
            openExpressionOccur += 1;
          } else if (fragment === MATHJAX_CLOSE_EXPRESSION) {
            openExpressionOccur -= 1;
          }
        }
      }

      if (openExpressionOccur > 0) {
        let indexString = position;
        for (let index = 0; index < openExpressionOccur; index += 1) {
          indexString = text.indexOf(MATHJAX_CLOSE_EXPRESSION, indexString);
        }
        return indexString + 2;
      }
      return position;
    },
    removeHtmlBreakTag(text) {
      const htmlTagIndex = text.indexOf("<br");
      let resultText = text;
      if (htmlTagIndex >= 0) {
        resultText = text.substring(0, htmlTagIndex).concat(" ...");
      }
      return resultText;
    },

    formatTitleItem(title, itemIndex) {
      const MAX_LENGTH = this.isMobileResolution ? MAX_TITLE_LENGTH_MOBILE : MAX_TITLE_LENGTH;
      let formattedText = "";
      if (this.collapsibleItems[itemIndex]) {
        return title;
      }
      if (title.length < MAX_LENGTH) {
        return this.removeHtmlBreakTag(title);
      }
      const isMathjaxExpression = title.indexOf("\\(") > -1;
      if (isMathjaxExpression) {
        const savePositionToCut = this.getSavePositionToCutMathjax(title, MAX_LENGTH);
        formattedText = title.substring(0, savePositionToCut).concat(" ...");
      } else {
        formattedText = title.substring(0, MAX_LENGTH).concat(" ...");
      }
      return this.removeHtmlBreakTag(formattedText);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

::v-deep .v-expansion-panel-header > :not(.v-expansion-panel-header__icon) {
  flex: initial;
}

::v-deep .theme--light.v-expansion-panels .v-expansion-panel {
  background-color: $white;
}

img {
  width: 100%;
}

.explanation__button {
  margin: auto;
  width: 95%;

  @include laptop {
    width: 50%;
  }
}

/** @define main-content; */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

/** @define answer-sheet-results-by-subject; */
.answer-sheet-results-by-subject {
  width: 100%;
  padding-bottom: 30%;

  @include laptop {
    padding-bottom: 0;
  }
}

.answer-sheet-results-by-subject__heading {
  @extend %font-body-1;

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 1em;

  @include laptop {
    padding-top: 0;
  }
}

.answer-sheet-results-by-subject__accept-btn {
  width: 100%;
  margin-top: 2.5em;

  @include laptop {
    width: 60%;
  }
}

/** @define heading; */
.heading__subject-circle-icon {
  margin: 1.2em;
  height: auto;
  color: $black;

  @include laptop {
    margin: 1.75em;
  }
}

.heading__title {
  @extend %font-title-2;

  text-align: center;
  color: $ipn-color;
}

/** @define subject-circle-icon; */
.subject-circle-icon__icon {
  cursor: default;
  width: 100%;
}

/** @define answer-sheet-results-by-subject; */
.answer-sheet-results-by-subject__subjects-results {
  @extend %font-body-1;

  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

/** @define subjects-results; */
.subjects-results__item {
  padding-top: 1em;
  width: 100%;
}

/** @define item; */
.item__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.6em;
}

/** @define content; */
.content__button {
  width: 100%;
  margin: auto;

  @include laptop {
    width: 100%;
  }
}

.content__button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

/** @define button; */
.button__image {
  width: 2em;
}

/** @define exercise; */
.exercise__text {
  padding-bottom: 2em;
}

.exercise__icon {
  width: 1.25em;

  @include laptop {
    width: 1.75em;
  }
}

.exercise__img {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 2em;

  @include laptop {
    width: 50%;
  }
}

.exercise__container {
  display: flex;
  align-items: center;
  gap: 0.85em;
}

/** @define custom-panel; */
.custom-panel {
  @extend %font-body-2;
}

.custom-panel_header_container {
  display: flex;
  align-items: center;
  gap: 0.5em;

  @include laptop {
    gap: 2em;
  }
}
</style>
