import APIService from "@/services/API";

const SearchMajorServiceRequest = (searchText) => ({ search_text: searchText });

const SearchMajorServiceResponse = (majors) => ({
  /** @type {{ id: number, name: string, campusName: string, institutionNameWithLevel: string }[]} * */
  majors,
});

class SearchMajorService extends APIService {
  constructor(searchMajorRequest) {
    super();
    this.serviceURL = "/user/search-major/";
    this.response = null;
    this.request = searchMajorRequest;
  }

  // eslint-disable-next-line class-methods-use-this
  mapAPIResponseToServiceResponse(major) {
    return {
      id: major.id,
      name: major.name,
      campusName: major.campus_name,
      institutionNameWithLevel: major.institution_name_with_level,
    };
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    const response = [];
    for (let index = 0; index < this.response.data.length; index += 1) {
      response.push(this.mapAPIResponseToServiceResponse(this.response.data[index]));
    }
    return SearchMajorServiceResponse(response);
  }
}

export { SearchMajorService, SearchMajorServiceRequest };
