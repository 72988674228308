import {
  StartGeneralExamService,
  StartGeneralExamServiceRequest,
} from "@/use_cases/show_subjects/StartGeneralExamService";
import { GetSubjectsService, GetSubjectsServiceRequest } from "@/use_cases/show_subjects/GetSubjectsService";
import {
  StartSubjectExamService,
  StartSubjectExamServiceRequest,
} from "@/use_cases/show_subjects/StartSubjectExamService";
import {
  IsDailyExamAvailableService,
  IsDailyExamAvailableServiceRequest,
} from "@/use_cases/show_subjects/daily_exam/IsDailyExamAvailableService";

const GetSubjectResponse = function constructor({ id, name, imageUrl }) {
  this.id = id;
  this.name = name;
  this.imageUrl = imageUrl;
};

const getSubjects = async ({ courseProgressId }) => {
  const request = new GetSubjectsServiceRequest({
    courseProgressId,
  });
  const service = new GetSubjectsService(request);
  const response = await service.do();
  return response.map((responseData) => new GetSubjectResponse(responseData));
};

const StartGeneralExamResponse = function constructor({
  answerSheetId,
  currentAnswerSheetItemId,
  isAnswerSheetStarted,
}) {
  this.answerSheetId = answerSheetId;
  this.currentAnswerSheetItemId = currentAnswerSheetItemId;
  this.isAnswerSheetStarted = isAnswerSheetStarted;
};

const startGeneralExam = async ({ courseProgressId, numberOfExercises }) => {
  const request = StartGeneralExamServiceRequest({
    courseProgressId,
    numberOfExercises,
  });
  const service = new StartGeneralExamService(request);
  return new StartGeneralExamResponse(await service.do());
};

const StartSubjectExamResponse = function constructor({
  answerSheetId,
  currentAnswerSheetItemId,
  isAnswerSheetStarted,
}) {
  this.answerSheetId = answerSheetId;
  this.currentAnswerSheetItemId = currentAnswerSheetItemId;
  this.isAnswerSheetStarted = isAnswerSheetStarted;
};

const startSubjectExam = async ({ courseProgressId, numberOfExercises, subjectId }) => {
  const request = StartSubjectExamServiceRequest({
    courseProgressId,
    numberOfExercises,
    subjectId,
  });
  const service = new StartSubjectExamService(request);
  return new StartSubjectExamResponse(await service.do());
};

const IsDailyExamAvailableResponse = function constructor({ isDailyExamAvailable }) {
  this.isDailyExamAvailable = isDailyExamAvailable;
};

const isDailyExamAvailable = async ({ courseProgressId }) => {
  const serviceRequest = new IsDailyExamAvailableServiceRequest({
    courseProgressId,
  });
  const service = new IsDailyExamAvailableService(serviceRequest);
  try {
    return new IsDailyExamAvailableResponse(await service.do());
  } catch (e) {
    return new IsDailyExamAvailableResponse({ isDailyExamAvailable: false });
  }
};

const ShowSubjects = { getSubjects, startGeneralExam, startSubjectExam, isDailyExamAvailable };

export default ShowSubjects;
