import localForage from "localforage";
import {
  SignUpUsingEmailService,
  SignUpUsingEmailServiceRequest,
} from "@/use_cases/login_sign_up/services/SignUpUsingEmailService";

const signUp = async (password) => {
  let response = null;
  try {
    const email = await localForage.getItem("email");
    const signUpUsingEmailServiceRequest = SignUpUsingEmailServiceRequest(email, password);
    const signUpUsingEmailService = new SignUpUsingEmailService(signUpUsingEmailServiceRequest);

    response = await signUpUsingEmailService.do();

    await Promise.all([
      localForage.setItem("JWT", response.jwt),
      localForage.setItem("isStudentRegistrationPending", true),
    ]);
  } catch (err) {
    console.log(err.response.status);
  }
  return response;
};

const SignUp = { signUp };

export default SignUp;
