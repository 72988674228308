import { SearchMajorService, SearchMajorServiceRequest } from "@/use_cases/select_major/SearchMajorService";

/** @return {{subgroup: *, name: *, id: *, group: *}}* */
function mapMajorsToInputDropDownItem(major) {
  return {
    id: major.id,
    name: major.name,
    subgroup: major.campusName,
    group: major.institutionNameWithLevel,
  };
}
async function getMajors(searchText) {
  const searchMajorServiceRequest = SearchMajorServiceRequest(searchText);
  const searchMajorService = new SearchMajorService(searchMajorServiceRequest);
  const searchMajorServiceResponse = await searchMajorService.do();
  const responseMajors = searchMajorServiceResponse.majors;
  const inputDropDownItems = [];
  for (let index = 0; index < responseMajors.length; index += 1) {
    inputDropDownItems.push(mapMajorsToInputDropDownItem(responseMajors[index]));
  }

  return inputDropDownItems;
}

const SearchMajor = { getMajors };

export default SearchMajor;
