<template>
  <BaseFloatingButton @click="emitClickEvent">
    <HintImage />
  </BaseFloatingButton>
</template>

<script>
import BaseFloatingButton from "@/components/base_floating_button/BaseFloatingButton.vue";
// eslint-disable-next-line import/extensions
import HintsImage from "@/assets/hints.svg?inline";

export default {
  name: "HintsFloatingButton",
  components: {
    BaseFloatingButton,
    HintImage: HintsImage,
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>
