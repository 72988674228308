<template>
  <div data-test-id="base__card" class="card p-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseCard",
};
</script>

<style scoped lang="scss">
@import "~@/scss/_spacing.scss";

.card {
  box-sizing: border-box;
  background: white;
  border: solid;
  border-width: $space-unit / 4;
  border-radius: $space-unit * 6;
  border-color: #ccc;
  height: 100%;
  width: 100%;
}
</style>
