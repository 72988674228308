import APIService from "@/services/API";

const GetSubjectsServiceRequest = function constructor({ courseProgressId }) {
  this.course_progress_id = courseProgressId;
};

/* eslint-disable camelcase */
const GetSubjectsServiceResponse = function constructor({ id, name, image_url }) {
  this.id = id;
  this.name = name;
  this.imageUrl = image_url;
};
/* eslint-enable camelcase */

class GetSubjectsService extends APIService {
  constructor(getSubjectsServiceRequest) {
    super();
    this.serviceURL = "student/get-enrolled-course-subjects/";
    this.request = getSubjectsServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return this.response.data.map((responseData) => new GetSubjectsServiceResponse(responseData));
  }
}

export { GetSubjectsService, GetSubjectsServiceRequest };
