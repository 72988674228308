import APIService from "@/services/API";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import HttpStatusCodes from "@/services/HttpStatusCodes";

const StartSimulationExamServiceRequest = ({ simulationExamId }) => ({
  id_simulation_exam: simulationExamId,
});

/* eslint-disable camelcase */
const StartSimulationExamServiceResponse = ({
  id_answer_sheet,
  id_current_answer_sheet_item,
  is_answer_sheet_started,
  id_course,
}) => ({
  answerSheetId: id_answer_sheet,
  currentAnswerSheetItemId: id_current_answer_sheet_item,
  isAnswerSheetStarted: is_answer_sheet_started,
  courseId: id_course,
});
/* eslint-enable camelcase */

class StartSimulationExamService extends APIService {
  constructor(startSimulationExamServiceRequest) {
    super();
    this.serviceURL = "student/start-simulation-exam/";
    this.request = startSimulationExamServiceRequest;
    this.response = null;
  }

  async do() {
    try {
      await this.setAuthorizationHeaders();
      this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    } catch (e) {
      switch (e.response.status) {
        case HttpStatusCodes.PAYMENT_REQUIRED:
          throw new InsufficientCoins(e.response.data.message);
        default:
          console.log(e);
      }
    }

    return StartSimulationExamServiceResponse(this.response.data);
  }
}

export { StartSimulationExamService, StartSimulationExamServiceRequest };
