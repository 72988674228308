import APIService from "@/services/API";

const GetDailyExamServiceRequest = ({ idCourseProgress, date }) => ({
  course_progress_id: idCourseProgress,
  date,
});

// eslint-disable-next-line camelcase
const GetDailyExamServiceResponse = ({ answer_sheet_id, current_answer_sheet_item_id }) => ({
  // eslint-disable-next-line camelcase
  answerSheetId: answer_sheet_id,
  // eslint-disable-next-line camelcase
  currentAnswerSheetItemId: current_answer_sheet_item_id,
});

class GetDailyExamService extends APIService {
  constructor(getDailyExamServiceRequest) {
    super();
    this.serviceURL = "student/start-daily-exam/";
    this.request = getDailyExamServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return GetDailyExamServiceResponse(this.response.data);
  }
}

export { GetDailyExamService, GetDailyExamServiceRequest };
