<template>
  <div class="base-btn">
    <button
      :class="{
        'base-btn__btn': true,
        ['base-btn__btn--' + styleModifier]: true,
        'btn-loader_active': isLoading,
      }"
      :disabled="isDisabled || isLoading"
      @click="emitClickEvent"
    >
      <slot v-if="!isLoading"></slot>
      <v-progress-circular v-if="isLoading" color="white" indeterminate :size="20" :width="3"></v-progress-circular>
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) =>
        [
          "primary",
          "secondary",
          "tertiary",
          "text",
          "facebook",
          "google",
          "close-modal",
          "previous-item",
          "navigation-hint",
          "close-hint",
          "activate-course",
        ].includes(prop),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";
@import "~@/scss/_fonts.scss";

$font-button-family: "Avenir-Black";
$font-button-size: 1rem;
$font-button-size--laptop: 1.2rem;
$font-button-weight: $font-weight-heavy;
%font-button {
  font-family: $font-button-family;
  font-size: $font-button-size;
  font-weight: $font-button-weight;

  @include laptop {
    font-size: $font-button-size--laptop;
  }
}

$font-tertiary-button-family: "Avenir-Medium";
$font-tertiary-button-size: 1rem;
$font-tertiary-button-size--laptop: 1.2rem;
$font-tertiary-button-weight: $font-weight-medium;
%font-tertiary-button {
  font-family: $font-tertiary-button-family;
  font-size: $font-tertiary-button-size;
  font-weight: $font-tertiary-button-weight;

  @include laptop {
    font-size: $font-tertiary-button-size--laptop;
  }
}

$font-text-button-family: "Avenir-Medium";
$font-text-button-size: 0.7rem;
$font-text-button-size--laptop: 0.85rem;
$font-text-button-weight: $font-weight-medium;
%font-text-button {
  font-family: $font-text-button-family;
  font-size: $font-text-button-size;
  font-weight: $font-text-button-weight;

  @include laptop {
    font-size: $font-text-button-size--laptop;
  }
}

/** @define base-btn; */
.base-btn__btn {
  @extend %font-button;

  border: 0;
  color: inherit;
  background: none;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  outline: none;
  position: relative;
  padding: 0.6em;
  letter-spacing: 0.04em;
  border-radius: 6px;
  width: 100%;
  transition: all 0.3s;
}

.base-btn__btn:enabled:active {
  top: 0.08em;
}

.base-btn__btn--primary {
  background: $primary-color;
  color: $white;

  &:hover {
    background: $primary-color--hover;
  }

  &:active {
    background: $primary-color--active;
  }

  &:disabled {
    cursor: default;
    background-color: $disabled-color;

    &.btn-loader_active {
      background: $primary-color;
    }
  }
}

.base-btn__btn--secondary {
  background: $secondary-color;
  color: $white;

  &:hover {
    background: $secondary-color--hover;
  }

  &:active {
    background: $secondary-color--active;
  }

  &:disabled {
    cursor: default;
    background-color: $disabled-color;

    &.btn-loader_active {
      background: $secondary-color;
    }
  }
}

.base-btn__btn--facebook {
  background: $facebook-color;
  color: $white;

  &:hover {
    background: $facebook-color--hover;
  }

  &:active {
    background: $facebook-color--active;
  }
}

.base-btn__btn--google {
  background: $google-color;
  color: $white;

  &:hover {
    background: $google-color--hover;
  }

  &:active {
    background: $google-color--active;
  }
}

.base-btn__btn--tertiary {
  @extend %font-tertiary-button;

  background: $tertiary-color;
  color: $dark-orange;

  &:hover {
    background: $tertiary-color--hover;
  }

  &:active {
    background: $tertiary-color--active;
  }

  &:disabled {
    cursor: default;
    background-color: $disabled-color;

    &.btn-loader_active {
      background: $tertiary-color;
    }
  }
}

.base-btn__btn--text {
  @extend %font-text-button;

  width: auto;

  &:disabled {
    cursor: default;
    color: $disabled-color;
  }
}

.base-btn__btn--close-modal {
  @extend %font-text-button;

  width: 4rem;

  &:disabled {
    cursor: default;
    background-color: $disabled-color;
  }
}

.base-btn__btn--previous-item {
  &:disabled {
    cursor: default;
    background-color: $disabled-color;
  }

  @include laptop {
    width: 15rem;
    padding-left: 1.5em;
  }
}

.base-btn__btn--navigation-hint {
  width: 1.4em;
  padding: 0;
}

.base-btn__btn--close-hint {
  &:disabled {
    cursor: default;
    background-color: $disabled-color;
  }
}

.base-btn__btn--activate-course {
  @extend %font-text-button;

  width: auto;
  color: $ipn-color;

  &:disabled {
    cursor: default;
    background-color: $disabled-color;
  }
}
</style>
