<template>
  <BaseCard class="product">
    <div class="product__title">
      <div>
        <span :class="'product__name product__name--' + styleModifier" data-test-id="product-name">
          {{ product.name }}
        </span>
        <span class="product__discount" data-test-id="product__discount">
          - 20% de descuento por tiempo limitado:
        </span>
      </div>
      <div class="product__countdown">
        <CountdownTimer class="product__count-left" :time-in-minutes="minutesLeft" @time-out="$emit('time-out')" />
      </div>
    </div>

    <div class="product__body">
      <div class="product__section product__section--image p-1" data-test-id="product-image">
        <img class="product__image" :src="product.image" :alt="product.name" />
      </div>
      <div class="product__section product__section--info p-2">
        <div>
          <p class="product__description" data-test-id="product-description">{{ product.description }}</p>
        </div>

        <div class="product__price" data-test-id="product-price">
          <div class="product__price--before" data-test-id="product-price"><del>$399.00</del></div>
          <span>${{ product.price }}</span>
        </div>
      </div>
    </div>

    <div class="product__footer">
      <div class="product__buy-btn">
        <BaseButton
          style-modifier="text"
          :data-test-id="`buy-btn-${product.productId}`"
          @click="$emit('open-payment-method-modal', product)"
        >
          Comprar >
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import CountdownTimer from "../../components/countdown_timer/CountdownTimer.vue";

export default {
  name: "SubscriptionCardDiscount",
  components: {
    BaseCard,
    BaseButton,
    CountdownTimer,
  },
  /** @type {{ productId: int , name: string , description: string, price: int , image: string, type: string }} * */
  props: {
    product: {
      type: Object,
      required: true,
    },
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) => ["primary", "secondary"].includes(prop),
    },
    minutesLeft: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define product */
.product {
  background: $primary-color;
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  justify-content: space-evenly;
}

.product__title {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
}

.product__name {
  @extend %font-section-title-1;

  text-align: justify;

  &--primary {
    color: $secondary-color;
  }

  &--secondary {
    color: $success-color;
  }
}

.product__body {
  display: flex;
  flex-direction: row;
}

.product__section {
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
}

.product__section--info {
  flex-basis: fit-content;
  display: flex;
  flex-direction: column;
}

.product__image {
  max-width: 100%;
}

.product__description {
  @extend %font-body-2;

  color: $white;
}

.product__discount {
  @extend %font-body-2;

  color: $secondary-color;
}

.product__countdown {
  display: flex;
  justify-content: space-around;
  width: 30%;
  color: $white;
}

.product__count-left {
  width: 20%;
}

.product__price {
  @extend %font-section-title-1;

  color: $white;
  text-align: center;
}

.product__price--before {
  @extend %font-body-3;

  color: $secondary-color;
}

.product__buy-btn {
  @extend %font-section-title-1;

  color: $secondary-color;
  text-align: right;
}
</style>
