<template>
  <SubscriptionCard
    v-if="
      product.productId === THREE_MONTHS_BUNDLE_ID ||
      (product.productId === SIX_MONTHS_BUNDLE_ID && promotionMinutesLeft <= 0)
    "
    :data-test-id="`product-card-${product.productId}`"
    :product="product"
    :style-modifier="styleModifier"
    class="coins-product__card"
    @open-payment-method-modal="$emit('open-payment-method-modal', product)"
  />

  <SubscriptionCardDiscount
    v-else-if="product.productId === SIX_MONTHS_DISCOUNT_BUNDLE_ID && promotionMinutesLeft > 0"
    :data-test-id="`product-card-${product.productId}`"
    :product="product"
    :minutes-left="promotionMinutesLeft"
    style-modifier="secondary"
    class="coins-product__card"
    @open-payment-method-modal="$emit('open-payment-method-modal', product)"
    @time-out="$emit('time-out')"
  />

  <CoinsBundleCard
    v-else-if="product.productId === COINS_BUNDLE_ID"
    :data-test-id="`product-card-${product.productId}`"
    :product="product"
    class="coins-product__card"
    @open-payment-method-modal="$emit('open-payment-method-modal', product)"
  />
</template>
<script>
import {
  COINS_BUNDLE_ID,
  SIX_MONTHS_BUNDLE_ID,
  SIX_MONTHS_DISCOUNT_BUNDLE_ID,
  THREE_MONTHS_BUNDLE_ID,
} from "@/constants";
import CoinsBundleCard from "../../use_cases/buy_product/CoinsBundleCard.vue";
import SubscriptionCard from "../../use_cases/buy_product/SubscriptionCard.vue";
import SubscriptionCardDiscount from "../../use_cases/buy_product/SubscriptionCardDiscount.vue";

export default {
  /**
   * @typedef Product
   * @type {object}
   * @property {number} productId
   * @property {string} name
   * @property {string} description
   * @property {number} price
   * @property {string} image
   * @property {string} type
   */

  name: "CoinsProduct",
  components: { CoinsBundleCard, SubscriptionCard, SubscriptionCardDiscount },
  props: {
    /** @type {Product} * */
    product: {
      type: Object,
      required: true,
    },
    promotionMinutesLeft: {
      type: Number,
      required: true,
    },
  },

  computed: {
    styleModifier() {
      if (this.product.productId === this.THREE_MONTHS_BUNDLE_ID) return "primary";
      return "secondary";
    },
  },

  async created() {
    this.COINS_BUNDLE_ID = COINS_BUNDLE_ID;
    this.THREE_MONTHS_BUNDLE_ID = THREE_MONTHS_BUNDLE_ID;
    this.SIX_MONTHS_BUNDLE_ID = SIX_MONTHS_BUNDLE_ID;
    this.SIX_MONTHS_DISCOUNT_BUNDLE_ID = SIX_MONTHS_DISCOUNT_BUNDLE_ID;
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/resolutions";
@import "~@/scss/colors";
@import "~@/scss/typography";

/** @define coins-product */
.coins-product__card {
  width: 100%;
}
</style>
