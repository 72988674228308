<template>
  <router-view @load-finished="emitLoadFinished" />
</template>

<script>
export default {
  name: "RegisteredUserPage",
  methods: {
    emitLoadFinished() {
      this.$emit("load-finished");
    },
  },
};
</script>
