import APIService from "@/services/API";

const GetExerciseIssuesServiceRequest = () => function constructor() {};

const GetExerciseIssuesResponse = function constructor({ id, description }) {
  this.id = id;
  this.description = description;
};

class GetExerciseIssuesService extends APIService {
  constructor(exerciseIssuesRequest) {
    super();
    this.serviceURL = "/student/get-exercise-issues/";
    this.response = null;
    this.request = exerciseIssuesRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, this.request);
    return this.response.data.exercise_issues.map((responseData) => new GetExerciseIssuesResponse(responseData));
  }
}

export { GetExerciseIssuesService, GetExerciseIssuesServiceRequest };
