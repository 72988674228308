<template>
  <BaseModal
    :is-visible="isVisible"
    :is-close-button-visible="isCloseButtonVisible"
    :style-modifier="styleModifier"
    @hide-modal="emitHideModalEvent"
  >
    <slot slot="image" name="image"></slot>

    <slot slot="message" name="message"></slot>

    <slot slot="callToAction" name="buttonComponent">
      <BaseButton
        data-test-id="call-to-action-default-btn"
        class="one-btn-modal__btn"
        style-modifier="secondary"
        @click="emitButtonClickEvent"
      >
        <slot name="buttonText"></slot>
      </BaseButton>
    </slot>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "OneButtonModal",
  components: { BaseModal, BaseButton },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) => ["transparent", "secondary"].includes(prop),
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitButtonClickEvent(event) {
      this.$emit("button-click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define one-btn-modal */
.one-btn-modal__btn {
  width: 80%;

  @include laptop {
    width: 70%;
  }
}
</style>
