<template>
  <div>
    <div class="profile__courses-container">
      <div class="profile__courses-plus-button">
        <v-btn icon data-test-id="add-course-button" @click="addCourse()">
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="profile__courses">
        <div class="profile__courses-active">
          <SchoolImage class="profile__courses-icon"></SchoolImage>
          <div data-test-id="active-course">{{ activeCourse.name + " " + activeCourse.major_name }}</div>
        </div>
        <div
          v-for="course in courses"
          :key="course.major_id"
          :data-test-id="`course-inactive-${course.id_course_progress}`"
          class="profile__courses-no-active"
        >
          <div class="profile__courses-active-container">
            <div class="profile__courses-no-active-text">{{ course.name + " " + course.major_name }}</div>
            <BaseButton
              style-modifier="text"
              class="profile__courses-active-button"
              :data-test-id="`activate-course-button-${course.id_course_progress}`"
              @click="onClickActiveCourse(course.id_course_progress, course.major_id)"
              >Activar</BaseButton
            >
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SchoolImage from "@/assets/school.svg?inline";
import BaseButton from "@/components/base_button/BaseButton.vue";
import ShowProducts from "@/use_cases/profile/courses/ShowCourses";
import localForage from "localforage";

export default {
  name: "CoursesCard",
  components: { BaseButton, SchoolImage },
  data() {
    return {
      courses: [],
      activeCourse: {
        name: "",
        major_name: "",
      },
    };
  },
  async mounted() {
    this.courses = await ShowProducts.getCourses();
    await this.getCourses();
  },
  methods: {
    async getCourses() {
      const activeMajorId = await localForage.getItem("majorId");
      this.activeCourse = this.courses.find((course) => course.major_id === activeMajorId);
      this.courses = this.courses.filter((course) => course.major_id !== activeMajorId);
    },
    async onClickActiveCourse(CourseProgressId, MajorId) {
      await localForage.setItem("activeCourseProgressId", CourseProgressId);
      await localForage.setItem("majorId", MajorId);
      await this.$router.push({
        name: "ShowSubjectsPage",
        params: { courseProgressId: await localForage.getItem("activeCourseProgressId") },
      });
    },
    async addCourse() {
      await this.$router.push({
        name: "AddCoursePage",
        params: { courseProgressId: await localForage.getItem("activeCourseProgressId") },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.profile__courses-container {
  @extend %font-body-3;

  background-color: $tertiary-overlay-color;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.profile__courses-plus-button {
  display: flex;
  justify-content: flex-end;
}

.profile__courses {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0 1em;
  max-height: 10em;
  overflow-y: auto;
}

.profile__courses-active {
  display: flex;
  background-color: $tertiary-color;
  border-radius: 0.4em;
  padding: 0.5em;
  gap: 1em;
}

.profile__courses-active-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.profile__courses-no-active-text {
  padding: 0.5em 0 0.5em 1em;
}

.profile__courses-icon {
  width: 3.5em;
}

.profile__courses-active-button {
  @extend %font-body-3;

  color: $primary-color !important;
}
</style>
