import APIService from "@/services/API";
import HttpStatusCodes from "@/services/HttpStatusCodes";
import AccountRegisteredWithDifferentAuthProvider from "@/exceptions/AccountRegisteredWithDifferentAuthProvider";
import InvalidPassword from "@/exceptions/InvalidPassword";

const LoginServiceRequest = ({ email, password }) => ({ email, password });
const InvalidPasswordError = "Invalid Password";

/* eslint-disable camelcase */
const LoginServiceResponse = ({ jwt, is_student_registration_pending }) => ({
  jwt,
  isStudentRegistrationPending: is_student_registration_pending,
});
/* eslint-enable camelcase */

class LoginService extends APIService {
  constructor(loginServiceRequest) {
    super();
    this.serviceURL = "login/";
    this.response = null;
    this.request = loginServiceRequest;
  }

  async do() {
    try {
      this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    } catch (e) {
      switch (e.response.status) {
        case HttpStatusCodes.CONFLICT:
          throw new AccountRegisteredWithDifferentAuthProvider(e.response.data.message, e.response.data.auth_provider);
        default:
          console.log(e);
      }
    }
    if (this.response.status === HttpStatusCodes.OK && this.response.data.error === InvalidPasswordError) {
      throw new InvalidPassword(this.response.data.error);
    }

    return LoginServiceResponse(this.response.data);
  }
}

export { LoginService, LoginServiceRequest };
