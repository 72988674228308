import APIService from "@/services/API";

const GetStoreProductsRequest = () => function constructor() {};

const GetStoreProductsResponse = (productsArray) =>
  productsArray.map((product) => {
    return {
      productId: product.product_id,
      name: product.name,
      description: product.description,
      price: product.price,
      image: product.image,
      type: product.type,
    };
  });
class GetStoreProductsService extends APIService {
  constructor(storeProductsRequest) {
    super();
    this.serviceURL = "/student/show-products/";
    this.response = null;
    this.request = storeProductsRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, this.request);
    return GetStoreProductsResponse(this.response.data);
  }
}

export { GetStoreProductsService, GetStoreProductsRequest };
