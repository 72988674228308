<template>
  <div class="sign-up-form">
    <div class="sign-up-form__field">
      <BaseInputPassword
        v-model="password"
        :is-text-hidden="!isPasswordVisible"
        :is-invalid-password-message-visible="isInvalidPasswordMessageVisible"
        error-message="La contraseña debe ser entre 8 y 16 caracteres"
        @keyup.enter.native="signUp"
        @update:isPasswordVisible="isPasswordVisible = $event"
      />
    </div>

    <div class="sign-up-form__field">
      <BaseButton
        class="sign-up-form__button"
        style-modifier="secondary"
        :is-disabled="isBtnDisabled"
        :is-loading="isLoaderActive"
        @click="validate_password()"
      >
        Continuar
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
import SignUp from "@/use_cases/login_sign_up/SignUp";
import BaseInputPassword from "@/components/base_input_password/BaseInputPassword.vue";
import facebookTracking from "@/facebookTracking";

export default {
  name: "SignUpPage",
  components: {
    BaseInputPassword,
    BaseButton,
  },
  data() {
    return {
      response: "",
      password: "",
      authProvider: "",
      is_signed_up: "",
      isPasswordVisible: false,
      isInvalidPasswordMessageVisible: false,
      signUpUseCaseResponse: "",
      isBtnDisabled: true,
      isLoaderActive: false,
    };
  },
  watch: {
    password(newVal) {
      this.isBtnDisabled = newVal === "";
    },
  },
  methods: {
    validate_password() {
      const passwordSize = this.password.length;
      if (passwordSize >= 8 && passwordSize <= 16) {
        this.signUp();
      } else {
        this.isLoaderActive = false;
        this.isInvalidPasswordMessageVisible = true;
      }
    },
    async signUp() {
      this.isLoaderActive = true;

      this.signUpUseCaseResponse = await SignUp.signUp(this.password);

      this.$gtag.event("sign_up_started", {
        auth_provider: "email",
      });
      facebookTracking.trackEvent({ eventName: "sign_up_started", params: { auth_provider: "email" } });

      await this.$router.push({ name: "DemoIntroductionPage" });
    },
  },
};
</script>
<style scoped lang="scss">
/** @define sign-up-form */
.sign-up-form {
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  width: 90%;
}

.sign-up-form__field {
  flex: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 0.5em;
  text-align: center;
}

.sign-up-form__button {
  margin-top: 1em;
}

/** @define field */
.field__input-check {
  padding-top: 1em;
  padding-bottom: 1em;
}
</style>
