<template>
  <OneButtonModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    style-modifier="secondary"
    :button-style-modifier="authProvider"
    @hide-modal="emitHideModalEvent"
  >
    <div slot="image" class="email-registered-with-social-account-modal__social-network-img">
      <FacebookImage v-if="authProvider === 'facebook'" data-test-id="facebook-img" class="social-network-img__img" />
      <GoogleImage v-if="authProvider === 'google'" data-test-id="google-img" class="social-network-img__img" />
    </div>

    <div slot="message" data-test-id="message" class="email-registered-with-social-account-modal__message">
      <p>¡Ups!</p>
      <p>Este correo esta asociado a una cuenta de {{ authProvider }}.</p>
    </div>

    <ContinueWithSocialAccountButton
      slot="buttonComponent"
      class="email-registered-with-social-account-modal__btn"
      :auth-provider="authProvider"
      data-test-id="modal-continue-with-social-account-btn"
    />
  </OneButtonModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import FacebookImage from "@/assets/facebook-logo.svg?inline";
// eslint-disable-next-line import/extensions
import GoogleImage from "@/assets/google-logo.svg?inline";
import OneButtonModal from "@/components/one_button_modal/OneButtonModal.vue";
// eslint-disable-next-line max-len
import ContinueWithSocialAccountButton from "@/components/continue_with_social_account_button/ContinueWithSocialAccountButton.vue";

export default {
  name: "EmailRegisteredWithSocialAccountModal",
  components: {
    ContinueWithSocialAccountButton,
    OneButtonModal,
    FacebookImage,
    GoogleImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    authProvider: {
      type: String,
      required: true,
      validator: (prop) => ["facebook", "google"].includes(prop),
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_resolutions.scss";

/** @define email-registered-with-social-account-modal */
.email-registered-with-social-account-modal__social-network-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.email-registered-with-social-account-modal__message {
  margin: 2em 0;
}

.email-registered-with-social-account-modal__btn {
  width: 80%;
}

/** @define social-network-img */
.social-network-img__img {
  width: 40%;

  @include laptop {
    width: 12.5%;
  }
}
</style>
