<template>
  <div class="timer">
    <TimerImage class="timer__img" />
    <div class="timer__label">
      <span>{{ hours | formatTime }}:{{ minutes | formatTime }}:{{ seconds | formatTime }}</span>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import TimerImage from "@/assets/timer.svg?inline";

const SPEED_IN_MILLIESECONDS = 1000;

export default {
  name: "CountdownTimer",
  components: {
    TimerImage,
  },
  filters: {
    formatTime(value) {
      if (value < 10) {
        return `0${value}`;
      }
      return value;
    },
  },
  props: {
    timeInMinutes: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentTime: this.timeInMinutes * 60 * 1000,
    };
  },
  computed: {
    seconds() {
      return Math.floor((this.currentTime / 1000) % 60);
    },
    minutes() {
      return Math.floor((this.currentTime / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor((this.currentTime / (1000 * 60 * 60)) % 24);
    },
  },
  watch: {
    timeInMinutes() {
      this.currentTime = this.timeInMinutes * 60 * 1000;
    },
  },
  mounted() {
    setTimeout(this.countdown, SPEED_IN_MILLIESECONDS);
  },
  methods: {
    countdown() {
      this.currentTime -= SPEED_IN_MILLIESECONDS;
      if (this.currentTime > 0) {
        setTimeout(this.countdown, SPEED_IN_MILLIESECONDS);
      } else {
        this.$emit("time-out");
        this.currentTime = null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/scss/_typography.scss";

/** @define timer; */
.timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timer__img {
  width: 50%;
}

.timer__label {
  @extend %font-body-2;

  padding-left: 0.5em;
  width: 50%;
}
</style>
