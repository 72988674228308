import localForage from "localforage";
import {
  GetStudentCoinsBalanceService,
  GetStudentCoinsBalanceServiceRequest,
} from "@/use_cases/show_student_coins/GetStudentCoinsBalanceService";
import {
  SetCoinsBundlePurchaseEventAsSentService,
  SetCoinsBundlePurchaseEventAsSentServiceRequest,
} from "@/use_cases/show_student_coins/SetCoinsBundlePurchaseEventAsSentService";

const LOCAL_FORAGE_KEY = "storedStudentCoinsBalance";

const GetStudentCoinsResponse = function constructor({ coinsBalance, isCoinsBundlePurchaseEventPending } = {}) {
  this.coinsBalance = coinsBalance;
  this.isCoinsBundlePurchaseEventPending = isCoinsBundlePurchaseEventPending;
};

const getStudentCoinsBalance = async () => {
  const coinsBalanceService = new GetStudentCoinsBalanceService(GetStudentCoinsBalanceServiceRequest({}));
  const serviceResponse = await coinsBalanceService.do();
  localForage.setItem(LOCAL_FORAGE_KEY, serviceResponse);
  return new GetStudentCoinsResponse(serviceResponse);
};

const getStudentStoredCoinsBalance = async () => {
  const defaultStoredBalanceObject = { coinsBalance: 0, isCoinsBundlePurchaseEventPending: false };
  try {
    const studentBalance = await localForage.getItem(LOCAL_FORAGE_KEY);
    if (studentBalance !== null) {
      return studentBalance;
    }
    return defaultStoredBalanceObject;
  } catch {
    return defaultStoredBalanceObject;
  }
};

const SetCoinsBundlePurchaseEventAsSentResponse = function constructor() {};

const setCoinsBundlePurchaseEventAsSent = async () => {
  const service = new SetCoinsBundlePurchaseEventAsSentService(SetCoinsBundlePurchaseEventAsSentServiceRequest());
  const serviceResponse = await service.do();
  return new SetCoinsBundlePurchaseEventAsSentResponse(serviceResponse);
};

const ShowStudentCoinsBalance = {
  GetStudentBalance: getStudentCoinsBalance,
  StudentStoredBalance: getStudentStoredCoinsBalance,
  SetCoinsBundlePurchaseEventAsSent: setCoinsBundlePurchaseEventAsSent,
};

export default ShowStudentCoinsBalance;
