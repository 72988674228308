<template>
  <div class="base-floating-btn">
    <button class="base-floating-btn__btn" @click="emitClickEvent">
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseFloatingButton",
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_fonts.scss";

/** @define base-floating-btn */
.base-floating-btn {
  font-size: map-get($font-base, size);
  position: fixed;
  width: 6.4em;
  height: 6.4em;
  bottom: 1.6em;
  right: 1.6em;
}

.base-floating-btn__btn {
  width: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
</style>
