import { GetHintService, GetHintServiceRequest } from "@/use_cases/show_exam/GetHintService";

/** @returns {GetHintServiceResponse} * */
const getHint = async ({ answerSheetItemId, hintId }) => {
  const serviceRequest = new GetHintServiceRequest({ answerSheetItemId, hintId });
  const service = new GetHintService(serviceRequest);
  // eslint-disable-next-line no-return-await
  return await service.do();
};

const GetHint = { getHint };

export default GetHint;
