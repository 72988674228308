<template>
  <div class="payment-container-bg">
    <div class="payment-container-bg-text">
      MEMBRESÍA <br />
      INGRESAPP PREMIUM
    </div>
    <BaseCard class="payment-container">
      <div class="bank-card-close_container">
        <div class="bank-card-container-close_icon" data-test-id="close-btn-mobile" @click="hidePaymentMethodModal">
          <CloseButtonImage class="bank-card-close_icon"></CloseButtonImage>
        </div>
      </div>
      <div class="payment-container-top">
        <div class="payment-container-top_left">
          <div class="payment-container-logo">
            <img class="payment-logo_img" :src="product.image" alt="Imagen del Producto" />
          </div>
        </div>
        <div class="payment-container-top_right">
          <div class="payment-text_price">$ {{ product.price }} MXN</div>
          <div class="payment-text_time">{{ extractFirstTwoStrings(product.name) }}</div>
          <div class="payment-container-text_mount">
            <CoinsImage class="payment-mount_image"></CoinsImage>
            <div class="payment-mount_text">{{ coinsProductValue }}</div>
          </div>
        </div>
      </div>
      <div class="payment-container-mid">
        <div class="payment-mid_text">
          <p v-html="insertLineBreaks(product.description, 7)"></p>
        </div>
        <div class="payment_line"></div>
      </div>
      <div class="payment-container-bottom">
        <div class="payment-method_title">Elige método de pago</div>
        <div class="payment-method_pay">
          <CardImage
            :class="['payment-method_card', shadowCardClass]"
            data-test-id="method-btn-card"
            @click="buyWith('card')"
          ></CardImage>
          <div data-test-id="method-btn-oxxo" @click="buyWith('oxxo')">
            <OxxoImage :class="['payment-method_oxxo', shadowOxxoClass]"></OxxoImage>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import CoinsImage from "@/assets/coins.svg?inline";
import OxxoImage from "@/assets/oxxo.svg?inline";
import CardImage from "@/assets/card.svg?inline";
import CloseButtonImage from "@/assets/close_btn.svg?inline";

export default {
  name: "PaymentMethodCard",
  components: { BaseCard, CoinsImage, OxxoImage, CardImage, CloseButtonImage },
  props: {
    /** @type { productId: int , name: string , description: string, price: int , image: string, type: string } * */
    product: {
      required: true,
      type: Object,
    },
    /** @type {{ referenceNumber: string, amountToPay: float, expirationDate: date, barcodeUrl: string }} * */
    oxxoPaymentInfo: {
      required: true,
      type: Object,
    },
    isShadowCardHideActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      coinsProductValue: "",
      isShadowCardActive: true,
      isShadowOxxoActive: false,
      isShadowCardHide: false,
    };
  },
  computed: {
    shadowCardClass() {
      if (this.testxd) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isShadowCardActive = true;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isShadowOxxoActive = false;
      }
      return {
        "payment-method-shadow": this.isShadowCardActive,
      };
    },
    shadowOxxoClass() {
      return {
        "payment-method-shadow": this.isShadowOxxoActive,
      };
    },
  },
  watch: {
    isShadowCardHideActive(newValue) {
      this.isShadowCardHide = newValue;
    },
  },
  methods: {
    buyWith(method) {
      this.isShadowCardHide = false;
      this.isShadowCardActive = method === "card";
      this.isShadowOxxoActive = method === "oxxo";
      this.$emit("buy-with", method);
    },
    insertLineBreaks(text, wordsPerLine) {
      if (!text) {
        return "";
      }
      const words = text.split(" ");
      let result = "";
      for (let index = 0; index < words.length; index += 1) {
        result += words[index];
        if ((index + 1) % wordsPerLine === 0) {
          result += "<br>";
        } else {
          result += " ";
        }
      }
      return result;
    },
    extractFirstTwoStrings(productName) {
      if (!productName) {
        return "";
      }
      const words = productName.split(" ");
      const twoWords = words.slice(0, 2).join(" ");
      if (!twoWords.includes("meses")) {
        this.coinsProductValue = words.slice(0, 1).join(" ");
        return "";
      }
      this.coinsProductValue = "10 mil";
      return twoWords;
    },
    hidePaymentMethodModal() {
      this.$emit("hide", false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_fonts.scss";

.payment-container-bg {
  @include laptop {
    padding: 5em 4.5em;
    background: $primary-color;
  }
}

.payment-container-bg-text {
  @extend %font-h2_title;

  padding-bottom: 0.5em;
  display: none;

  @include laptop {
    display: block;
  }
}

.bank-card-close_container {
  display: flex;
  justify-content: flex-end;
  padding: 1.2em 0;

  @include laptop {
    display: none;
  }
}

.bank-card-container-close_icon {
  cursor: pointer;
}

.bank-card-close_icon {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
  fill: $white;
}

.payment-container {
  @extend %font-body-3;

  display: flex;
  flex-direction: column;
  color: $white;
  background: $primary-color;
  border-color: $primary-color;
  padding: 0 1em;
  border-radius: 0;
  height: auto;

  @include laptop {
    background: $white;
    padding: 3em 2em 3.5em;
    color: $black;
  }
}

.payment-container-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;

  @include laptop {
    gap: 1.5em;
  }
}

.payment-container-top_left {
  padding-bottom: 2em;
}

.payment-container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-logo_img {
  width: 70%;

  @include laptop {
    width: 75%;
  }
}

.payment-container-top_right {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  padding-bottom: 2em;
}

.payment-text_price {
  font-family: Nunito, sans-serif;
  font-weight: bold;
  font-size: 23px;
}

.payment-text_time {
  @extend %font-text;

  background: $secondary-color;
  color: $white;
  border-radius: 1em;
}

.payment-container-text_mount {
  display: flex;
  gap: 0.7em;
}

.payment-mount_image {
  width: 1.5em;
}

.payment-mount_text {
  @extend %font-text;

  font-weight: bold;
}

.payment-container-mid {
  padding-top: 0.5em;
}

.payment-mid_text {
  text-align: center;
  color: $white;

  @include laptop() {
    color: $black;
  }
}

.payment_line {
  display: none;

  @include laptop {
    display: block;
    border-bottom: 1px solid $black;
    margin: 0 auto;
    width: 100%;
  }
}

.payment-container-bottom {
  @include laptop {
    padding-top: 1.5em;
  }
}

.payment-method_title {
  @extend %font-section-title-2;

  display: none;

  @include laptop {
    display: block;
  }
}

.payment-method_pay {
  display: none;

  @include laptop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin-top: 1em;
  }
}

.payment-method_card {
  cursor: pointer;
  width: 7em;
}

.payment-method_oxxo {
  cursor: pointer;
  width: 9em;
}

.payment-method-shadow {
  border-radius: 8%;
  box-shadow: #0a0a0a 0 3px 13px 0;
}
</style>
