<script>
export default {
  name: "Validatable",
  props: {
    validations: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      validationErrorMessages: [],
    };
  },
  methods: {
    validateContent() {
      this.validationErrorMessages = [];
      for (let index = 0; index < this.validations.length; index += 1) {
        if (!this.validations[index].rule(this.value)) {
          this.validationErrorMessages.push(this.validations[index].errorMessage);
        }
      }
      return this.validationErrorMessages.length === 0;
    },
  },
};
</script>
