<template>
  <div class="exercise-hints">
    <div :class="'exercise-hints__collapse-btn-container ' + animationClass">
      <BaseButton
        style-modifier="close-hint"
        :class="
          isHintsContainerVisible || forceVisibility ? 'exercise-hints__collapse-btn' : 'exercise-hints__expand-btn'
        "
        @click="toggleHintsContainerVisibility"
      >
        <CollapseArrowImage :class="isHintsContainerVisible ? 'collapse-btn-animation' : 'expand-btn-animation'" />
      </BaseButton>
    </div>

    <div :class="'exercise-hints__hints-container-collapsed ' + animationClass" />

    <VerticalSlide
      name="slide-top"
      @enter="transitionHintsContainerEnter"
      @after-enter="transitionHintsContainerAfterEnter"
      @leave="transitionHintsContainerLeave"
      @after-leave="transitionHintsContainerAfterLeave"
    >
      <div v-show="isHintsContainerVisible" class="exercise-hints__hints-container">
        <div
          v-show="isNavigationButtonsVisible"
          :class="hintIndex > 1 ? 'exercise-hints__navigation-buttons' : 'exercise-hints__navigation-buttons-reverse'"
        ></div>
        <div class="exercise-hints__arrows-container">
          <v-btn
            v-show="isHintPreviousButtonVisible"
            class="exercise-hints__arrows-left"
            icon
            data-test-id="hint-previous-button"
            @click="getPreviousHint"
          >
            <v-icon color="white" size="30">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            v-show="isHintNextButtonVisible"
            class="exercise-hints__arrows-right"
            icon
            data-test-id="hint-next-button"
            @click="getNextHint"
          >
            <v-icon color="white" size="30">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div
          v-for="(hint, index) in exerciseHints"
          v-show="index + 1 === hintIndex"
          :key="index"
          class="exercise-hints__text-container"
        >
          <FeedbackComponent
            :data-test-id-prefix="`hint-${index}`"
            buttons-variant="white"
            placeholder="Puedes agregar un comentario opcional sobre la pista..."
            @send-feedback="(feedback) => sendFeedback(index, hint, feedback)"
          />
          <span class="exercise-hints__numeration"> ({{ index + 1 }}/{{ totalHints }}) </span>
          {{ " " }} {{ hint.text }}
        </div>

        <div class="exercise-hints__add-hint-container">
          <BaseButton
            style-modifier="text"
            :class="{ 'exercise-hints__add-hint-btn': !hintsVariant }"
            :is-disabled="isAddHintButtonDisabled"
            data-test-id="buy-hint-button"
            @click="buyHint"
          >
            <span v-if="hintsVariant">+ Obtener pista</span>
            <span v-else>+ Obtener pista por 5</span>
            <CoinsImage v-if="!hintsVariant" :class="'add-hint-btn__img ' + coinsImageModifierClass" />
          </BaseButton>
        </div>
      </div>
    </VerticalSlide>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
import CollapseArrowImage from "@/assets/collapse-arrow.svg?inline";
import CoinsImage from "@/assets/coins.svg?inline";
import VerticalSlide from "@/transitions/VerticalSlide.vue";
import FeedbackComponent from "@/components/feedback_component/FeedbackComponent.vue";

export default {
  name: "ExerciseHintsBottomSheet",
  components: {
    FeedbackComponent,
    BaseButton,
    CollapseArrowImage,
    CoinsImage,
    VerticalSlide,
  },
  props: {
    exerciseHints: {
      type: Array,
      required: true,
    },
    lastHintIndexToLoad: {
      type: Number,
      default: 0,
    },
    totalHints: {
      type: Number,
      default: 0,
    },
    hintsVariant: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceVisibility: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isHintsContainerVisible: true,
      animationClass: "",
      hintIndex: 0,
      hints: this.exerciseHints,
    };
  },
  computed: {
    isAddHintButtonDisabled() {
      return this.hints.length >= this.totalHints;
    },
    isNavigationButtonsVisible() {
      return this.hints.length > 0;
    },
    isHintPreviousButtonVisible() {
      return this.hintIndex > 1;
    },
    isHintNextButtonVisible() {
      return this.hintIndex !== this.hints.length;
    },
    coinsImageModifierClass() {
      return this.isAddHintButtonDisabled ? "add-hint-btn__img--disabled" : "";
    },
  },
  watch: {
    lastHintIndexToLoad() {
      this.hintIndex = this.lastHintIndexToLoad;
      this.refreshMathJax();
    },
    exerciseHints() {
      this.hints = this.exerciseHints;
      this.refreshMathJax();
    },
    forceVisibility() {
      this.isHintsContainerVisible = this.forceVisibility;
    },
  },
  methods: {
    async created() {
      this.hintIndex = this.lastHintIndexToLoad;
      this.refreshMathJax();
    },
    async buyHint() {
      this.$emit("load-next-hint");
      if (this.hintIndex < this.hints.length) {
        this.hintIndex += 1;
      }
      this.$emit("button-click", this.hintIndex);
    },
    toggleHintsContainerVisibility() {
      this.isHintsContainerVisible = !this.isHintsContainerVisible;
    },
    transitionHintsContainerEnter(appliedAnimationClass) {
      this.animationClass = appliedAnimationClass;
    },
    transitionHintsContainerAfterEnter() {
      this.animationClass = "";
    },
    transitionHintsContainerLeave(appliedAnimationClass) {
      this.animationClass = appliedAnimationClass;
    },
    transitionHintsContainerAfterLeave() {
      this.animationClass = "";
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          window.MathJax.typesetPromise();
        });
      }
    },
    getPreviousHint() {
      if (this.hintIndex > 1) {
        this.hintIndex -= 1;
        this.$emit("button-click", this.hintIndex);
      }
    },
    getNextHint() {
      this.hintIndex += 1;
      this.$emit("button-click", this.hintIndex);
    },
    async sendFeedback(index, hint, feedback) {
      const hintFeedback = feedback;
      hintFeedback.hintId = hint.id;
      this.$emit("send-feedback", hintFeedback);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define exercise-hints */
.exercise-hints {
  display: flex;
  flex-direction: column;
  color: $white;
  width: 100%;
  vertical-align: bottom;
  overflow: scroll;
  position: absolute;
  bottom: 0;
  left: 0;
}

.exercise-hints__collapse-btn-container {
  background: $primary-color;
  align-self: flex-end;
  width: 2.5em;
  padding: 0 0.5em;
  margin-right: 1.2em;
}

.exercise-hints__collapse-btn,
.exercise-hints__expand-btn {
  ::v-deep > .base-btn__btn {
    padding: 5px 0 0;
  }
}

.exercise-hints__hints-container {
  background: $primary-color;
  padding: 0.5em 1.5em 1em;
}

.exercise-hints__hints-container-collapsed {
  background: $primary-color;
  height: 1em;
}

.exercise-hints__text-container {
  @extend %font-body-2;

  padding: 0.5em 0;
  text-align: justify;
}

.exercise-hints__arrows-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.exercise-hints__arrows-left {
  position: absolute;
  left: 0;
}

.exercise-hints__arrows-right {
  position: absolute;
  right: 0;
}

.exercise-hints__numeration {
  @extend %font-caption-3;

  color: $secondary-color;
}

.exercise-hints__add-hint-container {
  text-align: right;
}

.exercise-hints__add-hint-btn {
  display: flex;
  justify-content: flex-end;
  color: $secondary-color;
  margin-bottom: 1em;
}

.exercise-hints__navigation-buttons,
.exercise-hints__navigation-buttons-reverse {
  padding-bottom: 0.5em;
  display: flex;
  justify-content: space-between;

  &-reverse {
    flex-direction: row-reverse;
  }
}

/** @define add-hint-btn; weak */
.add-hint-btn__img {
  width: 1.5em;
}

.add-hint-btn__img--disabled > path {
  fill: $disabled-color;
}

/** @define arrow-hint-btn */
.arrow-hint-btn__img_right {
  padding-top: 1em;
  transform: rotate(270deg);
}

/** @define expand-btn-animation */
.expand-btn-animation {
  animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes rotate-180-cw {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

/** @define collapse-btn-animation */
.collapse-btn-animation {
  animation: rotate-180-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes rotate-180-ccw {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0);
  }
}
</style>
