import Vue from "vue";
import localForage from "localforage";
import { AxiosError } from "axios";
import HttpStatusCodes from "@/services/HttpStatusCodes";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.config.errorHandler = async (err) => {
  if (err instanceof AxiosError && err.response.status === HttpStatusCodes.UNAUTHORIZED) {
    try {
      await localForage.clear();
      await router.push({ name: "Home" });
    } catch (e) {
      console.log(e);
    }
  }
  console.log(err);
};

const isAnalyticsDebugEnabled = process.env.VUE_APP_GOOGLE_ANALYTICS_DEBUG_ENABLED === "true";

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      params: isAnalyticsDebugEnabled ? { debug_mode: true } : {},
    },
    appName: "ingresapp-webapp",
    pageTrackerScreenviewEnabled: true,
    enabled: process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED === "true",
  },
  router,
);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
