import { GetDailyExamService, GetDailyExamServiceRequest } from "@/use_cases/show_exam/daily_exam/GetDailyExamService";

const GetDailyExamResponse = ({ answerSheetId, currentAnswerSheetItemId }) => ({
  answerSheetId,
  currentAnswerSheetItemId,
});

const getDailyExam = async ({ idCourseProgress, date }) => {
  const getDailyExamServiceRequest = GetDailyExamServiceRequest({
    idCourseProgress,
    date,
  });
  const getDailyExamService = new GetDailyExamService(getDailyExamServiceRequest);
  return GetDailyExamResponse(await getDailyExamService.do());
};

const DailyExam = { getDailyExam };

export default DailyExam;
