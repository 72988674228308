import APIService from "@/services/API";

const GetAnswerSheetItemServiceRequest = function constructor({ answerSheetId, answerSheetItemId }) {
  this.answer_sheet_id = answerSheetId;
  this.answer_sheet_item_id = answerSheetItemId;
};

/* eslint-disable camelcase */
const GetAnswerSheetItemServiceResponse = function constructor({
  answer_sheet_id,
  next_answer_sheet_item_id,
  previous_answer_sheet_item_id,
  answer_sheet_item_id,
  exercise,
  answers,
  selected_answer_id,
  answer_sheet_item_number,
  total_hints = 0,
  hints_unlocked,
} = {}) {
  this.answerSheetId = answer_sheet_id;
  this.nextAnswerSheetItemId = next_answer_sheet_item_id;
  this.previousAnswerSheetItemId = previous_answer_sheet_item_id;
  this.answerSheetItemId = answer_sheet_item_id;
  this.exercise = exercise;
  this.answers = answers;
  this.selectedAnswerId = selected_answer_id;
  this.answerSheetItemNumber = answer_sheet_item_number;
  this.totalHints = total_hints;
  this.unlockedHints = hints_unlocked;
};
/* eslint-enable camelcase */

class GetAnswerSheetItemService extends APIService {
  constructor(getAnswerSheetItemsServiceRequest) {
    super();
    this.serviceURL = "student/get-answer-sheet-item/";
    this.request = getAnswerSheetItemsServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    return new GetAnswerSheetItemServiceResponse(this.response.data);
  }
}

export { GetAnswerSheetItemService, GetAnswerSheetItemServiceRequest };
