import APIService from "@/services/API";

const EvaluateAnswerSheetServiceRequest = ({ answerSheetId }) => ({
  id_answer_sheet: answerSheetId,
});

const EvaluateAnswerSheetServiceResponse = () => ({});

class EvaluateAnswerSheetService extends APIService {
  constructor(evaluateAnswerSheetServiceRequest) {
    super();
    this.serviceURL = "student/evaluate-answer-sheet/";
    this.request = evaluateAnswerSheetServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().put(this.serviceURL, this.request);
    return EvaluateAnswerSheetServiceResponse(this.response.data);
  }
}

export { EvaluateAnswerSheetService, EvaluateAnswerSheetServiceRequest };
