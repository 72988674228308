import APIService from "@/services/API";

const RegisterAsAStudentServiceRequest = ({ name, lastName, referrerCode, desiredMajorId }) => ({
  name,
  last_name: lastName,
  referrer_code: referrerCode,
  desired_major_id: desiredMajorId,
});

// eslint-disable-next-line camelcase
const RegisterAsAStudentServiceResponse = ({ course_progress_id }) => ({ courseProgressId: course_progress_id });

class RegisterAsAStudentService extends APIService {
  constructor(registerAsAStudentServiceRequest) {
    super();
    this.serviceURL = "user/register-as-a-student/";
    this.request = registerAsAStudentServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return RegisterAsAStudentServiceResponse(this.response.data);
  }
}

export { RegisterAsAStudentService, RegisterAsAStudentServiceRequest };
