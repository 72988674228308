<template>
  <BaseCard class="profile-card">
    <div class="profile-card__container">
      <LogoImage class="profile-card__logo"></LogoImage>
      <div class="profile-card__text-container">
        <div class="profile-card__title">Aún no eres premium</div>
        <div class="profile-card__text">
          Al volverte premium disfrutas de <span>acceso ilimitado</span> a todas las funcionalidades además de
          <span>1 millón de monedas</span> para que las uses como prefieras.
        </div>
        <BaseButton style-modifier="secondary" @click="returnShop">VOLVERME PREMIUM</BaseButton>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import LogoImage from "@/assets/logo-without-text.svg?inline";

export default {
  name: "ProfileCard",
  components: { BaseButton, BaseCard, LogoImage },
  methods: {
    returnShop() {
      this.$emit("return-shop");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/typography";
@import "~@/scss/spacing";
@import "~@/scss/colors";
@import "~@/scss/resolutions";

span {
  font-weight: bold;
}

.profile-card {
  border-color: $secondary-color;
  border-width: 0.1em;
  padding: 1.5em;
}

.profile-card__container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 1em;
}

.profile-card__logo {
  background-color: $primary-color;
  width: 70%;
  padding: 0.5em;
  border-radius: 50%;
  border: 5px solid $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.profile-card__text-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-card__title {
  @extend %font-section-title-1;

  text-align: center;
}

.profile-card__text {
  @extend %font-body-3;

  text-align: justify;
}
</style>
