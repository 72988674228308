import { AddCourseService, AddCourseServiceRequest } from "@/use_cases/profile/courses//AddCourseService";

const AddCourseServiceResponse = ({ idDesiredMajor }) => ({ idDesiredMajor });

const addCourse = async ({ idCourseProgress, idCourse, idDesiredMajor }) => {
  const addCourseServiceRequest = AddCourseServiceRequest({
    idCourseProgress,
    idCourse,
    idDesiredMajor,
  });
  const addCourseService = new AddCourseService(addCourseServiceRequest);
  return AddCourseServiceResponse(await addCourseService.do());
};

const AddCourse = { addCourse };

export default AddCourse;
