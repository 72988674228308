import APIService from "@/services/API";

const DownloadOxxoPDFReferenceResponse = function constructor(data) {
  this.data = data;
};

/* eslint-disable camelcase */
const DownloadOxxoPDFReferenceRequest = function constructor({
  referenceNumber,
  amountToPay,
  expirationDate,
  barcodeUrl,
} = {}) {
  this.reference_number = referenceNumber;
  this.amount_to_pay = amountToPay;
  this.expiration_date = expirationDate;
  this.barcode_url = barcodeUrl;
};

class DownloadOxxoPDFReferenceService extends APIService {
  constructor(downloadOxxoPDFReferenceRequest) {
    super();
    this.serviceURL = "student/get-oxxo-pdf-stub/";
    this.request = downloadOxxoPDFReferenceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return new DownloadOxxoPDFReferenceResponse(this.response.data);
  }
}

export { DownloadOxxoPDFReferenceService, DownloadOxxoPDFReferenceRequest };
