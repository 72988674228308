<template>
  <div class="circle-letter-icon" data-test-id="circle-letter-icon">{{ getAlphabeticalIndex }}</div>
</template>

<script>
const ASCII_A = 64;
const ALPHABET_SIZE = 26;
export default {
  name: "CircleLetterIcon",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getAlphabeticalIndex() {
      let letterIndex = "";
      let remainder = 0;
      let quotient = this.value;
      while (quotient > 0) {
        remainder = quotient % ALPHABET_SIZE;
        quotient = Math.trunc(quotient / ALPHABET_SIZE);
        if (remainder === 0) {
          remainder = ALPHABET_SIZE;
          quotient -= 1;
        }
        remainder = ASCII_A + remainder;
        letterIndex = String.fromCharCode(remainder) + letterIndex;
      }
      return letterIndex;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define circle-letter-icon; */
.circle-letter-icon {
  @extend %font-title-1;

  background-color: $simulation-exam-icon-color;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-color: $simulation-exam-icon-color;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
