import {
  StartSimulationExamService,
  StartSimulationExamServiceRequest,
} from "@/use_cases/show_simulation_exams/StartSimulationExamService";

const StartSimulationExamResponse = ({ answerSheetId, currentAnswerSheetItemId, isAnswerSheetStarted }) => ({
  answerSheetId,
  currentAnswerSheetItemId,
  isAnswerSheetStarted,
});

const StartSimulationExam = async ({ simulationExamId }) => {
  const startSimulationExamServiceRequest = StartSimulationExamServiceRequest({
    simulationExamId,
  });
  const startSimulationExamService = new StartSimulationExamService(startSimulationExamServiceRequest);
  return StartSimulationExamResponse(await startSimulationExamService.do());
};

export default { StartSimulationExam };
