import APIService from "@/services/API";
import InsufficientCoins from "@/exceptions/InsufficientCoins";

const StartSubjectExamServiceRequest = ({ numberOfExercises, courseProgressId, subjectId }) => ({
  number_of_exercises: numberOfExercises,
  course_progress_id: courseProgressId,
  subject_id: subjectId,
});

/* eslint-disable camelcase */
const StartSubjectExamServiceResponse = ({
  answer_sheet_id,
  current_answer_sheet_item_id,
  is_answer_sheet_started,
}) => ({
  answerSheetId: answer_sheet_id,
  currentAnswerSheetItemId: current_answer_sheet_item_id,
  isAnswerSheetStarted: is_answer_sheet_started,
});
/* eslint-enable camelcase */

class StartSubjectExamService extends APIService {
  constructor(startSubjectServiceRequest) {
    super();
    this.serviceURL = "student/start-subject-exam/";
    this.request = startSubjectServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    try {
      this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    } catch (e) {
      switch (e.response.status) {
        case 402:
          throw new InsufficientCoins(e.response.data.message);
        default:
          console.log(e);
      }
    }

    return StartSubjectExamServiceResponse(this.response.data);
  }
}

export { StartSubjectExamService, StartSubjectExamServiceRequest };
