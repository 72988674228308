<template>
  <OneButtonModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    style-modifier="transparent"
    @button-click="emitButtonEvents"
    @hide-modal="emitHideModalEvent"
  >
    <div slot="image" class="pending-simulation-exam-modal__img">
      <div class="pending-simulation-exam-modal__exam-img">
        <div class="exam-img__img">
          <ExamImage />
        </div>
      </div>
    </div>

    <div slot="message" class="pending-simulation-exam-modal__message">
      <p>
        Tienes un exámen de simulación en progreso:
        <span class="message__exam-name">{{ `${pendingSimulationExam.name} - ${pendingSimulationExam.year}` }}</span>
      </p>
      <p class="message__details">Presiona Continuar para ir al examen pendiente.</p>
    </div>

    <span slot="buttonText" class="pending-simulation-exam-modal__btn-text">Continuar</span>
  </OneButtonModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import ExamImage from "@/assets/exam.svg?inline";
import OneButtonModal from "@/components/one_button_modal/OneButtonModal.vue";

/**
 * @typedef SimulationExam
 * @type {object}
 * @property {string} simulationExamId
 * @property {string} courseName
 * @property {string} releaseDate
 * @property {string} finishedAnswerSheetId
 * @property {string} currentAnswerSheetItemId
 * @property {string} unfinishedAnswerSheetId
 * @property {string} freeReleaseEndDate
 * @property {float} answerSheetProgress
 * @property {number} year
 * @property {string} name
 */

export default {
  name: "PendingSimulationExamModal",
  components: {
    OneButtonModal,
    ExamImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    /** @type {SimulationExam} * */
    pendingSimulationExam: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitButtonEvents(event) {
      this.emitResumeExamEvent(event);
      this.emitHideModalEvent();
    },
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitResumeExamEvent() {
      this.$emit("resume-exam", this.pendingSimulationExam);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define pending-simulation-exam-modal */
.pending-simulation-exam-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $overlay-color;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.pending-simulation-exam-modal__exam-img {
  width: 100%;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.pending-simulation-exam-modal__twelve-coins {
  width: 100%;
  display: flex;
}

.pending-simulation-exam-modal__message {
  margin: 2em 0;
}

/** @define message; */
.message__details {
  @extend %font-caption-2;
}

.message__exam-name {
  color: $secondary-color;
  display: block;
}

/** @define twelve-coins */
.twelve-coins__img {
  width: 30%;
  margin-left: 4em;

  @include laptop {
    width: 20%;
  }
}

/** @define exam-img */
.exam-img__img {
  width: 30%;
  padding-left: 35%;
  justify-content: center;

  @include laptop {
    width: 20%;
    padding-left: 40%;
  }
}
</style>
