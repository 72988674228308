import localForage from "localforage";
import { LoginService, LoginServiceRequest } from "./services/LoginService";

const LoginResponse = ({ isStudentRegistrationPending }) => ({
  isStudentRegistrationPending,
});

const login = async ({ password }) => {
  const email = await localForage.getItem("email");
  const loginServiceRequest = LoginServiceRequest({ email, password });
  const loginService = new LoginService(loginServiceRequest);

  const serviceResponse = await loginService.do();
  await localForage.setItem("JWT", serviceResponse.jwt);
  return LoginResponse(serviceResponse);
};

const Login = { login };

export default Login;
