<template>
  <div>
    <BaseButton
      data-test-id="continue-with-social-account-btn"
      :style-modifier="authProvider"
      @click="continueWithSocialAccount()"
    >
      Continuar con {{ authProvider }}
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";

const googleID = process.env.VUE_APP_GOOGLE_ID;
const facebookID = process.env.VUE_APP_FACEBOOK_ID;
const facebookAPIVersion = process.env.VUE_APP_FACEBOOK_API_VERSION;
const redirectUri = `${window.location.origin}/uu/ingresar/social-auth`;

export default {
  name: "ContinueWithSocialAccountButton",
  components: { BaseButton },
  props: {
    authProvider: {
      type: String,
      required: true,
      validator: (prop) => ["facebook", "google"].includes(prop),
    },
  },
  methods: {
    async continueWithSocialAccount() {
      // eslint-disable-next-line no-nested-ternary
      await (this.authProvider === "facebook"
        ? this.loginWithFacebook()
        : this.authProvider === "google"
          ? this.loginWithGoogle()
          : undefined);
    },
    async loginWithGoogle() {
      const params = {
        client_id: googleID,
        redirect_uri: `${redirectUri}/google`,
        response_type: "code",
        scope: "openid profile email",
        provider: "google",
      };

      const queryParams = new URLSearchParams(params).toString();
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryParams}`;
    },
    async loginWithFacebook() {
      const params = {
        client_id: facebookID,
        redirect_uri: `${redirectUri}/facebook`,
        response_type: "code",
        scope: "email public_profile",
      };

      const queryParams = new URLSearchParams(params).toString();
      window.location.href = `https://www.facebook.com/v${facebookAPIVersion}/dialog/oauth?${queryParams}`;
    },
  },
};
</script>
