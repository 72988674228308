import {
  ShowSimulationExamService,
  ShowSimulationExamServiceRequest,
} from "@/use_cases/show_exam/simulation_exam/ShowSimulationExamService";

const showSimulationExam = async ({ simulationExamId, answerSheetId }) => {
  const serviceRequest = new ShowSimulationExamServiceRequest({ simulationExamId, answerSheetId });
  const service = new ShowSimulationExamService(serviceRequest);
  const showSimulationExamResponse = await service.do();
  return showSimulationExamResponse;
};

const ShowSimulationExam = { showSimulationExam };

export default ShowSimulationExam;
