<template>
  <div class="show-subjects">
    <BaseDiscount
      v-if="isIngresappPremiumOfferVisible"
      data-test-id="discount-card"
      class="show-subjects__dicount"
      :user-name="userName"
      :desired-major="desiredMajorName"
      :is-short-message-visible="isShortMessageVisible"
      :discount-time="discountTime"
      @close-discount="closeDiscount"
      @go-to-store="goToStore"
    />
    <DailyExamBar
      v-if="isDailyExamVisible"
      class="daily-exam_bar"
      data-test-id="daily-exam-bar"
      :course-progress-id="courseProgressId"
    ></DailyExamBar>
    <ContentSidebarLayout>
      <div slot="main-content">
        <div class="show-subjects__featured-circle-icons">
          <CircleIcon
            class="featured-circle-icons__simulation-exam-circle-icon"
            style-modifier="featured"
            caption="Examenes de simulación"
            @click="$router.push({ name: 'ShowSimulationExamsPage' })"
          >
            <img
              slot="icon"
              class="circle-icon__img"
              src="../../assets/simulation-exams.png"
              alt="simulation exams icon"
            />
          </CircleIcon>
          <CircleIcon
            class="featured-circle-icons__general-exam-circle-icon"
            caption="Práctica general"
            style-modifier="featured"
            data-test-id="general-exam-icon"
            @click="showChooseExamNumberOfExercisesModal(null)"
          >
            <img slot="icon" class="circle-icon__img" src="../../assets/general-exam.png" alt="general exam icon" />
          </CircleIcon>
        </div>

        <div class="show-subjects__subjects-circle-icons">
          <CircleIcon
            v-for="subject in subjects"
            :key="subject.id"
            class="subjects-circle-icons__circle-icon"
            :data-test-id="`subject-icon-${subject.id}`"
            :caption="subject.name"
            @click="showChooseExamNumberOfExercisesModal(subject)"
          >
            <img slot="icon" class="circle-icon__img" :src="subject.imageUrl" :alt="subject.name" />
          </CircleIcon>
        </div>
      </div>
      <div slot="additional-top-sidebar" :class="showDailyExamModel">
        <DailyExamCard
          v-if="isDailyExamVisible"
          data-test-id="daily-exam-card"
          :course-progress-id="courseProgressId"
        ></DailyExamCard>
      </div>
    </ContentSidebarLayout>

    <ChooseExamNumberOfExercisesModalComponent
      v-model="isChooseExamNumberOfExercisesModalVisible"
      data-test-id="choose-exam-number-of-exercises-modal"
      @number-of-exercises-chosen="startExam"
    >
      <p slot="message">
        <span v-if="examType === SUBJECT_EXAM_TYPE">
          ¿Cuántos ejercicios deseas en tu examen de
          <span class="choose-exam-number-of-exercises-modal__subject">{{ selectedSubject.name }}</span> ?
        </span>
        <span v-else>
          ¿Cuántos ejercicios deseas en tu
          <span class="choose-exam-number-of-exercises-modal__subject">examen general</span> ?
        </span>
      </p>
    </ChooseExamNumberOfExercisesModalComponent>

    <InsufficientCoinsModalComponent
      v-model="isInsufficientCoinsModalComponent"
      data-test-id="insufficient-coins-modal"
    />
  </div>
</template>

<script>
import CircleIcon from "@/components/circle_icon/CircleIcon.vue";
import ShowSubjects from "@/use_cases/show_subjects/ShowSubjects";
// eslint-disable-next-line max-len
import ChooseExamNumberOfExercisesModalComponent from "@/use_cases/show_subjects/ChooseExamNumberOfExercisesModalComponent.vue";
import InsufficientCoinsModalComponent from "@/components/InsufficientCoinsModalComponent.vue";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import BaseDiscount from "@/components/base_discount/BaseDiscount.vue";
import localForage from "localforage";
import DailyExamCard from "@/use_cases/show_subjects/daily_exam/DailyExamCard.vue";
import DailyExamBar from "@/use_cases/show_subjects/daily_exam/DailyExamBar.vue";

const GENERAL_EXAM_TYPE = "general-exam";
const SUBJECT_EXAM_TYPE = "subject-exam";

export default {
  name: "ShowSubjectsPage",
  components: {
    DailyExamBar,
    DailyExamCard,
    BaseDiscount,
    ContentSidebarLayout,
    CircleIcon,
    ChooseExamNumberOfExercisesModalComponent,
    InsufficientCoinsModalComponent,
  },
  props: {
    courseProgressId: {
      type: String,
      required: true,
    },
    desiredMajor: {
      type: String,
      required: false,
      default: "",
    },
    userName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      /**
       * @typedef Subject
       * @type {object}
       * @property {number} id
       * @property {string} name
       * @property {string} imageUrl
       */

      /** @type {Subject[]} * */
      subjects: [],
      /** @type {Subject} * */
      selectedSubject: null,
      isChooseExamNumberOfExercisesModalVisible: false,
      isInsufficientCoinsModalComponent: false,
      isDailyExamVisible: false,
      showDailyExamModel: "daily-exam_sidebar_top_hidden",

      desiredMajorName: null,
      isIngresappPremiumOfferVisible: false,
      isShortMessageVisible: 0,
      range: -1,
      discountTime: null,
    };
  },
  computed: {
    examType() {
      if (this.selectedSubject) {
        return SUBJECT_EXAM_TYPE;
      }
      return GENERAL_EXAM_TYPE;
    },
  },

  async mounted() {
    await Promise.all([this.getSubjects(), this.isDailyExamAvailable()]);
    if (this.isFirstLogin()) await this.discount();
    this.$emit("load-finished");
  },

  created() {
    this.SUBJECT_EXAM_TYPE = SUBJECT_EXAM_TYPE;
  },

  methods: {
    isFirstLogin() {
      return this.userName !== "" && this.desiredMajor !== "";
    },
    async getSubjects() {
      this.subjects = await ShowSubjects.getSubjects({ courseProgressId: this.courseProgressId });
    },
    async isDailyExamAvailable() {
      const response = await ShowSubjects.isDailyExamAvailable({ courseProgressId: this.courseProgressId });
      if (response.isDailyExamAvailable) {
        this.isDailyExamVisible = true;
        this.showDailyExamModel = "daily-exam-sidebar-top";
      }
    },
    async discount() {
      const aux = this.desiredMajor.split(" ");
      const endCarrier = (element) =>
        element === "Escuela" ||
        element === "Unidad" ||
        element === "CECyT" ||
        element === "Facultad" ||
        element === "Universidad" ||
        element === "CET";
      this.desiredMajorName = aux.findIndex(endCarrier);
      this.desiredMajorName = aux.slice(0, this.desiredMajorName);
      this.desiredMajorName = this.desiredMajorName.join(" ");

      await localForage.setItem("initialTime", new Date().getTime());
      this.range = Math.floor(Math.random() * 11);
      if (this.range < 5) this.isShortMessageVisible = 1;
      else this.isShortMessageVisible = 0;
      this.range = Math.floor(Math.random() * 11);
      if (this.range < 5) {
        this.discountTime = "10 minutos";
        await localForage.setItem("promotionTime", 600);
      } else {
        this.discountTime = "30 minutos";
        await localForage.setItem("promotionTime", 1800);
      }
      this.isIngresappPremiumOfferVisible = true;
    },
    closeDiscount() {
      this.isIngresappPremiumOfferVisible = false;
    },
    async goToStore() {
      this.isIngresappPremiumOfferVisible = false;
      await this.$router.push({ name: "ShowProductsPage" });
    },
    showChooseExamNumberOfExercisesModal(selectedSubject) {
      this.selectedSubject = selectedSubject;
      this.isChooseExamNumberOfExercisesModalVisible = true;
    },
    showInsufficientCoinsModalComponent() {
      this.isInsufficientCoinsModalComponent = true;
    },
    async startExam(numberOfExercises) {
      switch (this.examType) {
        case GENERAL_EXAM_TYPE: {
          try {
            const response = await ShowSubjects.startGeneralExam({
              courseProgressId: this.courseProgressId,
              numberOfExercises,
            });

            await this.redirectToExamPage(response.answerSheetId, response.currentAnswerSheetItemId);
          } catch (e) {
            if (e instanceof InsufficientCoins) {
              this.showInsufficientCoinsModalComponent();
            }
          }

          break;
        }
        case SUBJECT_EXAM_TYPE:
          try {
            const response = await ShowSubjects.startSubjectExam({
              courseProgressId: this.courseProgressId,
              numberOfExercises,
              subjectId: this.selectedSubject.id,
            });

            await this.redirectToExamPage(response.answerSheetId, response.currentAnswerSheetItemId);
          } catch (e) {
            if (e instanceof InsufficientCoins) {
              this.showInsufficientCoinsModalComponent();
            }
          }
          break;
        default:
          break;
      }
    },
    async redirectToExamPage(answerSheetId, answerSheetItemId) {
      await this.$router.push({
        name: "ExamPage",
        params: {
          answerSheetId,
          answerSheetItemId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_fonts.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define show-subjects; */
.show-subjects {
  font-size: map-get($font-base, size);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 0;
  padding-bottom: 20%;

  @include laptop {
    padding-top: 1.5em;
    padding-bottom: 0;
  }
}

.show-subjects__featured-circle-icons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.show-subjects__subjects-circle-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3em;
}

/** @define featured-circle-icons; */
.featured-circle-icons__simulation-exam-circle-icon,
.featured-circle-icons__general-exam-circle-icon {
  margin: 1.2em;
  height: auto;

  @include laptop {
    margin: 0 1.75em 1.75em;
  }
}

/** @define subjects-circle-icons; */
.subjects-circle-icons__circle-icon {
  margin: 1.2em;
  height: auto;

  @include laptop {
    margin: 1.75em;
  }
}

/** @define circle-icon; */
.circle-icon__img {
  width: 100%;
}

/** @define choose-exam-number-of-exercises-modal; */
.choose-exam-number-of-exercises-modal__subject {
  color: $secondary-color;
}

/** @define daily-exam; */
.daily-exam_bar {
  display: block;

  @include laptop {
    display: none;
  }
}

/** @define daily-exam-sidebar-top; */
.daily-exam-sidebar-top {
  display: block;
  padding-bottom: 2.5em;
}

.daily-exam-sidebar-top_hidden {
  display: none;
  padding-bottom: 0;
}
</style>
