<template>
  <div class="help-card">
    <div class="help-card__container--logo">
      <div class="help-card__logo">
        <LogoImage />
      </div>
      <div class="help-card__help-card-text">
        {{ demoHelpText }}
        <HintsImage v-if="isDemoHelpLogoVisible" class="help-card__hints-mini" />
        <span v-if="isDemoHelpLogoVisible">.</span>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImage from "@/assets/logo-without-text.svg?inline";
import HintsImage from "@/assets/hints.svg";

export default {
  name: "DemoHelpCard",
  components: {
    LogoImage,
    HintsImage,
  },
  props: {
    demoHelpText: {
      type: String,
      default: "",
      required: true,
    },
    isDemoHelpLogoVisible: {
      type: Boolean,
      default: null,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.help-card {
  width: 85%;
  margin-top: 0.7em;
  border: 2.5px solid $secondary-color;
  border-radius: 0;
  box-shadow: #dedede 1px 0 10px 3px;

  @include laptop {
    margin-top: 0.2em;
    font-size: $font-body-1-size--laptop;
  }
}

.help-card__container--logo {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.help-card__logo {
  min-width: 20%;
  max-width: 30%;

  @include laptop {
    min-width: 6.5em;
    max-width: 6.5em;
  }
}

.help-card__hints-mini {
  max-width: 5%;
}
</style>
