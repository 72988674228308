<template>
  <ContentSidebarLayout>
    <div slot="main-content" class="main-content">
      <DemoHelpCard
        v-if="isDemoHelpCardVisible"
        :demo-help-text="demoHelpText"
        :is-demo-help-logo-visible="isDemoHelpLogoVisible"
      ></DemoHelpCard>
      <BaseContainer>
        <ExamExercise
          v-model="selectedAnswerId"
          :exercise-number="1"
          :exercise="exercise"
          exercise-image-position="beforeText"
          :answers="exerciseAnswers"
          :is-selected-answer-correct="isSelectedAnswerCorrect"
          :is-selected-demo-answer-correct="isSelectedDemoAnswerCorrect"
          :are-answers-disabled="disableExerciseAnswer"
          :is-demo-exam="true"
          class="main-content__exercise"
          data-test-id="exam-exercise"
          @answer-selected="resetAnswerStatus"
        >
          <div slot="exerciseImage" class="main-content__img" data-test-id="exam-exercise-image">
            <GraduationCapImage />
          </div>
        </ExamExercise>

        <div class="main-content__evaluate-btn">
          <BaseButton
            class="evaluate-btn__btn"
            style-modifier="secondary"
            :is-disabled="
              selectedAnswerId === null || isSelectedAnswerCorrect === false || isSelectedAnswerCorrect === true
            "
            data-test-id="demo-evaluate-button"
            @click="evaluateSelectedAnswer"
          >
            Siguiente
          </BaseButton>
        </div>
      </BaseContainer>

      <ExerciseFeedbackComponent
        v-model="isExerciseFeedbackVisible"
        :is-exercise-correct="isSelectedAnswerCorrect"
        hints-variant
        @load-next-answer-sheet-item="$router.push({ name: 'DemoSuccessfullyCompletedPage' })"
        @get-hint="handleGetHint"
      >
        <slot>
          <span v-if="isSelectedAnswerCorrect">Siguiente</span>
          <span v-else>Obtener pista</span>
        </slot>
      </ExerciseFeedbackComponent>

      <div class="main-content__hints">
        <VerticalSlide @after-leave="isLeaveHintsTransitionDone = true">
          <ExerciseHintsBottomSheet
            v-show="isHintsBottomSheetVisible"
            :exercise-hints="hints"
            :last-hint-index-to-load="lastHintIndexToLoad"
            :total-hints="exerciseHints.length"
            :force-visibility="forceDemoVisibility"
            hints-variant
            data-test-id="exercise-hints-bottom-sheet"
            @load-next-hint="getHint"
          />
        </VerticalSlide>
      </div>
      <HintsFloatingButton
        v-show="isHintsFloatingButtonVisible"
        class="main-content__floating-button"
        @click="handleGetHint"
      />
    </div>

    <div slot="sidebar">
      <ExerciseHintsSidebarBox
        :exercise-hints="exerciseHints"
        :last-hint-index-to-load="lastHintIndexToLoad"
        :total-hints="exerciseHints.length"
        hints-variant
        data-test-id="exercise-hints-sidebar-box"
        @button-click="getHint"
      />
    </div>
  </ContentSidebarLayout>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import GraduationCapImage from "@/assets/graduation-cap.svg?inline";
import Demo from "@/use_cases/demo/Demo";
import VerticalSlide from "@/transitions/VerticalSlide.vue";
import ExerciseHintsBottomSheet from "@/components/exercise_hints/ExerciseHintsBottomSheet.vue";
import HintsFloatingButton from "@/components/HintsFloatingButton.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import ExerciseHintsSidebarBox from "@/components/exercise_hints/ExerciseHintsSidebarBox.vue";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import ExamExercise from "@/components/exam_exercise/ExamExercise.vue";
import ExerciseFeedbackComponent from "@/use_cases/show_exam/ExerciseFeedbackComponent.vue";
import DemoHelpCard from "@/use_cases/demo/DemoHelpCard.vue";

export default {
  name: "DemoPage",
  components: {
    DemoHelpCard,
    ExerciseFeedbackComponent,
    ExamExercise,
    BaseContainer,
    BaseButton,
    GraduationCapImage,
    ExerciseHintsBottomSheet,
    VerticalSlide,
    HintsFloatingButton,
    ExerciseHintsSidebarBox,
    ContentSidebarLayout,
  },
  data() {
    return {
      demoHelpText:
        "Responde la siguiente pregunta de ejemplo. Si no sabes la respuesta, intenta con una pista dando clic en",
      demoHelpText_NoHints:
        "Selecciona la respuesta correcta para finalizar la demo. No te preocupes si no respondiste correctamente.",
      exercise: {
        text:
          "La expresión algebraica  ________ describe la siguiente frase “cuatro veces el cubo de la suma de un " +
          "número y el dos”.",
      },
      /** @type {{ id: number, text: string }[]} * */
      exerciseAnswers: [
        {
          id: 1,
          text: "\\(4x^3+2\\)",
        },
        {
          id: 2,
          text: "\\(4\\left(x+2\\right)^3\\)",
        },
        {
          id: 3,
          text: "\\(4\\left(x^3+2\\right)\\)",
        },
        {
          id: 4,
          text: "\\(4\\left(x+2^3\\right)\\)",
        },
      ],
      exerciseHints: [
        {
          text:
            "Podemos dividir esta descripción en partes más simples y traducirlas a lenguaje algebraico. Un " +
            "número: Representemos el número con una variable, por ejemplo: X.",
        },
        {
          text:
            "La suma de un número y el dos: Sumamos el número x y 2 para obtener x + 2, El cubo de la suma de" +
            "un número y el dos: Elevamos al cubo el resultado anterior, es decir, (x + 2)³.",
        },
        {
          text:
            "Cuatro veces el cubo de la suma de un número y el dos: Multiplicamos por cuatro el resultado " +
            "anterior, es decir, 4(x + 2)³.",
        },
      ],
      /** @typo number * */
      selectedAnswerId: null,
      isSelectedAnswerCorrect: null,
      isSelectedDemoAnswerCorrect: null,
      isHintsBottomSheetVisible: false,
      isLeaveHintsTransitionDone: true,
      hasHintsFloatingButtonBeenHidden: false,
      isHintsSectionDiscovered: false,
      isExerciseFeedbackVisible: false,
      isDemoHelpLogoVisible: true,
      isDemoHelpCardVisible: true,
      forceDemoVisibility: false,
      lastHintIndexToLoad: 0,
      hints: [],
      nextHintId: 0,
    };
  },

  computed: {
    disableExerciseAnswer() {
      return this.isExerciseFeedbackVisible && this.hints.length !== 3;
    },
    isHintsFloatingButtonVisible: {
      get() {
        return !(this.isHintsBottomSheetVisible || this.hasHintsFloatingButtonBeenHidden);
      },

      set(isHintsFloatingButtonVisible) {
        if (isHintsFloatingButtonVisible === false) {
          this.hasHintsFloatingButtonBeenHidden = true;
        }
      },
    },
  },

  mounted() {
    this.refreshMathJax();
    this.$emit("load-finished");
  },

  methods: {
    evaluateSelectedAnswer() {
      this.isSelectedAnswerCorrect = Demo.evaluateAnswer(this.selectedAnswerId);
      if (this.isSelectedAnswerCorrect) {
        this.isDemoHelpCardVisible = false;
      } else {
        // eslint-disable-next-line max-len
        this.demoHelpText = `Intenta con ${this.nextHintId === 1 ? "otra" : "una"} pista. Te ${this.nextHintId === 2 ? "queda" : "quedan"} ${3 - this.nextHintId} ${this.nextHintId === 2 ? "pista" : "pistas"}.`;
        if (3 - this.nextHintId === 0) {
          this.demoHelpText = this.demoHelpText_NoHints;
        }
        this.isDemoHelpLogoVisible = false;
      }
      this.isHintsBottomSheetVisible = false;
      this.forceDemoVisibility = false;
      this.isHintsFloatingButtonVisible = false;
      this.isExerciseFeedbackVisible = true;
    },
    showHintsBottomSheet() {
      this.isExerciseFeedbackVisible = false;
      this.forceDemoVisibility = true;
      this.isHintsBottomSheetVisible = true;
    },
    resetAnswerStatus() {
      this.isExerciseFeedbackVisible = false;
      this.forceDemoVisibility = false;
    },

    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          window.MathJax.typesetPromise();
        });
      }
    },
    getHint() {
      // eslint-disable-next-line max-len
      this.demoHelpText = `Intenta con ${this.nextHintId === 1 ? "otra" : "una"} pista. Te ${this.nextHintId === 1 ? "queda" : "quedan"} ${2 - this.nextHintId} ${this.nextHintId === 1 ? "pista" : "pistas"}.`;
      this.isDemoHelpLogoVisible = false;
      this.isExerciseFeedbackVisible = false;
      this.isSelectedAnswerCorrect = null;
      const hint = this.exerciseHints[this.nextHintId];
      this.hints.push(hint);
      this.nextHintId += 1;
      this.lastHintIndexToLoad = this.hints.length;
      if (this.nextHintId === 3) {
        this.isSelectedDemoAnswerCorrect = false;
        this.demoHelpText = this.demoHelpText_NoHints;
      }
    },
    handleGetHint() {
      this.isDemoHelpLogoVisible = false;
      this.isSelectedAnswerCorrect = null;
      if (2 - this.nextHintId >= 0) {
        this.getHint();
      }
      this.showHintsBottomSheet();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define main-content; weak */
.main-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @include laptop {
    justify-content: left;
  }
}

.main-content__help-card-text {
  font-size: $font-caption-3-size;

  @include laptop {
    font-size: $font-title-1-size--laptop;
  }
}

.main-content__exercise {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include laptop {
    margin-top: 3em;
  }
}

.main-content__img {
  width: 40%;

  @include laptop {
    width: 25%;
  }
}

.main-content__evaluate-btn {
  width: 100%;
  margin: 0.5em 0;
  flex-grow: 1;

  @include laptop {
    width: 70%;
    padding-bottom: 5em;
  }
}

.main-content__feedback {
  width: 100%;
  bottom: 0;
}

.main-content__hints {
  width: auto;

  @include laptop {
    display: none;
  }
}

.main-content__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > .exercise-answer {
    flex-grow: 20;
  }

  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

.main-content__floating-button {
  @include laptop {
    display: none;
  }
}

/** @define answers; */
.answers__item {
  @include laptop {
    padding-left: 2em;
  }
}
</style>
