import {
  BuyProductWithOxxoService,
  BuyProductWithOxxoRequest,
} from "@/use_cases/buy_product/BuyProductWithOxxoService";
import {
  BuyProductWithCardService,
  BuyProductWithCardRequest,
} from "@/use_cases/buy_product/BuyProductWithCardService";

const BuyProductWithOxxoResponse = function constructor({
  referenceNumber,
  amountToPay,
  expirationDate,
  barcodeUrl,
} = {}) {
  this.referenceNumber = referenceNumber;
  this.amountToPay = amountToPay;
  this.expirationDate = expirationDate;
  this.barcodeUrl = barcodeUrl;
};

const BuyProductWithCardSuccessResponse = function constructor(data) {
  this.data = data;
};

const BuyProductWithOxxo = async ({ productId }) => {
  const buyProductWithOxxoRequest = new BuyProductWithOxxoRequest({ productId });
  const buyProductWithOxxoService = new BuyProductWithOxxoService(buyProductWithOxxoRequest);
  const serviceResponse = await buyProductWithOxxoService.do();
  return new BuyProductWithOxxoResponse(serviceResponse);
};

const BuyProductWithCard = async ({ productId, cardToken }) => {
  const buyProductWithCardRequest = new BuyProductWithCardRequest({ productId, cardToken });
  const buyProductWithCardService = new BuyProductWithCardService(buyProductWithCardRequest);
  const serviceResponse = await buyProductWithCardService.do();
  return new BuyProductWithCardSuccessResponse(serviceResponse);
};

export default { BuyProductWithOxxo, BuyProductWithCard };
