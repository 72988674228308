import {
  GetExerciseIssuesService,
  GetExerciseIssuesServiceRequest,
} from "@/use_cases/show_exam/report_an_issue/GetExerciseIssuesService";

const GetExerciseIssueResponse = function constructor({ id, description } = {}) {
  this.id = id;
  this.description = description;
};

const getExerciseIssues = async () => {
  const getCoursesService = new GetExerciseIssuesService(GetExerciseIssuesServiceRequest({}));
  const serviceResponse = await getCoursesService.do();
  return serviceResponse.map((responseData) => new GetExerciseIssueResponse(responseData));
};

const ShowExerciseIssues = { getExerciseIssues };

export default ShowExerciseIssues;
