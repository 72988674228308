<template>
  <TwoButtonsModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    style-modifier="transparent"
    primary-button-style-modifier="primary"
    @hide-modal="emitHideModalEvent"
    @primary-button-click="emitNumberOfExercisesChosen(5)"
    @secondary-button-click="emitNumberOfExercisesChosen(10)"
  >
    <div slot="image" class="choose-exam-number-of-exercises-modal__exam-img">
      <ExamImage class="exam-img__img" />
    </div>

    <div slot="message" class="choose-exam-number-of-exercises-modal__message">
      <slot name="message"></slot>
    </div>

    <span slot="primaryButtonText" class="choose-exam-number-of-exercises-modal__five-exercises-btn-text"
      >5 preguntas (5 <CoinsImage class="coins-img__img" />)
    </span>
    <span slot="secondaryButtonText" class="choose-exam-number-of-exercises-modal__ten-exercises-btn-text"
      >10 preguntas (10 <CoinsImage class="coins-img__img" />)</span
    >
  </TwoButtonsModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import ExamImage from "@/assets/exam.svg?inline";
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";
import TwoButtonsModal from "@/components/two_buttons_modal/TwoButtonsModal.vue";

export default {
  name: "ChooseExamNumberOfExercisesModalComponent",
  components: {
    TwoButtonsModal,
    ExamImage,
    CoinsImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitNumberOfExercisesChosen(numberOfExercisesChosen) {
      this.$emit("number-of-exercises-chosen", numberOfExercisesChosen);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_resolutions.scss";

/** @define choose-exam-number-of-exercises-modal */
.choose-exam-number-of-exercises-modal__exam-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.choose-exam-number-of-exercises-modal__message {
  margin: 2em 0;
}

/** @define exam-img */
.exam-img__img {
  width: 40%;

  @include laptop {
    width: 12.5%;
  }
}

/** @define coins-img */
.coins-img__img {
  width: 1.2em;
}
</style>
