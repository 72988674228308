import {
  DownloadOxxoPDFReferenceService,
  DownloadOxxoPDFReferenceRequest,
} from "@/use_cases/buy_product/DownloadOxxoPDFReferenceService";

const DownloadOxxoPDFReferenceResponse = function constructor({ data }) {
  this.paymentReferenceData = data;
};

// eslint-disable-next-line
const DownloadOxxoPDFReference = async ({ referenceNumber, amountToPay, expirationDate, barcodeUrl }) => {
  const downloadOxxoPDFReferenceRequest = new DownloadOxxoPDFReferenceRequest({
    referenceNumber,
    amountToPay,
    expirationDate,
    barcodeUrl,
  });
  const downloadOxxoPDRReferenceService = new DownloadOxxoPDFReferenceService(downloadOxxoPDFReferenceRequest);
  const serviceResponse = await downloadOxxoPDRReferenceService.do();
  return new DownloadOxxoPDFReferenceResponse(serviceResponse);
};

export default { DownloadOxxoPDFReference };
