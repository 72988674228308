import { SendFeedbackService, SendFeedbackRequest } from "@/services/send_feedback/SendFeedbackService";

const SendFeedbackResponse = function constructor() {};

/* eslint-disable camelcase */
const sendFeedback = async ({ text, hint_id, client, score, explanation_id, answer_sheet_item_id }) => {
  const SendFeedbackServiceRequest = new SendFeedbackRequest({
    text,
    hint_id,
    client,
    score,
    explanation_id,
    answer_sheet_item_id,
  });
  const sendFeedbackService = new SendFeedbackService(SendFeedbackServiceRequest);
  return SendFeedbackResponse(await sendFeedbackService.do());
};
/* eslint-enable camelcase */

const SendFeedback = { sendFeedback };

export default SendFeedback;
