import APIService from "@/services/API";

const GetAnswerSheetResultsSummaryServiceRequest = function constructor({ answerSheetId }) {
  this.answer_sheet_id = answerSheetId;
};

/* eslint-disable camelcase */
const SubjectResults = function constructor({
  id,
  name,
  image_url,
  number_of_exercises,
  number_of_correct_answers,
} = {}) {
  this.id = id;
  this.name = name;
  this.imageUrl = image_url;
  this.numberOfExercises = number_of_exercises;
  this.numberOfCorrectAnswers = number_of_correct_answers;
};

const GetAnswerSheetResultsSummaryServiceResponse = function constructor({
  number_of_exercises,
  number_of_correct_answers,
  spent_time_in_seconds,
  subjects_results,
} = {}) {
  this.numberOfExercises = number_of_exercises;
  this.numberOfCorrectAnswers = number_of_correct_answers;
  this.spentTimeInSeconds = spent_time_in_seconds;
  this.subjectsResults = subjects_results.map((results) => new SubjectResults(results));
};

class GetAnswerSheetResultsSummaryService extends APIService {
  constructor(getAnswerSheetResultsSummaryServiceRequest) {
    super();
    this.serviceURL = "student/get-answer-sheet-results-summary/";
    this.request = getAnswerSheetResultsSummaryServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return new GetAnswerSheetResultsSummaryServiceResponse(this.response.data);
  }
}

export { GetAnswerSheetResultsSummaryService, GetAnswerSheetResultsSummaryServiceRequest };
