import APIService from "@/services/API";

const SaveAnswerSheetItemServiceRequest = ({ answerSheetId, answerSheetItemId, selectedAnswerId, time }) => ({
  answer_sheet_id: answerSheetId,
  answer_sheet_item_id: answerSheetItemId,
  selected_answer_id: selectedAnswerId,
  time,
});

const SaveAnswerSheetItemServiceResponse = () => ({});

class SaveAnswerSheetItemService extends APIService {
  constructor(getAnswerSheetItemsServiceRequest) {
    super();
    this.serviceURL = "student/save-answer-sheet-item/";
    this.request = getAnswerSheetItemsServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);
    return SaveAnswerSheetItemServiceResponse(this.response.data);
  }
}

export { SaveAnswerSheetItemService, SaveAnswerSheetItemServiceRequest };
