<template>
  <BaseModal
    :is-visible="isVisible"
    :is-close-button-visible="isCloseButtonVisible"
    :style-modifier="styleModifier"
    @hide-modal="emitHideModalEvent"
  >
    <slot slot="image" name="image"></slot>

    <slot slot="message" name="message"></slot>

    <div slot="callToAction" class="two-btns-modal__call-to-action">
      <BaseButton
        class="call-to-action__primary-btn"
        :style-modifier="primaryButtonStyleModifier"
        data-test-id="call-to-action-primary-btn"
        @click="emitPrimaryButtonClickEvent"
      >
        <slot name="primaryButtonText"></slot>
      </BaseButton>

      <BaseButton
        class="call-to-action__secondary-btn"
        style-modifier="tertiary"
        data-test-id="call-to-action-secondary-btn"
        @click="emitSecondaryButtonClickEvent"
      >
        <slot name="secondaryButtonText"></slot>
      </BaseButton>
    </div>
  </BaseModal>
</template>
<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "TwoButtonsModal",
  components: { BaseModal, BaseButton },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    primaryButtonStyleModifier: {
      type: String,
      required: false,
      default: "secondary",
    },
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) => ["transparent", "secondary"].includes(prop),
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitPrimaryButtonClickEvent(event) {
      this.$emit("primary-button-click", event);
    },
    emitSecondaryButtonClickEvent(event) {
      this.$emit("secondary-button-click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_fonts.scss";

/** @define two-btns-modal */
.two-btns-modal__call-to-action {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: center;
}

/** @define call-to-action */
.call-to-action__primary-btn {
  width: 45%;
  margin-right: 2em;
}

.call-to-action__secondary-btn {
  width: 45%;
  margin-left: 2em;
}
</style>
