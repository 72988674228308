/**
 * @typedef {Object} Item
 * @property {number} id
 * @property {string} name
 */

/** @return {Item} * */
const Item = (id, name) => ({
  id,
  name,
});

/**
 * @typedef {Object} Subgroup
 * @property {string} name
 * @property {Item[]} items
 */

/** @return {Subgroup} * */
const Subgroup = (name, items) => ({
  name,
  items,
});

/**
 * @typedef {Object} Group
 * @property {string} name
 * @property {Subgroup[]} subgroups
 */

// eslint-disable-next-line no-unused-vars
/** @return {{name: string, subgroups: [Subgroup]}} * */
const Group = (name, subgroups) => ({
  name,
  subgroups,
});

/**
 * @param {Item[]} items
 * @return {Item[]}
 */
const groupItemsInInputDropDownItems = (items) => {
  const reducedItems = [];
  let group = null;
  let subgroup = null;
  for (let index = 0; index < items.length; index += 1) {
    group = reducedItems.find((itemGroup) => itemGroup.name === items[index].group);
    if (group) {
      subgroup = group.subgroups.find((itemSubgroup) => itemSubgroup.name === items[index].subgroup);
      if (subgroup) {
        const item = Item(items[index].id, items[index].name);
        subgroup.items.push(item);
      } else {
        const item = Item(items[index].id, items[index].name);
        const newSubgroup = Subgroup(items[index].subgroup, [item]);
        group.subgroups.push(newSubgroup);
      }
    } else {
      const item = Item(items[index].id, items[index].name);
      const newSubgroup = Subgroup(items[index].subgroup, [item]);
      const newGroup = Group(items[index].group, [newSubgroup]);
      reducedItems.push(newGroup);
    }
  }
  return reducedItems;
};

export default groupItemsInInputDropDownItems;
