import APIService from "@/services/API";

const BuyProductWithOxxoRequest = function constructor({ productId }) {
  this.id_product = productId;
};

/* eslint-disable camelcase */
const BuyProductWithOxxoResponse = function constructor({
  reference_number,
  amount_to_pay,
  expiration_date,
  barcode_url,
} = {}) {
  this.referenceNumber = reference_number;
  this.amountToPay = amount_to_pay;
  this.expirationDate = expiration_date;
  this.barcodeUrl = barcode_url;
};

class BuyProductWithOxxoService extends APIService {
  constructor(buyProductWithOxxoRequest) {
    super();
    this.serviceURL = "student/buy-product-with-oxxo/";
    this.request = buyProductWithOxxoRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().post(this.serviceURL, this.request);

    return new BuyProductWithOxxoResponse(this.response.data);
  }
}

export { BuyProductWithOxxoService, BuyProductWithOxxoRequest };
