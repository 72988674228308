<template>
  <div class="hints-box">
    <HintsImage
      v-if="hintIndex === 0"
      class="hints-box__img"
      data-test-id="hints-box-image"
      @click="getNextHint"
    ></HintsImage>

    <div v-for="index in hintIndex" :key="index" class="hints-box__text-container" data-test-id="hint-content">
      <FeedbackComponent
        buttons-variant="black"
        placeholder="Puedes agregar un comentario opcional sobre la pista..."
        :data-test-id-prefix="`hint-${index}`"
        @send-feedback="(feedback) => sendFeedback(index, feedback)"
      />
      <span class="hints-box__numeration"> ({{ index }}/{{ totalHints }}) </span>
      {{ " " }} <span v-html="hints[index - 1].text"></span>
    </div>

    <BaseButton
      style-modifier="text"
      class="hints-box__add-hint-btn"
      :is-disabled="isAddHintButtonDisabled"
      data-test-id="get-next-hint-button"
      @click="getNextHint"
    >
      <span v-if="hintsVariant">+ Obtener pista</span>
      <span v-else>+ Obtener pista por 5</span>
      <CoinsImage v-if="!hintsVariant" :class="'add-hint-btn__img ' + coinsImageModifierClass" />
    </BaseButton>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import HintsImage from "@/assets/hints.svg?inline";
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";
import BaseButton from "@/components/base_button/BaseButton.vue";
import FeedbackComponent from "@/components/feedback_component/FeedbackComponent.vue";

export default {
  name: "ExerciseHintsSidebarBox",
  components: {
    HintsImage,
    CoinsImage,
    BaseButton,
    FeedbackComponent,
  },
  props: {
    exerciseHints: {
      type: Array,
      default: null,
    },
    lastHintIndexToLoad: {
      type: Number,
      default: 0,
    },
    totalHints: {
      type: Number,
      default: 0,
    },
    hintsVariant: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hintIndex: 0,
      hints: this.exerciseHints,
    };
  },
  computed: {
    coinsImageModifierClass() {
      return this.isAddHintButtonDisabled ? "add-hint-btn__img--disabled" : "";
    },
    isAddHintButtonDisabled() {
      return this.lastHintIndexToLoad >= this.totalHints;
    },
  },
  watch: {
    async lastHintIndexToLoad() {
      this.hintIndex = this.lastHintIndexToLoad;
      await this.refreshMathJax();
    },
    async exerciseHints() {
      this.hints = this.exerciseHints;
      this.hintIndex = this.lastHintIndexToLoad;
      await this.refreshMathJax();
    },
  },
  methods: {
    async created() {
      this.hintIndex = this.lastHintIndexToLoad;
      await this.refreshMathJax();
    },
    async getNextHint() {
      this.$emit("load-next-hint");
      if (this.hintIndex < this.hints.length) {
        this.hintIndex += 1;
      }
      this.$emit("button-click", this.hintIndex);
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          window.MathJax.typesetPromise();
        });
      }
    },
    async sendFeedback(index, feedback) {
      const hintFeedback = feedback;
      hintFeedback.hintId = this.hints[index - 1].id;
      this.$emit("send-feedback", hintFeedback);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_fonts.scss";

/** @define hints-box; */
.hints-box {
  font-size: map-get($font-base, size);
  width: 80%;
  border-radius: 25px;
  border: 0.3em solid $secondary-color;
  display: flex;
  padding: 1.5em;
  flex-direction: column;
  align-items: center;
}

.hints-box__text-container {
  @extend %font-body-2;

  padding: 0.5em 0;
}

.hints-box__img {
  cursor: pointer;
  width: 20%;
  padding-bottom: 1em;
}

.hints-box__add-hint-btn {
  color: $primary-color;
}

.hints-box__numeration {
  @extend %font-caption-3;

  color: $secondary-color;
}

/** @define add-hint-btn; weak */
.add-hint-btn__img {
  width: 1.5em;
}

.add-hint-btn__img--disabled > path {
  fill: $disabled-color;
}
</style>
