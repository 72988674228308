import { GetStoreProductsService, GetStoreProductsRequest } from "./GetStoreProductsService";

const GetProductsResponse = function constructor({ productId, name, description, price, image, type } = {}) {
  this.productId = productId;
  this.name = name;
  this.description = description;
  this.price = price;
  this.image = image;
  this.type = type;
};

const getProducts = async () => {
  const getProductsService = new GetStoreProductsService(GetStoreProductsRequest({}));
  const serviceResponse = await getProductsService.do();
  return serviceResponse.map((responseData) => new GetProductsResponse(responseData));
};

const ShowProducts = { getProducts };

export default ShowProducts;
