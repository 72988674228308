<template>
  <div>
    <span>{{ item.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    /** @type {{name: string, id: String}} * */
    // eslint-disable-next-line vue/require-prop-types
    item: { default: null },
  },
};
</script>
