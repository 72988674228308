<template>
  <BaseModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    :is-close-button-disabled="isDisabledIssuesForm"
    style-modifier="transparent"
    @hide-modal="handleHideModal"
  >
    <div slot="message" class="report-issue-modal-container">
      <p class="report-issue-modal_title">Reporta un problema</p>
      <div v-for="(issue, index) in exerciseIssues" :key="index">
        <div v-if="issue.id !== otherOptionId" class="report-issue-modal_checkbox">
          <input
            :id="'option-' + index"
            v-model="selectedIssues"
            type="checkbox"
            :value="issue.id"
            :disabled="isDisabledIssuesForm"
            :data-test-id="`check-boxes-issues-${issue.id}`"
            class="custom-checkbox"
          />
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label :for="'option-' + index"> {{ issue.description }} </label>
        </div>
      </div>
      <BaseInputText
        v-model="otherIssueText"
        placeholder="Otro Tipo de Problema"
        type="text"
        :disabled="isDisabledIssuesForm"
      ></BaseInputText>
      <BaseButton
        style-modifier="secondary"
        class="report-issue-modal_btn"
        :is-disabled="!isButtonDisabled && isButtonDisabledInput"
        :is-loading="isLoadingBtn"
        data-test-id="report-issue-question-button"
        @click="handleSendReportIssue"
      >
        Enviar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseInputText from "@/components/base_input_text/BaseInputText.vue";
import ShowExerciseIssues from "@/use_cases/show_exam/report_an_issue/ShowExerciseIssues";
import ReportIssue from "@/use_cases/show_exam/report_an_issue/ReportIssue";

const OTHER_OPTION = 5;

export default {
  name: "ReportIssueModal",
  components: { BaseInputText, BaseButton, BaseModal },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    answerSheetItemId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      selectedIssues: [],
      isLoadingBtn: false,
      exerciseIssues: [],
      otherIssueText: "",
      isButtonDisabledInput: true,
      isDisabledIssuesForm: false,
      otherOptionId: OTHER_OPTION,
    };
  },
  computed: {
    isButtonDisabled() {
      return this.selectedIssues.length > 0;
    },
  },
  watch: {
    otherIssueText() {
      this.isButtonDisabledInput = this.otherIssueText === "";
    },
  },
  async mounted() {
    this.exerciseIssues = await ShowExerciseIssues.getExerciseIssues();
  },
  methods: {
    async handleSendReportIssue() {
      try {
        this.isLoadingBtn = true;
        this.isDisabledIssuesForm = true;

        await ReportIssue.reportIssue({
          text: this.otherIssueText,
          issue_types: this.selectedIssues,
          client: "web",
          answer_sheet_item_id: this.answerSheetItemId,
        });

        this.$emit("send-report-issue");
      } catch (error) {
        throw Error(error);
      } finally {
        this.resetForm();
      }
    },
    async handleHideModal() {
      this.$emit("hide-modal");
      this.resetForm();
    },
    resetForm() {
      this.selectedIssues = [];
      this.otherIssueText = "";
      this.isButtonDisabledInput = true;
      this.isLoadingBtn = false;
      this.isDisabledIssuesForm = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/typography";
@import "~@/scss/colors";

.report-issue-modal-container {
  background-color: $white;
  border-radius: 0.25em;
  color: $black;
  padding: 1.25em;
  text-align: left;
}

.report-issue-modal_title {
  @extend %font-subtitle-1;

  text-align: center;
}

.report-issue-modal_btn {
  padding-top: 1em;
}

.report-issue-modal_checkbox {
  @extend %font-body-1;

  line-height: 1;
  padding-top: 1.5em;
  display: flex;
  align-items: center;
}

.report-issue-modal_checkbox input {
  margin-right: 1em;
}

.report-issue-modal_checkbox label {
  flex: 1;
  white-space: normal;
}

.custom-checkbox {
  width: 1.5em;
  height: 1.5em;
  appearance: none;
  border: 2px solid $gray;
  border-radius: 0.3em;
  cursor: pointer;
  background-color: $white;
}

.custom-checkbox:checked {
  background-color: $primary-color;
  border: none;
}

.custom-checkbox:checked::after {
  content: "\2713";
  font-size: 1em;
  color: $white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
