<script>
export default {
  name: "Form",
  data() {
    return {
      form: {},
    };
  },
  methods: {
    isFormValid() {
      let isValid = true;
      Object.keys(this.form).forEach((f) => {
        isValid = isValid && this.$refs[f].validateContent();
      });
      return isValid;
    },
  },
};
</script>
