<template>
  <BaseCard class="daily__card">
    <div class="daily__container">
      <div class="daily__container-image">
        <CoinsImage class="daily__image"></CoinsImage>
      </div>
      <div class="daily__container-text">
        <p class="daily__title">¡PREGUNTA DEL DÍA!</p>
        <p class="daily__description">Responde la siguiente pregunta para ganar monedas</p>
        <BaseButton style-modifier="secondary" data-test-id="daily-exam-card-button" @click="startDailyExam"
          >RESPONDER PREGUNTA DEL DIA</BaseButton
        >
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import CoinsImage from "@/assets/coins.svg?inline";
import DailyExamUtils from "./DailyExamUtils";

export default {
  name: "DailyExamCard",
  components: { BaseButton, BaseCard, CoinsImage },
  props: {
    courseProgressId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async startDailyExam() {
      const response = await DailyExamUtils.startDailyExam(this.courseProgressId);
      await this.redirectToExamPage(response.answerSheetId, response.currentAnswerSheetItemId);
    },
    async redirectToExamPage(answerSheetId, answerSheetItemId) {
      await this.$router.push({
        name: "DailyExamPage",
        params: {
          answerSheetId,
          answerSheetItemId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/typography";
@import "~@/scss/spacing";
@import "~@/scss/colors";
@import "~@/scss/resolutions";

.daily__card {
  border-color: $secondary-color;
  border-width: 0.2em;
}

.daily__container {
  display: flex;
  justify-content: space-around;
  padding: 3% 0;
}

.daily__container-image {
  display: flex;
  align-items: center;
  margin: 0 4%;
  min-width: 25%;
  max-width: 25%;
}

.daily__image {
  width: 100%;
}

.daily__container-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily__title {
  @extend %font-section-title-1;

  text-align: center;
}

.daily__description {
  @extend %font-body-2;

  text-align: center;
}
</style>
