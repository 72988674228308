import APIService from "@/services/API";

const GetAnswerSheetResultsBySubjectServiceRequest = function constructor({ answerSheetId, subjectId }) {
  this.answer_sheet_id = answerSheetId;
  this.subject_id = subjectId;
};

/* eslint-disable camelcase */
const ExerciseResults = function constructor({ id, number, text, is_correct } = {}) {
  this.id = id;
  this.number = number;
  this.text = text;
  this.isCorrect = is_correct;
};

const GetAnswerSheetResultsBySubjectServiceResponse = function constructor({ answer_sheet_id, exercises } = {}) {
  this.answerSheetId = answer_sheet_id;
  this.exerciseResults = exercises.map((results) => new ExerciseResults(results));
};

class GetAnswerSheetResultsBySubjectService extends APIService {
  constructor(getAnswerSheetResultsBySubjectServiceRequest) {
    super();
    this.serviceURL = "student/get-answer-sheet-results-by-subject/";
    this.request = getAnswerSheetResultsBySubjectServiceRequest;
    this.response = null;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

    return new GetAnswerSheetResultsBySubjectServiceResponse(this.response.data);
  }
}

export { GetAnswerSheetResultsBySubjectService, GetAnswerSheetResultsBySubjectServiceRequest };
